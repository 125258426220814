import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { CartItem, InvoiceHead, InvoiceItem, Total, RefundInvoiceItem } from "../../models"
import { ExampleTotal, ExampleSpecials } from "../../constants/data"
import { calculateCartTotal, calculateGSTAmount, calculateRefundTotal, handleAddToCartSpecial, handleRemoveFromCartSpecial, pointsToDollar } from "../../utils/Global"

export interface CheckoutState {
  itemsInCart: Array<CartItem>
  selectedItem: CartItem | null
  total: Total
  isKeyboardVisible: boolean
  keyboardLanguage: "eng" | "kor"
  editable: any
  nextToken: string | null,
  currentOrderNo: string
  isRefundMode: boolean
  currentRefundInvoice: InvoiceHead | null
  refundItems: Array<RefundInvoiceItem>
  refundTotal: Total
}

const initialState = {
  itemsInCart: [],
  selectedItem: null,
  total: ExampleTotal,
  isKeyboardVisible: false,
  keyboardLanguage: "eng",
  editable: null,
  nextToken: null,
  currentOrderNo: "",
  isRefundMode: false,
  refundItems: [],
  refundTotal: ExampleTotal,
  currentRefundInvoice: null
} as CheckoutState

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    addItemToCart(state, action: PayloadAction<any>) {
      // reverse order
      // state.itemsInCart.unshift(action.payload.item)
      const newCartItems = handleAddToCartSpecial(action.payload.item, state.itemsInCart)
      state.itemsInCart = newCartItems
      state.total = calculateCartTotal(newCartItems)
      // state.itemsInCart.push(action.payload.item)
    },
    updateAllCartItems(state, action: PayloadAction<any>) {
      state.itemsInCart = action.payload.cartItems
    },
    updateCartItem(state, action: PayloadAction<any>) {
      state.itemsInCart = state.itemsInCart.map((item) =>
        item.itemId === action.payload.id
          ? { ...item, ...action.payload.input }
          : item
      )
    },
    removeItemFromCart(state, action: PayloadAction<any>) {
      const newCartItems = handleRemoveFromCartSpecial(action.payload.item, state.itemsInCart)
      state.itemsInCart = newCartItems
      state.total = calculateCartTotal(newCartItems)
      // state.itemsInCart = state.itemsInCart.filter(
      //   (item: CartItem) => item.itemId !== action.payload.id
      // )
    },
    clearCart(state, action: PayloadAction<any>) {
      state.itemsInCart = []
      state.total = ExampleTotal
    },
    updateTotal(state, action: PayloadAction<any>) {
      state.total = {...state.total, ...action.payload.input}
      console.log('redux state.total', state.total)
    },
    showKeyboard(state, action: PayloadAction<any>) {
      state.isKeyboardVisible = action.payload.isVisible
    },
    switchKeyboardLanguage(state, action: PayloadAction<any>) {
      if (state.keyboardLanguage === "eng") {
        state.keyboardLanguage = "kor"
      } else {
        state.keyboardLanguage = "eng"
      }
    },
    revokeOrder(state, action: PayloadAction<any>) {
      state.currentOrderNo = action.payload.orderNo
      state.itemsInCart = action.payload.itemsInCart
      state.total = action.payload.total
    },
    clearCurrentOrderNo(state, action: PayloadAction<any>) {
      state.currentOrderNo = ""
    },
    applyRatioDiscount(state, action: PayloadAction<any>) {
      if (typeof action.payload.discount === 'number') {
        const discountRatio = action.payload.discount / 100
        // const discountAmount = state.total.amount * discountRatio
        // const totalAmount = state.total.amount - discountAmount
        // const newTotal: Total = {
        //   ...state.total,
        //   discountAmount: discountAmount,
        //   totalAmount: totalAmount,
        //   totalWithGstAmount: totalAmount
        // }
        // state.total = newTotal
        state.itemsInCart = state.itemsInCart.map((item) =>
          item.allowDiscount
          ? { 
              ...item, 
              discountRatio: action.payload.discount, 
              total: item.unitPrice * item.quantity * (1 - discountRatio),
              gstAmount: item.applyGst ? calculateGSTAmount(item.unitPrice * item.quantity * (1 - discountRatio), item.gstRatio) : 0
            }
          : item
        )
        const newTotal = calculateCartTotal(state.itemsInCart)
        state.total = newTotal
      }
    },
    applyAmountDiscount(state, action: PayloadAction<any>) {
      const discountAmount = action.payload.discount
      if (typeof discountAmount === 'number') {
        const totalAmount = state.total.amount - discountAmount
        const newTotal: Total = {
          ...state.total,
          discountAmount: discountAmount,
          totalAmount: totalAmount,
          totalWithGstAmount: totalAmount
        }
        state.total = newTotal
      }
    },
    removeDiscount(state, action: PayloadAction<any>) {
      const discountAmount = state.total.discountAmount
      if (discountAmount > 0) {
        const totalAmount = state.total.totalAmount + discountAmount
        const newTotal: Total = {
          ...state.total,
          discountAmount: 0,
          totalAmount: totalAmount,
          totalWithGstAmount: totalAmount
        }
        state.total = newTotal
        state.itemsInCart = state.itemsInCart.map((item) =>
          item.allowDiscount
          ? { ...item, discountRatio: 0, total: item.unitPrice * item.quantity }
          : item
        )
      }
    },
    redeemLoyaltyPoints(state, action: PayloadAction<any>) {
      const redeemAmount = pointsToDollar(action.payload.points)
      const totalAmount = state.total.amount - state.total.discountAmount - redeemAmount
      const netAmount = state.total.netAmount - redeemAmount
      const newTotal: Total = {
        ...state.total,
        redeemPoints: action.payload.points,
        netAmount: netAmount,
        totalAmount: totalAmount,
        totalWithGstAmount: totalAmount
      }
      state.total = newTotal
    },
    setRefundMode(state, action: PayloadAction<any>) {
      state.isRefundMode = action.payload.isRefundMode
    },
    addItemToRefundCart(state, action: PayloadAction<any>) {
      const newCartItems = [
        ...state.refundItems,
        action.payload.item
      ]
      const newTotal = calculateRefundTotal(newCartItems)
      state.refundItems = newCartItems
      state.refundTotal = newTotal
    },
    addWholeToRefundCart(state, action: PayloadAction<any>) {
      const newCartItems = action.payload.items
      const newTotal = calculateRefundTotal(newCartItems)
      state.refundItems = newCartItems
      state.refundTotal = newTotal
    },
    updateItemInRefundCart(state, action: PayloadAction<any>) {
      const oldItems = state.refundItems
      const newCartItems = oldItems.map((item: RefundInvoiceItem) => {
        if (item.refundItemId === action.payload.item.refundItemId) {
          return action.payload.item
        } else {
          return item
        }
      })
      const newTotal = calculateRefundTotal(newCartItems)
      state.refundItems = newCartItems
      state.refundTotal = newTotal
    },
    removeItemFromRefundCart(state, action: PayloadAction<any>) {
      //@ts-ignore
      const newCartItems = state.refundItems.filter((item: InvoiceItem) => item.refundItemId !== action.payload.refundItemId)
      const newTotal = calculateRefundTotal(newCartItems)
      state.refundItems = newCartItems
      state.refundTotal = newTotal
    },
    clearRefundCart(state, action: PayloadAction<any>) {
      state.refundItems = []
      state.refundTotal = ExampleTotal
    },
    setCurrentRefundInvoice(state, action: PayloadAction<any>) {
      state.currentRefundInvoice = action.payload.invoice
    },
    overWrite(state, action: PayloadAction<any>) {
      console.log("checkout overwrite是", action.payload)
      // state.itemsInCart = action.payload.itemsInCart
      // state.total = action.payload.total
      state = action.payload
    }
  },
})

export const {
  addItemToCart,
  updateCartItem,
  removeItemFromCart,
  clearCart,
  updateTotal,
  showKeyboard,
  switchKeyboardLanguage,
  revokeOrder,
  clearCurrentOrderNo,
  applyRatioDiscount,
  applyAmountDiscount,
  removeDiscount,
  redeemLoyaltyPoints,
  updateAllCartItems,
  setRefundMode,
  addItemToRefundCart,
  clearRefundCart,
  setCurrentRefundInvoice,
  removeItemFromRefundCart,
  updateItemInRefundCart,
  addWholeToRefundCart,
  overWrite
} = checkoutSlice.actions

export default checkoutSlice.reducer
