import React, { useState, useEffect, useRef } from "react"
import { Layout, Table, Typography, Button, DatePicker, Form } from 'antd'
import { CashDeclaration } from "../models"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import { refreshCashDeclarationList } from "../redux/CashDeclaration/slice"
import useViewport from "../hooks/useViewport"
import CashDeclarationDetail from "../components/CashDeclaration/CashDeclarationDetail"
import moment from "moment"
import { dateFormat } from "../constants/data"
import { listCashDeclarations } from "../api/cashDeclaration";
import { getConfiguration } from "../utils/configurate"
import { Link } from "react-router-dom"
import { PostDrawOpen } from "../api/drawOpen"

const PosConfig = getConfiguration("CashDeclaration")
const { machineID } = PosConfig
const { Title } = Typography
const { RangePicker } = DatePicker

export default function CashDeclarationPage() {
  const dispatch = useDispatch()
  const { width, height } = useViewport()
  const { cashDeclarations: CashDeclarations } = useSelector((state: RootStateOrAny) => state.cashDeclaration)
  // console.log('state的CashDeclarations值。。。', CashDeclarations.cashDeclarations)
  const [curCash, setCurCash] = useState<CashDeclaration | null>(null)
  const [form] = Form.useForm()

  useEffect(() => {
    reset()
  }, [])

  const calculate = (record: CashDeclaration) => {
    if (record) {
      return (
        (record.HundredDollar * 10000 +
          record.FiftyDollar * 5000 +
          record.TwentyDollar * 2000 +
          record.TenDollar * 1000 +
          record.FiveDollar * 500 +
          record.TwoDollar * 200 +
          record.OneDollar * 100 +
          record.FiftyCent * 50 +
          record.TwentyCent * 20 +
          record.TenCent * 10 +
          record.FiveCent * 5) / 100
      )
    } else return 0

  }

  const Columns = [
    {
      title: "Start Time",
      dataIndex: "StartDateTime",
      key: "StartDateTime",
      render: (date: string) => <Typography>{moment(date).format(dateFormat)}</Typography>
    },
    {
      title: "End Time",
      dataIndex: "EndDateTime",
      key: "EndDateTime",
      render: (date: string) => <Typography>{moment(date).format(dateFormat)}</Typography>
    },
    {
      title: "Total Cash",
      dataIndex: "Total",
      key: "Total",
      render: (total: any, record: CashDeclaration) => <Typography>${calculate(record).toFixed(2)}</Typography>
    },
    {
      title: "Staff",
      dataIndex: "OpFirebaseUid",
      key: "OpFirebaseUid"
    },
    {
      title: "Operations",
      dataIndex: "Operations",
      key: "Operations",
      render: (text: any, item: CashDeclaration) =>
        <button
          className="viewDetailsButton"
          onClick={() => {
            setCurCash(item)

          }}

        >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/details-icon.png"} className="pageIcon" />
          View Details
        </button>
      // <Button
      //   type="primary"
      //   // size="large" 
      //   onClick={() => {
      //     setCurCash(item)
      //   }}
      //   style={{ width: 100, fontSize: 14, textAlign: "center", alignItems: "center", justifyContent: "center", display: "flex" }}
      // >
      //   View Details
      // </Button>
    }
  ]

  const onFinish = async (values: any) => {
    console.log("Form的变量是", values)
    let startDate: string | undefined = undefined
    let endDate: string | undefined = undefined
    if (values.dateRange) {
      startDate = values.dateRange[0].format("YYYY-MM-DD")
      endDate = values.dateRange[1].format("YYYY-MM-DD")
    }
    const payload = {
      page: 1,
      pageSize: 5,
      MachineID: machineID,
      dateFrom: startDate,
      dateTo: endDate,
      SortBy: "-CreateDate"
    }
    console.log("提交参数", payload)
    const res = await listCashDeclarations(payload)
    console.log('cashDeclaration查询结果是。。。', res)
    if (res) {
      const payload = {
        list: res.data,
        pagination: {
          current: 1,
          pageSize: 5,
          total: res.total,
        }
      }
      dispatch(refreshCashDeclarationList(payload))
    }

  }


const onOpenDraw = () => {
  //@ts-ignore
  if (window?.electronAPI) {
    //@ts-ignore
    console.log('open drawer in homepage')
    //@ts-ignore
    window.electronAPI.openDrawComponent((response) => {
      console.log('Todo add unsale open draw log: ', response);
    });
  } else console.log("cant load electronAPI:", window)
  const openRecord: any = {
    Reasons: "no sales"
  };
  PostDrawOpen(openRecord)
}

  const reset = async () => {
    const res = await listCashDeclarations({ page: 1, pageSize: 5, MachineID: machineID })
    if (res) {
      const payload = {
        list: res.data,
        pagination: {
          current: 1,
          pageSize: 5,
          total: res.total,
        }
      }
      dispatch(refreshCashDeclarationList(payload))
    }
    form.setFieldsValue({ "dateRange": undefined })
  }


  return (
    <div className="doublePageContainer" style={{ width: width * 0.99, height: height * 0.99 }}>
      {/* <div className="doublePage01">
        <div style={{ width: "100%", marginLeft: 10, marginRight: "-40px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "inline-flex" , flexDirection: "row", alignItems: "center"}}>
            <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/invoice-icon.png"} className="pageIcon" /></div>
            <div className="pageTitle" style={{ fontSize: "20px" }}>CashDeclaration List</div>
          </div>
          <Form
            form={form}
            name="searchCashDeclaration"
            onFinish={onFinish}
          >
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: 30 }}>
              <Form.Item name={"canceled"} >
                <button
                  className="pageSearchButton"
                  onClick={reset}
                  style={{ marginRight: 10, alignSelf: "center" }}
                >
                  Show All
                </button>
              </Form.Item>
              <Form.Item name={"dateRange"} >
                <RangePicker style={{ height: 30, borderRadius: "15px", width: "270px", paddingRight: "50px" }} />
              </Form.Item>
              <Form.Item name={"submit"} >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "-42px", height: 26, width: 40, display: "flex", justifyContent: "start", alignItems: "center", padding: 0, borderRadius: "15px" }}
                >
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/searchbar-icon.png"} className="pageIcon" />
                </Button>
              </Form.Item>
            </div>
          </Form>

        </div>
      </div> */}
      <div className="doublePage03">
        <Table
          className="pageDetailTable pageDetailTableHeight900 "
          rowClassName={(_, index) => {
            if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
          }}
          scroll={{
            y: height * 0.83,
          }}
          columns={Columns}
          dataSource={CashDeclarations ? CashDeclarations.list : []}
          pagination={{
            ...CashDeclarations?.pagination,
            onChange: async (page: number, pageSize: number) => {
              let startDate: string | undefined = undefined
              let endDate: string | undefined = undefined
              const values = form.getFieldsValue()
              if (values.dateRange) {
                startDate = values.dateRange[0].format("YYYY-MM-DD")
                endDate = values.dateRange[1].format("YYYY-MM-DD")
              }
              const res = await listCashDeclarations({
                page,
                pageSize,
                MachineID: machineID,
                dateFrom: startDate,
                dateTo: endDate,
                SortBy: "-CreateDate"
              })
              if (res) {
                const payload = {
                  list: res.data,
                  pagination: {
                    current: page,
                    pageSize: pageSize,
                    total: res.total,
                  }
                }
                dispatch(refreshCashDeclarationList(payload))
              }
            }
          }}
        />
      </div>
      <div className="doublePage05" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Link to="/checkout">
          <button
            className="unCheckedMenu"
            style={{ backgroundColor: "#DCE3EC" }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/return-icon.png"} className="pageIcon" />
            Return
          </button>
        </Link>
        <button
            className="unCheckedMenu"
            style={{ backgroundColor: "#DCE3EC" }}
            onClick={onOpenDraw}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/return-icon.png"} className="pageIcon" />
            Open Draw
          </button>
      </div>
      <div className="doublePage04">
        <CashDeclarationDetail
          declaration={curCash}
          onCancel={() => { setCurCash(null) }}
          reset={reset}
        />
      </div>      
    </div >
  )
}
