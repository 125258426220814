import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Table, Input, Button, Form } from "antd"
import { CartItem, StyleSheet, Total, Order, PaymentTotal } from "../../../models"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { EmptyPaymentTotal } from "../../../constants/data"

interface TotalSectionProps {
  containerStyle?: React.CSSProperties
}

const PaymentTotalSection = ({ containerStyle }: TotalSectionProps) => {

  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { paymentTotal, paymentTotal1, paymentTotal2, paymentType, paymentStep } = Payment

  const [item, setItem] = useState(EmptyPaymentTotal)

  useEffect(() => {
    if (paymentType === "oneOff") {
      setItem(paymentTotal)
    } else if (paymentStep === "split1") {
      setItem(paymentTotal1)
    } else if (paymentStep === "split2") {
      setItem(paymentTotal2)
    }
  }, [paymentTotal, paymentTotal1, paymentTotal2, paymentStep, paymentType])

  return (
    <div style={containerStyle ? containerStyle : { backgroundColor: "#F2F2F2", borderRadius: "10px", padding: "0px", flexDirection: "column", display: "flex" }}>
        <div style={styles.total}>
          <Typography style={styles.totalDescription}>Total Invoice</Typography>
          <Typography style={styles.totalContent}>${item?.totalAmount?.toFixed(2)}</Typography>
        </div>
      {
        paymentType === "split" &&
        
          <div style={styles.change}>
            <Typography style={{...styles.changeDescription }}>Split Payment:</Typography>
            <Typography style={{...styles.changeContent }}>${item?.receivableAmount?.toFixed(2)}</Typography>
          </div>
        
      }
      
        <div style={styles.change}>
          <Typography style={{...styles.changeDescription}}>Receivable:</Typography>
          <Typography style={{...styles.changeContent}}>${item?.receivableAmount?.toFixed(2)}</Typography>
        </div>
      
        <div style={styles.total}>
          <Typography style={styles.totalDescription}>Rounding:</Typography>
          <Typography style={styles.totalContent}>${item?.roundingAmount?.toFixed(2)}</Typography>
        </div>
      
        <div style={styles.total}>
          <Typography style={styles.totalDescription}>Surcharge:</Typography>
          <Typography style={styles.totalContent}>${item?.surchargeAmount?.toFixed(2)}</Typography>
        </div>
      
        <div style={styles.change}>
          <Typography style={{...styles.changeDescription, fontSize: 24 }}>Change:</Typography>
          <Typography style={{...styles.changeContent, fontSize: 40 }}>${item?.changeAmount?.toFixed(2)}</Typography>
        </div>
      
    </div>
  )
}

export default PaymentTotalSection

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 500
    // position: "absolute"
    // display: "flex",
    // justifyContent: "flex-end",
    // alignItems: "flex-end"
  },
  boldFont: {
    fontWeight: "bold"
  },
  totalContainer: {
    right: 10,
    position:"absolute"
  },
  total: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    minWidth: "420",
    height: "50px",
    borderRadius: "4px",
    margin: "10px",
    padding: "5px"
  },
  totalDescription: {
    // fontWeight: "bold",
    fontFamily: "DINPro",
    fontSize: "20px",
    color: "#6B7C91",
    marginRight: 20 ,
    width: 150,
    alignSelf: "center"
  },
  totalContent: {
    fontFamily: "DINPro",
    fontSize: "20px",
    color: "#333333",
    fontWeight: "bold",
    // marginRight: 20 ,
    textAlign: "right",
    alignSelf: "center"
  },
  change: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFE1E1",
    minWidth: "420",
    height: "50px",
    borderRadius: "4px",
    margin: "10px",
    padding: "5px"
  },
  changeDescription: {
    // fontWeight: "bold",
    fontFamily: "DINPro",
    fontSize: "20px",
    color: "#FC2C2C",
    marginRight: 20 ,
    width: 150,
    alignSelf: "center"
  },
  changeContent: {
    fontFamily: "DINPro",
    fontSize: "26px",
    color: "#FC2C2C",
    fontWeight: "bold",
    // marginRight: 20 ,
    textAlign: "right",
    alignSelf: "center"
  },
  button: {
    marginBottom: 20,
    width: 140,
    height: 140
  }
}