import React from "react"

/* ------- Data Structure for Code --------*/

export interface StyleSheet {
  [key: string]: React.CSSProperties
}

export interface Credential {
  username: string
  password: string
}

export enum ScannnerFocusType {
  CustomerCode = "CustomerCode",
  RoyalPayAuthCode = "RoyalPayAuthCode",
  ProductBarcode = "ProductBarcode"
}

export enum UserScreenType {
  Checkout = "Checkout",
  Payment = "Payment",
  Products = "Products",
  Orders = "Orders",
  Customers = "Customers",
  Invoices = "Invoices",
  Setting = "Settings",
  CustomerScreen = "CustomerScreen",
  Refunds = "Refunds"
}

export enum CartItemType {
  Special = "special",
  Promotion = "promotion",
  Normal = "normal",
  Refund = "refund",
  BuyNGetOneFree = "buyngetonefree",
  SpecialPrice = "specialprice",
  SpecialDiscount = "specialdiscount",
  ReductionPrice = "reductionprice",
  Bonus = "bonus"
}

export enum SpecialPriceType {
  SpecialPrice = "SpecialPrice",
  SpecialDiscount = "SpecialDiscount",
  ReductionPrice = "ReductionPrice",
  BuyNGetOneFree = "BuyNGetOneFree",
  Bonus = "Bonus"
}

/* ------- Data Structure In Local --------*/

export interface SpecialPrice {
  BuyNGetOneFree: number | null
  CateID: number
  CreatedAt: string
  EndDate: string
  ID: string
  Live: boolean
  ProductCode: string
  ReductionPrice: number | null
  SpecialDiscount: number | null
  SpecialPrice: number | null
  SpecialPriceWithoutTerm: boolean
  StartDate: string
  Type: SpecialPriceType
  SpecialValue?: number
  SpecialLabel?: string
  SpecialGroupID: string
  BonusRequestProductCode: string
  BonusRequestQty: number | null
  BonusQty: number | null
  AllowRequest: boolean
  AllowBonus: boolean
  SpecialGroup?: SpecialGroup
}

export interface SpecialGroup {
  ID: string
  Note: string
  RequestQty: number | null
  BonusQty: number | null
  SpecialPrices: Array<SpecialInDB>
}

export interface Product {
  id: string
  storeId: string
  name: string
  productCode: string
  price: number
  categoryCode: string | undefined
  category?: Category
  sku?: string
  barCode?: string
  discountPrice?: number
  gstRate?: number
  nameChinese?: string
  nameKorean?: string
  nameJapanese?: string
  stock?: number
  allowedMinPrice?: number
  description?: string
  image?: string
  isFavourite?: boolean
  isAvailable?: boolean
  isScalable?: boolean
  isSearchable?: boolean
  specs?: Array<ProductSpec>
  extras?: Array<ProductExtra>
  createdAt: string
  updatedAt: string
  hits: number
  allowDiscount: boolean
  specialPrices?: Array<SpecialPrice>
  includeGST?: boolean
  isShowImage?: boolean
  fontName?: string
  fontColor?: number
  fontSize?: number
  isFontBold?: boolean
  isFontItalic?: boolean
  isFontUnderline?: boolean
  isFontStrikeout?: boolean
  netPrice: number
  Scalable?: boolean
}

export interface Category {
  id: string
  storeId: string
  categoryCode: string
  name: string
  image?: string
  nameChinese?: string
  description?: string
  priority?: number
  createdAt: string
  updatedAt: string
}

export interface ProductSpec {
  id: string
  storeId: string
  productId: string
  isDefault: boolean
  priceChange: number
  value: string
  createdAt: string
  updatedAt: string
}

export interface ProductExtra {
  id: string
  storeId: string
  name: string
  nameChinese?: string
  nameKorean?: string
  price: number
  description?: string
  description3?: string
  image?: string
  createdAt: string
  updatedAt: string
}

export interface Total {
  amount: number
  netAmount: number
  discountAmount: number
  voucherAmount: number
  gainPoints: number
  redeemPoints: number
  redeemAmount: number
  totalAmount: number
  pointsRatio: number
  gstRatio: number
  gstAmount: number
  totalWithGstAmount: number
  refundAmount: number //negative number
  totalSaveAmount?: number  //折扣金额汇总
  surcharge?: number  //刷卡手续费$0.5 / <$5
}

export interface PaymentTotal extends Total {
  depositAmount: number
  receivableAmount: number
  roundingAmount: number
  surchargeAmount: number
  changeAmount: number
  RoyalPay?: string
  // RoyalPay?: {
  //   partner_order_id: string
  //   channel_order_id: string
  //   order_id: string
  // }
}

export interface OrderItem extends CartItem {
  orderId: string
  hasRefund?: boolean
}

export interface CartItem {
  displayId?: string
  itemId: string
  type: CartItemType
  productId: string
  productInfo: Product
  quantity: number
  unitPrice: number
  itemPrinted?: boolean
  total: number
  allowDiscount: boolean
  applyGst: boolean
  discountRatio: number
  discountAmount: number
  gstRatio: number
  gstAmount: number
  comment?: string
  itemExtras?: Array<ProductExtra>
  itemSpecs?: Array<ProductSpec>
  saveForLater?: boolean
  promotionText?: string
  BuyNGetOneFree?: number
  addedAt: string
  specialInfo?: SpecialPrice
  rowBackground?: string
  originalPrice?: number
  netPrice: number
}

export interface CartOrder {
  id: string
  customerOrderNo: string
  storeId: string
  machineId: string
  type?: string
  amount: number
  surchargeAmount: number
  discoutAmount: number
  taxAmount: number
  refundAmount: number
  totalAmount: number
  cartItems: Array<CartItem>
}

export interface Order extends CartOrder {
  createdAt: string
  updatedAt: string
  customerCode: string
  customerName: string
  customer?: Customer
  status: OrderStatus
  hasRefund: boolean
  staffCode?: string
  staffName?: string
  staff?: User
  invoicePrinted?: boolean
  createdBy?: string
  createdFor?: string
  cardPaymentAmount?: number
  cashPaymentAmount?: number
  QRPaymentAmount?: number
  paidAt?: string
}

export type OrderStatus = "draft" | "submitted" | "finished"

//export interface Staff { // we dont have such entity in backend, change to user
interface Staff { // we dont have such entity in backend, change to user
  id: string                    //FirebaseUid
  storeId: string               //DefaultWarehouseCode
  StaffCode: string             //unknown, perhaps FirebaseUid
  isOwner: boolean              //unknown, perhaps UserGroupCode
  createdAt: string             //missing
  updatedAt: string             //missing
  lastLogin: string             //missing
  name: string                  //Name
  password: string              //Password, shouln't stored in frontend
  deleted: boolean              //missing
  allowConfigureStore: boolean  //unknown
  allowManageStaff: boolean     //unknown
  allowManageProduct: boolean   //unknown
  allowMakeOrder: boolean       //unknown
  allowCheckData: boolean       //unknown
  allowCashier: boolean         //unknown
}

export interface Customer {
  id: string
  storeId: string
  CustomerCode: string
  Points: number
  isOwner: boolean
  createdAt: string
  updatedAt: string
  lastLogin: string
  name: string
  password: string
  Mobile: string
  Email?: string
  deleted: boolean
  allowConfigureStore: boolean
  allowManageStaff: boolean
  allowManageProduct: boolean
  allowMakeOrder: boolean
  allowCheckData: boolean
  allowCashier: boolean
}

/* ------- Data Structure In DB --------*/

export interface User {
  FirebaseUid: string
  DefaultWarehouseCode: string
  UserGroupCode: string
  Email: string
  Name: string
}

export interface InvoiceItem {
  ID: number
  InvoiceHead?: InvoiceHead
  Product?: Product
  ProductCode: string
  Qty: number
  Price: number
  Discount: number
  GSTRate: number
  UnitCost: number
  KindFlag?: number
  NetPrice?: number
  DeductStockQty?: number
  PriceKind?: CartItemType
  CreditedQty?: number
  InvoiceItemID?: number
  OriginalPrice?: number
  ManuallyEnterWeight?: boolean
  InvoiceNo?: string
  RefundQty: number
  promotionText?: string
}

export interface RefundInvoiceItem extends InvoiceItem {
  refundItemId: string
  planRefundQty: number
  refundTotal: number
}

export interface RecvAcct {
  PayBy: string
  RoyalPay?: string
  // RoyalPay?: {
  //   partner_order_id: string
  //   channel_order_id: string
  //   order_id: string
  // }
  // TransactionID: string
  PaidAmount: number
  MachineID: string
  Rounding?: number
  Change?: number
  Transfer: boolean
  PaymentID: number
  Notes?: string
}

export interface InvoiceHead {
  Customer?: CustomerInDB
  CustomerCode: string
  CustomerName?: string
  StoreId: string
  InvoiceNo: string
  InvoiceItems: InvoiceItem[]
  Delivery: boolean
  Printed: boolean
  InvoiceDate?: string
  ReferenceNo?: string
  CustomerOrderNo?: string
  Notes?: string
  DeliveryAddress?: string
  OpName?: string
  Freight?: number
  FreightGSTRate?: number
  OtherCharge?: number
  OtherChargeGSTRate?: number
  OtherChargeDescription?: string
  Amount?: number
  GSTStatus?: boolean
  GST?: number
  PaidAmount?: number
  TotalCost?: number
  KindFlag?: number
  ExemptTax: boolean
  LayByFlag?: string
  MachineID?: string
  WarehouseCode?: string
  TransferDateTime?: Date
  SalesName?: string
  CreditInvoiceKind?: number
  Calc?: boolean
  Confirmed?: boolean
  InternalNotes?: string
  Transfered?: boolean
  RedeemPoints?: number
  RedeemPointsGSTRate?: number
  PointsUploaded?: boolean
  DollarDiscount?: number
  DiscountKind?: number
  CurrentPoints?: number
  VoucherAmount?: number
  VoucherDiscount?: number
  CurrentGSTRate?: number
  RecvAcct?: Array<RecvAcct>
  RecvAccts?: Array<RecvAcct>
  HoldingCustomerOrderNo?: string //placeorder的id，设置后可自动软删除该order 
  RewardPoints?: number
  LoyaltyPoints?: Array<any>
  WareHouseCode?: string
  ReceiptCustomerCopy?: string
}

export interface CustomerOrderHead {
  CustomerOrderNo: string // primary key
  InvoiceHead?: InvoiceHead
  InvoiceNo?: string
  CustomerCode: string
  CustomerOrderItems: CustomerOrderItem[]
  OrderDate: string
  Cancel: boolean
  Holding: boolean
  WarehouseCode: string
  ModifyDate: string
  Amount: number
  Customer?: CustomerInDB
  Operator?: any
  // May not need
  OrderNo?: string
  ProcessControl?: boolean
  Picked?: boolean
  ProcessKind?: number
  DispatchControl?: boolean
  ExpireDate?: string
  Delivery?: boolean
  DeliveryAddress?: string
  Freight?: number
  FreightGSTRate?: number
}

export interface CustomerOrderItem extends InvoiceItem {
  CustomerOrderNo: string
  SoldQty?: number
  ScannedQty?: number
  StartDate?: string
  EndDate?: string
  Label?: string
  Kind?: string
  Value?: number
}


export interface CustomerInDB {
  CustomerCode: string
  Live: boolean
  Telephone?: string
  Fax?: string
  Mobile?: string
  Email?: string
  CreditLimit?: number
  CreditTerm?: string
  ABN?: string
  Adddress?: string
  Suburb?: string
  State?: string
  PostCode?: string
  Country?: string
  AccountManager?: string
  PriceFlag?: string
  DeliveryAddress?: string
  DeliverySuburb?: string
  DeliveryState?: string
  DeliveryPostCode?: string
  DeliveryCountry?: string
  Notes?: string
  MapReference?: string
  Contact?: string
  CustomerName?: string
  AutoPrintStatement?: number
  DefaultDiscountRate?: number
  Grade?: number
  SpecialCustomer?: boolean
  GroupName?: string
  Birthday?: Date
  AccType?: number
  Gender?: number
  LoyaltyPoints?: number
}

export interface SpecialInDB {
  ID: string
  ProductCode: string
  CateID: number
  type: SpecialPriceType
  SpecialPrice?: number
  SpecialDiscount?: number
  ReductionPrice?: number
  BuyNGetOneFree?: number
  SpecialPriceWithoutTerm?: number
  Live: boolean
  StartDate: string
  EndDate: string
  CreatedAt: string
  SpecialGroupID?: string
  BonusRequestProductCode?: string
  BonusRequestQty?: number | null
  BonusQty?: number | null
  AllowRequest?: boolean
  AllowBonus?: boolean
}

export interface ProductInDB {
  ProductCode: string
  CateID?: number
  Barcode: string
  ChineseName?: string
  KoreanName?: string
  Description: string
  Description3?: string
  ClickCountInCurrentPeriod: number
  ClickRate: number
  DeductStockQty: number
  DefaultSalesPrice: number
  DefaultSalesQty: number
  DefaultSupplier: string
  DepartmentCode?: string
  GSTRate: number
  GSTStatus: boolean
  ImageLoadPath: string
  ItemDiscountRate: number
  AllowDiscount: boolean
  LastEditor: string
  LastOrderPrice: number
  LimitStockQty: number
  Location: string
  LowStockLevel: number
  ManualSelectPrice: number
  MaxStockQty: number
  MeasureQty: number
  Measurement?: string
  Memorandum?: string
  MetCashCode?: string
  Metric: number
  ModifyDate: string
  MultiplePrice: number
  Notes?: string
  OnlyPrice1CanBeDiscount: boolean
  OpFirebaseUid?: string
  OpenPrice: number
  PackHeight: number
  PackLength: number
  PackMeasurement: number
  PackWidth: number
  Priority: number
  SalesPrice1: number
  SalesPrice2: number
  SalesPrice3: number
  SalesPrice4: number
  SalesPrice5: number
  SalesPrice6: number
  Scalable: number
  SerialID: number
  ShelfLife?: number
  ShowImage: boolean
  ShowOnSelectForm: boolean
  SpecialKind: boolean
  StockCommitted: boolean
  StockControl: boolean
  StockOnOrder: boolean
  SubDescription?: string
  TareWeight: number
  UnitMeasurement: number
  WETRate?: number
  WETStatus: boolean
  Weight: number
  Live: boolean
  SpecialPrices: SpecialPrice[]
  CategoryName?: string
  FontName?: string
  FontColor?: number
  FontSize?: number
  FontBold?: number
  FontItalic?: number
  FontUnderline?: number
  FontStrikeout?: number
}

export interface CategoryInDB {
  CateID: number
  Category: string
  ImageLoadPath: string
  OrderNo?: string
  ParentCateID?: number
  ShowImage: boolean
  ShowOnTouchPanel: boolean
}
export interface CashDeclaration {
  ID: number
  HundredDollar: number
  FiftyDollar: number
  TwentyDollar: number
  TenDollar: number
  FiveDollar: number
  TwoDollar: number
  OneDollar: number
  FiftyCent: number
  TwentyCent: number
  TenCent: number
  FiveCent: number
  CreateDate: string
  UpdateDate: string
  OpFirebaseUid: string
  LastEditorFirebaseUid: string
  MachineID: string
  StartDateTime: string
  EndDateTime: string
}
export interface DrawerOpenRecord {
  ID: number
  MachineID: string
  Reasons: string
  OpenDateTime: string
  OpFirebaseUid: string
}

export interface SerialPortInfo {
  path: string,
  dataBits: number, // 数据位
  stopBits: number, // 停止位
  parity: "none" | "even" | "odd", // 奇偶校验
  baudRate: number // 波特率
}

export interface Warehouse {
  Address: string | null,   //地址
  Telephone: string | null, // 联系电话
  WarehouseCode: string, // 仓库名称
  WarehouseName: string, // 仓库名称

}
