import { notification } from 'antd';
import React, {useEffect, useCallback} from 'react'

class ErrorBoundary extends React.Component<any> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        notification.warn({
            message: `Encounter a network error, message: ${error.message}`,
            duration: 5,
        });
    }

    render() {
        //   if (this.state.hasError) {
        //     // You can render any custom fallback UI
        //     return <h1>Something went wrong.</h1>;
        //   }

        return this.props.children;
    }
}

export default ErrorBoundary