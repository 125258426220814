import React, { useState, useEffect } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Radio, Input, Space, Typography, Button, Divider, notification, Image } from "antd"
import { CreditCardFilled, DollarCircleFilled } from '@ant-design/icons'
import { useNavigate } from "react-router-dom"
import useViewport from "../../../hooks/useViewport"
import { primary } from "../../../constants/Colors"
import ProgressModal from "../../common/ProgressModal" 
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import CashModal from "./CashModal"
import { 
  setCurrentInvoice, 
  setPaymentTotal, 
  setShowReceiptModal, 
  setPaymentType, 
  setSplitPaymentMethod1, 
  setSplitPaymentMethod2, 
  setSplitPaymentAmount1, 
  setSplitPaymentAmount2,
  setPaymentTotal1,
  setPaymentTotal2,
  setPaymentStep
} from "../../../redux/Payment/slice"
import { calculatePaymentRounding, cartItemsToInvoiceItems, randomInteger, cartItemsToProductCodeOnlyItems, transferPaymentMethod } from "../../../utils/Global"
import ReceiptModal from "./ReceiptModal"
import ActionConfirmModal from "../../common/ActionConfirmModal"
import { InvoiceHead, RecvAcct, Total } from "../../../models"
import { ExampleInvoiceInDB } from "../../../constants/data"
import { postInvoice } from "../../../api/invoice"
import { addInvoice } from "../../../redux/Customer/slice"
import RoyalPayModal from "./RoyalPayModal"
import NumPad from "react-numpad"
import AlertModal from "../../common/AlertModal"
import CardPaymentModal from "./CardPaymentModal"
import { getConfiguration } from "../../../utils/configurate"
const logo_royalpay = require("../../../assets/png/logo_royalpay.png")

interface Props {
  sectionHeightFactor?: number
  sectionWidthFactor?: number
}

interface PaymentMethodProps {
  paymentNo: number
}

export default function PaymentSection({ sectionHeightFactor, sectionWidthFactor }: Props) {
  const { height, width } = useViewport()
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { paymentTotal, currentInvoice, showReceiptModal, paymentType, paymentMethod1, paymentMethod2, paymentAmount1, paymentAmount2 } = Payment
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total, itemsInCart } = Checkout
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer } = Customer

  const [selectedMethod, setSelectedMethod] = useState<"cash" | "card" | "royalpay">("cash")
  // const [paymentType, setPaymentType] = useState<"oneOff" | "split">("oneOff")
  // const [splitOneMethod, setSplitOneMethod] = useState<"cash" | "card" | "royalpay">("cash")
  // const [splitTwoMethod, setSplitTwoMethod] = useState<"cash" | "card" | "royalpay">("cash")
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const [isCashModalVisible, setIsCashModalVisible] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [isRoyalPayModalVisible, setIsRoyalPayModalVisible] = useState<boolean>(false)
  const [splitOneAmount, setSplitOneAmount] = useState(0)
  const [splitTwoAmount, setSplitTwoAmount] = useState(0)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [isCardPaymentModalVisible, setIsCardPaymentModalVisible] = useState(false)
  const PosConfig = getConfiguration("paymentSection")
  const { machineID: MachineID, warehouseCode: WarehouseCode } = PosConfig
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const PaymentTypeSelect = () => {
    return (
      <div style={{ marginBottom: 18, display: "flex", flexDirection: "row" }}>
        <Typography style={{ fontWeight: "bold", marginRight: 80, fontSize: 18 }}>Payment Type: </Typography>
        <Radio.Group 
          onChange={(e: any) => {
            dispatch(setPaymentType({ paymentType: e.target.value }))
          }} 
          value={paymentType}
          defaultValue={"oneOff"}
        >
          <Radio value={"oneOff"} style={{ fontSize: 18, marginRight: 100 }}>One-Off</Radio>
          <Radio value={"split"} style={{ fontSize: 18 }}>Split</Radio>
        </Radio.Group>
      </div>
    )
  }

  const PaymentMethodSelect = ({ paymentNo }: PaymentMethodProps) => {
    return (
      <div style={{ marginBottom: 18, display: "flex", flexDirection: "row" }}>
        <Typography style={{ fontWeight: "bold", marginRight: 10, fontSize: 18 }}>Method: </Typography>
        <Radio.Group 
          onChange={(e: any) => {
            if (paymentNo === 1) {
              dispatch(setSplitPaymentMethod1({ paymentMethod1: e.target.value }))
            } else {
              dispatch(setSplitPaymentMethod2({ paymentMethod2: e.target.value }))
            }
          }} 
          value={paymentNo === 1 ? paymentMethod1 : paymentMethod2}
        >
          <Radio value={"cash"} style={{ fontSize: 18, marginRight: 80 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography >Cash</Typography>
              <DollarCircleFilled style={{ fontSize: 20, color: primary, marginLeft: 10, alignSelf: "center" }}/>
            </div>
          </Radio>
          <Radio value={"card"} style={{ fontSize: 18, marginRight: 80 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography >Card</Typography>
              <CreditCardFilled style={{ fontSize: 20, color: primary, marginLeft: 10, alignSelf: "center" }}/>
            </div>
          </Radio>
          <Radio value={"royalpay"} style={{ fontSize: 18 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ marginRight: 10 }}>RoyalPay</Typography>
              <Image
                src={logo_royalpay}
                width={40}
              />
            </div>
          </Radio>
        </Radio.Group>
      </div>
    )
  }


  const postInvoiceToBackend = async (payBy: string, payAmount: number) => {
    setIsInProgress(true)
    const invoiceItems = cartItemsToInvoiceItems(itemsInCart)
    const productCodeOnlyItems = cartItemsToProductCodeOnlyItems(itemsInCart)
    const invoiceNo =  "TESTINV-" + randomInteger(1, 10000).toString()
    const RecvAccts: Array<RecvAcct> = []
    if (paymentType === "oneOff") {
      RecvAccts.push({
        PayBy: payBy,
        PaidAmount: payAmount,
        MachineID,
        // MachineID: "POS1",
        Transfer: false,
        PaymentID: randomInteger(1,10000)
      })
    } else {
      RecvAccts.push({
        PayBy: transferPaymentMethod(paymentMethod1),
        PaidAmount: paymentAmount1,
        MachineID,
        Transfer: false,
        PaymentID: randomInteger(1,10000)
      })
      RecvAccts.push({
        PayBy: transferPaymentMethod(paymentMethod2),
        PaidAmount: paymentAmount2,
        MachineID,
        Transfer: false,
        PaymentID: randomInteger(1,10000)
      })
    }
  
    const newInvoiceForFrontEnd: InvoiceHead = {
      ...ExampleInvoiceInDB,
      InvoiceNo: invoiceNo,
      Amount: Number(total.totalAmount?.toFixed(2)),
      PaidAmount: Number(total.totalAmount?.toFixed(2)),
      CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
      CustomerName: servingCustomer ? servingCustomer.name : "Cash Customer",
      InvoiceDate: new Date().toString(),
      InvoiceItems: invoiceItems,
      RecvAcct: RecvAccts,
      RewardPoints: Math.floor(total.totalAmount * 1),
      // RewardPoints: Math.floor(total.totalAmount * 3),
      RedeemPoints: total.redeemPoints ? total.redeemPoints : 0,
      GST: total.gstAmount
    }
    const newInvoiceForBackEnd = {
      InvoiceHead: {
        // ...ExampleInvoiceInDB,
        // InvoiceNo: invoiceNo,
        Amount: Number(total.totalAmount.toFixed(2)),
        PaidAmount: Number(total.totalAmount.toFixed(2)),
        CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
        InvoiceDate: new Date().toString(),
        InvoiceItems: productCodeOnlyItems,
        GST: total.gstAmount,
        WarehouseCode,
      },
      RecvAcct: RecvAccts,
      RewardPoints: Math.floor(total.totalAmount * 1),
      // RewardPoints: Math.floor(total.totalAmount * 3),
      RedeemPoints: total.redeemPoints ? total.redeemPoints : 0
    }
    //@ts-ignore
    const result = await postInvoice(newInvoiceForBackEnd)
    console.log('保存发票后。。。', result, newInvoiceForFrontEnd)
    setIsInProgress(false)
    dispatch(addInvoice({ invoice: newInvoiceForFrontEnd }))
    dispatch(setCurrentInvoice({ invoice: newInvoiceForFrontEnd }))
    dispatch(setShowReceiptModal({ isVisible: true }))
  }

  // Cash
  // const postInvoiceToBackend = async (payBy: string, payAmount: number) => {
  //   setIsInProgress(true)
  //   const invoiceItems = cartItemsToInvoiceItems(itemsInCart)
  //   const productCodeOnlyItems = cartItemsToProductCodeOnlyItems(itemsInCart)
  //   const invoiceNo =  "TESTINV-" + randomInteger(1, 10000).toString()
  //   const newInvoiceForFrontEnd: InvoiceHead = {
  //     ...ExampleInvoiceInDB,
  //     InvoiceNo: invoiceNo,
  //     Amount: Number(total.totalAmount?.toFixed(2)),
  //     PaidAmount: Number(total.totalAmount?.toFixed(2)),
  //     CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
  //     InvoiceDate: new Date().toString(),
  //     InvoiceItems: invoiceItems,
  //     RecvAcct: {
  //       PayBy: payBy,
  //       PaidAmount: payAmount,
  //       MachineID: "POS01",
  //     },
  //     RewardPoints: Math.floor(total.totalAmount * 3),
  //     RedeemPoints: total.redeemPoints ? total.redeemPoints : 0
  //   }
  //   const newInvoiceForBackEnd = {
  //     InvoiceHead: {
  //       ...ExampleInvoiceInDB,
  //       InvoiceNo: invoiceNo,
  //       Amount: Number(total.totalAmount.toFixed(2)),
  //       PaidAmount: Number(total.totalAmount.toFixed(2)),
  //       CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
  //       InvoiceDate: new Date().toString(),
  //       InvoiceItems: productCodeOnlyItems,
  //     },
  //     RecvAcct: {
  //       PayBy: payBy,
  //       PaidAmount: payAmount,
  //       MachineID: "POS1",
  //       Transfer: false,
  //       PaymentID: randomInteger(1,10000)
  //     },
  //     RewardPoints: Math.floor(total.totalAmount * 3),
  //     RedeemPoints: total.redeemPoints ? total.redeemPoints : 0
  //   }
  //   //@ts-ignore
  //   await postInvoice(newInvoiceForBackEnd)
  //   setIsInProgress(false)
  //   dispatch(addInvoice({ invoice: newInvoiceForFrontEnd }))
  //   dispatch(setCurrentInvoice({ invoice: newInvoiceForFrontEnd }))
  //   dispatch(setShowReceiptModal({ isVisible: true }))
  // }

  // Card
  // const postInvoiceToBackend = async (payBy: string, payAmount: number) => {
  //   setIsInProgress(true)
  //   const invoiceItems = cartItemsToInvoiceItems(itemsInCart)
  //   const productCodeOnlyItems = cartItemsToProductCodeOnlyItems(itemsInCart)
  //   const invoiceNo =  "TESTINV-" + randomInteger(1, 10000).toString()
  //   const newInvoiceForFrontEnd: InvoiceHead = {
  //     ...ExampleInvoiceInDB,
  //     InvoiceNo: invoiceNo,
  //     Amount: Number(total.totalAmount?.toFixed(2)),
  //     PaidAmount: Number(total.totalAmount?.toFixed(2)),
  //     CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
  //     InvoiceDate: new Date().toString(),
  //     InvoiceItems: invoiceItems,
  //     RecvAcct: {
  //       PayBy: payBy,
  //       PaidAmount: payAmount,
  //       MachineID: "POS01",
  //     },
  //     RewardPoints: Math.floor(total.totalAmount * 3),
  //     RedeemPoints: total.redeemPoints ? total.redeemPoints : 0
  //   }
  //   const newInvoiceForBackEnd = {
  //     InvoiceHead: {
  //       ...ExampleInvoiceInDB,
  //       InvoiceNo: invoiceNo,
  //       Amount: Number(total.totalAmount.toFixed(2)),
  //       PaidAmount: Number(total.totalAmount.toFixed(2)),
  //       CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
  //       InvoiceDate: new Date().toString(),
  //       InvoiceItems: productCodeOnlyItems,
  //     },
  //     RecvAcct: {
  //       PayBy: payBy,
  //       PaidAmount: payAmount,
  //       MachineID: "POS1",
  //       Transfer: false,
  //       PaymentID: randomInteger(1,10000)
  //     },
  //     RewardPoints: Math.floor(total.totalAmount * 3),
  //     RedeemPoints: total.redeemPoints ? total.redeemPoints : 0
  //   }
  //   //@ts-ignore
  //   await postInvoice(newInvoiceForBackEnd)
  //   setIsInProgress(false)
  //   dispatch(addInvoice({ invoice: newInvoiceForFrontEnd }))
  //   dispatch(setCurrentInvoice({ invoice: newInvoiceForFrontEnd }))
  //   dispatch(setShowReceiptModal({ isVisible: true }))
  // }

  //RoyalPay
  // const postInvoiceToBackend = async (payBy: string, payAmount: number) => {
  //   setIsInProgress(true)
  //   const invoiceItems = cartItemsToInvoiceItems(itemsInCart)
  //   const productCodeOnlyItems = cartItemsToProductCodeOnlyItems(itemsInCart)
  //   const invoiceNo =  "TESTINV-" + randomInteger(1, 10000).toString()
  //   const newInvoiceForFrontEnd: InvoiceHead = {
  //     ...ExampleInvoiceInDB,
  //     InvoiceNo: invoiceNo,
  //     Amount: Number(total.totalAmount?.toFixed(2)),
  //     PaidAmount: Number(total.totalAmount?.toFixed(2)),
  //     CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
  //     InvoiceDate: new Date().toString(),
  //     InvoiceItems: invoiceItems,
  //     RecvAcct: {
  //       PayBy: payBy,
  //       PaidAmount: payAmount,
  //       MachineID: "POS01",
  //     },
  //     RewardPoints: Math.floor(total.totalAmount * 3),
  //     RedeemPoints: total.redeemPoints ? total.redeemPoints : 0
  //   }
  //   const newInvoiceForBackEnd = {
  //     InvoiceHead: {
  //       ...ExampleInvoiceInDB,
  //       InvoiceNo: invoiceNo,
  //       Amount: Number(total.totalAmount.toFixed(2)),
  //       PaidAmount: Number(total.totalAmount.toFixed(2)),
  //       CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
  //       InvoiceDate: new Date().toString(),
  //       InvoiceItems: productCodeOnlyItems,
  //     },
  //     RecvAcct: {
  //       PayBy: payBy,
  //       PaidAmount: payAmount,
  //       MachineID: "POS1",
  //       Transfer: false,
  //       PaymentID: randomInteger(1,10000)
  //     },
  //     RewardPoints: Math.floor(total.totalAmount * 3),
  //     RedeemPoints: total.redeemPoints ? total.redeemPoints : 0
  //   }
  //   //@ts-ignore
  //   await postInvoice(newInvoiceForBackEnd)
  //   setIsInProgress(false)
  //   dispatch(addInvoice({ invoice: newInvoiceForFrontEnd }))
  //   dispatch(setCurrentInvoice({ invoice: newInvoiceForFrontEnd }))
  //   dispatch(setShowReceiptModal({ isVisible: true }))
  // }

  const handleCashPayment = (amount: number, type: string) => {
    const roundingResult = calculatePaymentRounding(amount)
    const newPaymentTotal = {
      ...total,
      depositAmount: 0,
      receivableAmount: Number(roundingResult.payable),
      roundingAmount: Number(roundingResult.rounding),
      surchargeAmount: 0,
      changeAmount: 0
    }
    if (type === "oneOff") {
      dispatch(setPaymentStep({ paymentStep: "oneOff" }))
      dispatch(setPaymentTotal({ paymentTotal: newPaymentTotal }))
    } else if (type === "split1") {
      dispatch(setPaymentStep({ paymentStep: "split1" }))
      dispatch(setPaymentTotal1({ paymentTotal1: newPaymentTotal }))
    } else if (type === "split2") {
      dispatch(setPaymentStep({ paymentStep: "split2" }))
      dispatch(setPaymentTotal2({ paymentTotal2: newPaymentTotal }))
    }
    setIsCashModalVisible(true)
  }

  const handleCardPayment = async (amount: number, type: string) => {
    // await postInvoiceToBackend("EFTPOS", total.totalAmount)
    // dispatch(setShowReceiptModal({ isVisible: true }))
    setIsCardPaymentModalVisible(true)
  }

  const handleRoyalPay = (amount: number, type: string) => {
    setIsRoyalPayModalVisible(true)
  }

  const handleSplitPayment = () => {
    if (paymentMethod1 && paymentMethod2 && (paymentAmount1+paymentAmount2)?.toFixed(2) === total.totalAmount.toFixed(2) && paymentAmount1 > 0 && paymentAmount2 > 0) {
      dispatch(setPaymentStep({ paymentStep: "split1" }))
      switch (paymentMethod1) {
        case "cash":
          handleCashPayment(paymentAmount1, "split1")
          break
        case "card":
          handleCardPayment(paymentAmount1, "split1")
          break
        case "royalpay":
          handleRoyalPay(paymentAmount1, "split1")
          break
      }
    } else if ((paymentAmount1+paymentAmount2)?.toFixed(2) === total.totalAmount.toFixed(2) || paymentAmount1 <= 0 || paymentAmount2 <= 0){
      setErrorMsg("Payment Amount is Incorrect, Please Check Input Number!")
      setIsAlertModalVisible(true)
    } else {
      setErrorMsg("Please Choose Both Payment Methods Before Proceed!")
      setIsAlertModalVisible(true)
    }
  }

  const handleSplitPaymentStep2 = () => {
    dispatch(setPaymentStep({ paymentStep: "split2" }))
    switch(paymentMethod2) {
      case "cash":
        handleCashPayment(paymentAmount2, "split2")
        break
      case "card":
        handleCardPayment(paymentAmount2, "split2")
        break
      case "royalpay":
        handleRoyalPay(paymentAmount2, "split2")
        break
    }
  }

  return (
    <div 
      style={{ 
        display: "flex", 
        justifyContent: "flex-start", 
        alignItems: "flex-start", 
        backgroundColor: "white", 
        flexDirection: "column", 
        zIndex: 100, 
        padding: 18, 
        height: sectionHeightFactor ? height * sectionHeightFactor : height * 0.45
      }}
    >
      <PaymentTypeSelect />
      {
        paymentType === "oneOff" 
        ?
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", alignSelf: "center", zIndex: 100 }}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>Please Select Payment Method: </Typography>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 50, alignSelf: "center" }}>
            <div 
              onClick={() => { 
                setSelectedMethod("cash")
                // console.log("cash")
                handleCashPayment(total.totalAmount, "oneOff")
              }} 
              style={{ display: "flex", flexDirection: "column" }}
            >
              <DollarCircleFilled style={{ fontSize: 80, color: primary }}/>
              <Typography style={{ fontSize: 30, color: primary }}>Cash</Typography>
            </div>
            <Divider type="vertical" style={{ backgroundColor: primary, fontSize: 80, alignSelf: "center", marginLeft: 60, marginRight: 60 }}/>
            <div 
              onClick={async () => { 
                setSelectedMethod("card")
                // console.log("card")
                handleCardPayment(total.totalAmount, "oneOff")
              }} 
              style={{ display: "flex", flexDirection: "column" }}
            >
              <CreditCardFilled style={{ fontSize: 80, color: primary }}/>
              <Typography style={{ fontSize: 30, color: primary }}>Credit Card</Typography>
            </div>
            <Divider type="vertical" style={{ backgroundColor: primary, fontSize: 80, alignSelf: "center", marginLeft: 60, marginRight: 60 }}/>
            <div 
              onClick={() => { 
                setSelectedMethod("royalpay")
                handleRoyalPay(total.totalAmount, "oneOff")
              }} 
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Image
                src={logo_royalpay}
                width={150}
                preview={false}
              />
            </div>
          </div>
        </div>
        :
        <div style={{ alignSelf: "center", display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <div style={{ borderStyle: "dashed", padding: 10, borderWidth: "1px" }}>
            <Typography style={{ fontWeight: "bold", fontSize: 18, marginBottom: 18 }}>Split Payment 1: </Typography>
            <PaymentMethodSelect paymentNo={1} />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ fontWeight: "bold", fontSize: 18, marginRight: 18 }}>Amount: </Typography>
              <NumPad.Number
                onChange={(value: string) => { 
                  const amount = Number(value)
                  if (amount > total.totalAmount) {
                    setErrorMsg("The Number Entered Must Not Exceed Total Amount!")
                    setIsAlertModalVisible(true)
                  } else {
                    const restAmount = Number((total.totalAmount - amount).toFixed(2))
                    dispatch(setSplitPaymentAmount1({ paymentAmount1: amount }))
                    dispatch(setSplitPaymentAmount2({ paymentAmount2: restAmount }))
                  }
                }}
                value={paymentAmount1}
                decimal={2}
                negative={false}
              >
                <Input style={{ width: 180, fontSize: 18 }} value={paymentAmount1}/>
              </NumPad.Number>
            </div>
          </div>

          <div style={{ borderStyle: "dashed", padding: 10, borderWidth: "1px" }}>
            <Typography style={{ fontWeight: "bold", fontSize: 18, marginBottom: 18 }}>Split Payment 2: </Typography>
            <PaymentMethodSelect paymentNo={2} />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ fontWeight: "bold", fontSize: 18, marginRight: 18 }}>Amount: </Typography>
              <NumPad.Number
                onChange={(value: string) => { 
                  const amount = Number(value)
                  if (amount > total.totalAmount) {
                    setErrorMsg("The Number Entered Must Not Exceed Total Amount!")
                    setIsAlertModalVisible(true)
                  } else {
                    const restAmount = Number((total.totalAmount - amount).toFixed(2))
                    dispatch(setSplitPaymentAmount1({ paymentAmount1: restAmount }))
                    dispatch(setSplitPaymentAmount2({ paymentAmount2: amount }))
                  }
                }}
                value={paymentAmount2}
                decimal={2}
                negative={false}
              >
                <Input style={{ width: 180, fontSize: 18 }} value={paymentAmount2}/>
              </NumPad.Number>
            </div>
          </div>
          <Button 
            type="primary" 
            onClick={() => { 
              handleSplitPayment()
            }}
            style={{ backgroundColor: "green", fontSize: 20, marginTop: 20, width: 200, height: 50, alignSelf: "center" }}
          >
            Proceed
          </Button>
        </div>
      }
      <ProgressModal isVisible={isInProgress} description={"Transaction In Progress"} />
      <CashModal 
        isVisible={isCashModalVisible} 
        setVisibility={(value: boolean) => setIsCashModalVisible(value)} 
        handleSplitPayment={handleSplitPaymentStep2}
        postInvoiceToBackend={postInvoiceToBackend}
      />
      <RoyalPayModal 
        isVisible={isRoyalPayModalVisible} 
        setVisibility={(value: boolean) => setIsRoyalPayModalVisible(value)}
        handleSplitPayment={handleSplitPaymentStep2}
        postInvoiceToBackend={postInvoiceToBackend}
      />
      <CardPaymentModal 
        isVisible={isCardPaymentModalVisible} 
        setVisibility={(value: boolean) => {
          setIsCardPaymentModalVisible(value)
        }}
        handleSplitPayment={handleSplitPaymentStep2}
        postInvoiceToBackend={postInvoiceToBackend}
      />
      <ReceiptModal 
        isVisible={showReceiptModal} 
        invoice={currentInvoice}
        onCancel={() => dispatch(setShowReceiptModal({ isVisible: false }))}
        // paymentMethod={selectedMethod}
        // setVisibility={(value: boolean) => {
        //   setIsReceiptModalVisible(value)
        // }}
      />
      <AlertModal 
        isVisible={isAlertModalVisible} 
        description={errorMsg} 
        buttonText={"OK"} 
        onCancel={() => setIsAlertModalVisible(false)} 
        onConfirm={() => setIsAlertModalVisible(false)}      
      />
      <ActionConfirmModal 
        isVisible={isConfirmModalVisible} 
        description={"Print the Receipt?"} 
        actionText={"Yes"} 
        cancelText={"No"} 
        onConfirm={async () => {
          setSelectedMethod("card")
          setIsConfirmModalVisible(false)
          setIsInProgress(true)
          const invoiceItems = cartItemsToInvoiceItems(itemsInCart)
          const newInvoice: InvoiceHead = {
            ...ExampleInvoiceInDB,
            InvoiceNo: "TESTINV-" + randomInteger(1, 10000).toString(),
            Amount: Number(paymentTotal.amount.toFixed(2)),
            PaidAmount: Number(paymentTotal.depositAmount.toFixed(2)),
            CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000032",
            //@ts-ignore
            InvoiceDate: new Date().toString(),
            InvoiceItems: invoiceItems
          }
          await postInvoice(newInvoice)
          setIsInProgress(false)
          dispatch(addInvoice({ invoice: newInvoice }))
          dispatch(setCurrentInvoice({ invoice: newInvoice }))
          dispatch(setShowReceiptModal({ isVisible: true }))
        }} 
        onCancel={() => {
          setIsConfirmModalVisible(false)
        }} 
        leftButtonDanger={true}
      />
    </div>
  )
}
