import API, { pageSize } from "../constants/API"
import { generateRequestUrl } from "../utils/Global" 
import { notification } from "antd"
import axios from "axios"

export const listCashDeclarations = async (payload: any) => {
  try {
    const url = generateRequestUrl(API.queryCashDeclaration)
    const config = { params: payload }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const PostCashDeclaration = async ( payload: any) => {
  try {
    const url = generateRequestUrl(API.addCashDeclaration)
    //const config = {}
    const response = await axios.post(url, payload)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong at addCashDeclaration!"
    })
    return e
  }
}