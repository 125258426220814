import React, { useEffect, useState, useCallback } from "react"
import { Modal, Form, Input, Button, Checkbox, notification, Radio, Typography, message } from "antd"
import { GlobalState, freshBarcode, freshWeight } from "../../redux/Globals/slice"
import { userLogin } from "../../redux/User/slice"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { loginUser } from "../../api/user"
import { listCheckoutProductsByPage, listProducts } from "../../api/product"
import { listWarehouses } from "../../api/warehouse"
import { listCategories } from "../../api/category"
import { listCustomers } from "../../api/customer"
import { loadProducts, loadCategories, loadCheckoutProductsByPage, loadCheckoutProductsNextPage, loadCheckoutProductsPrePage, loadWarehouses } from "../../redux/Product/slice"
import { loadAllInvoices, loadCustomers } from "../../redux/Customer/slice"
import { productInDBToProduct, categoryInDBToCategory, customerInDBToCustomer, customerOrderHeadsToOrders, invoiceInDBsToLocalInvoices } from "../../utils/Global"
import { listOrders } from "../../api/order"
import { loadHoldingOrders } from "../../redux/Order/slice"
import { listNoneRefundInvoices } from "../../api/invoice"
import { listRefunds } from "../../api/refund"
import { loadAllRefunds } from "../../redux/Invoice/slice"
import { DisplayDataType } from "../../constants/Enums"
import { getConfiguration } from "../../utils/configurate"

let buffer = '' // buffer for constructing the barcode from key presses

interface LoginModalProps {
  isVisible: boolean
  setVisibility: (value: boolean) => void
}

const LoginModal = ({ isVisible, setVisibility }: LoginModalProps) => {
  const PosConfig = getConfiguration("loginModal")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isScanMode, setIsScanMode] = useState<boolean>(false)
  const Globals: GlobalState = useSelector((state: RootStateOrAny) => state.Globals)
  const [isInProgress, setIsInProgress] = useState(false)

  const fetchData = async () => {
    console.log("重新加载数据。。。。")
    setIsInProgress(true)
    const warehouseData = await listWarehouses()
    // const productsData = await listProducts()
    const categoriesData = await listCategories()
    const customersData = await listCustomers()
    // const ordersData = await listOrders()
    //default load invoice of this machine at first time
    const invoicesData = await listNoneRefundInvoices({MachineID: PosConfig.machineID})
    // const refundsData = await listRefunds()
    const checkoutProductsData = await listCheckoutProductsByPage(1)
    const checkoutProducts = productInDBToProduct(checkoutProductsData.data)
    const productTotal = checkoutProductsData.total
    dispatch(loadCheckoutProductsByPage({ products: checkoutProducts, page: 1, total: productTotal, type: DisplayDataType.All }))
    //同时检索出第二页等待翻页
    const checkoutProductsDataNextPage = await listCheckoutProductsByPage(2)
    const checkoutNextPageProducts = productInDBToProduct(checkoutProductsDataNextPage.data)
    dispatch(loadCheckoutProductsNextPage({ products: checkoutNextPageProducts }))
    dispatch(loadCheckoutProductsPrePage({ products: [] }))
    // const products = productInDBToProduct(productsData.data)
    const categories = categoryInDBToCategory(categoriesData.data)
    const customers = customerInDBToCustomer(customersData.data)
    // const orders = customerOrderHeadsToOrders(ordersData.data)
    const invoices = invoiceInDBsToLocalInvoices(invoicesData.data)
      const invoicesPagination = invoicesData.pagination
    // const refunds = invoiceInDBsToLocalInvoices(refundsData.data)
    // console.log("refunds: ", refunds)
    dispatch(loadWarehouses({ warehouses: warehouseData.data }))
    dispatch(loadCategories({ categories: categories }))
    // dispatch(loadProducts({ products: products }))
    dispatch(loadCustomers({ customers: customers }))
    // dispatch(loadHoldingOrders({ holdingOrders: orders }))
    dispatch(loadAllInvoices({ invoices: invoices, pagination: invoicesPagination }))
    // dispatch(loadAllRefunds({ refunds: refunds }))
    setIsInProgress(false)
  }


  const onFinish = async (values: any) => {
    // console.log('Success:', values)
    const result: any | undefined = await loginUser(values.username, values.password, values.barcode)
    console.log("result: ", result)
    if (result?.user) {
      //@ts-ignore
      dispatch(userLogin({
        uid: result.user.FirebaseUid,
        user: result.user
      }))
      fetchData()//数据重新同步
      setVisibility(false)
      //登录同步到客户屏
      //@ts-ignore
      if (window?.electronAPI) {
        console.log("登录成功同步显示在客户屏...", true)
        //@ts-ignore
        window.electronAPI.signInSync( true, (response) => {
          console.log('登录成功同步显示在客户屏===成功: ', response);
        })
      }
      navigate("/checkout")
    } else {
      notification.error({
        message: "Login Failed",
        duration: 1
      })
      //退出登录同步到客户屏
      //@ts-ignore
      if (window?.electronAPI) {
        console.log("退出登录成功同步显示在客户屏...", false)
        //@ts-ignore
        window.electronAPI.signInSync( false, (response) => {
          console.log('退出登录同步显示在客户屏===成功: ', response);
        })
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const onChange = (e: any) => {
    console.log('radio checked', e.target.value)
    setIsScanMode(e.target.value)
  }

  const ModeSelect = () => {
    return (
      <div style={{ marginBottom: 50, display: "flex", flexDirection: "row" }}>
        <Typography style={{ fontWeight: "bold", marginRight: 10 }}>Login Method: </Typography>
        <Radio.Group onChange={onChange} value={isScanMode}>
          <Radio value={true}>ID Card</Radio>
          <Radio value={false}>password</Radio>
        </Radio.Group>
      </div>
    )
  }

  const handleScan = (value: string) => {
    if (!value || value === '' || !isVisible) {
      console.log("扫用户码不满足条件", value, isVisible)
      return
    }
    console.log("用户码扫到：", value)
    // todo 调用员工码识别api
    onFinish({ barcode: value })
  }

  const scannerCallback = useCallback((event) => {
    let data = buffer || ''
    if (event.key !== 'Enter') { // barcode ends with enter -key
      data += event.key
      buffer = data
      console.log("buffer is ", buffer)
    } else {
      buffer = ''
      if (isVisible) {
        handleScan(data)
      }
    }
  }, [])

  useEffect(() => {
    console.log("开始监听scanner")
    // document.removeEventListener('keypress', scannerCallback, true)
    document.addEventListener('keypress', scannerCallback, true)
    return () => {
      document.removeEventListener('keypress', scannerCallback, true)
    }
  }, [])

  useEffect(() => {
    if (Globals.barcode) {
      handleScan(Globals.barcode)
      dispatch(freshBarcode({
        barcode: ""
      }))
    }
  }, [Globals.barcode])

  return (
    <Modal
      transitionName="none"
      maskTransitionName="none"
      title="Staff Login"
      visible={isVisible}
      maskClosable={false}
      footer={null}
      // okText="Login"
      // onOk={(e) => {
      //   setVisibility(false)
      // }}
      onCancel={(e) => {
        setVisibility(false)
      }}
      width={800}
    >
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <ModeSelect />
        {
          isScanMode ?
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>Please scan your ID</Typography>
            :
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true, username: "admin@aibuild.com", password: "password" }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
        }
      </div>

      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <button className="scanToRefundButton"
          onClick={() => {
            //关闭整个应用
            // message.info("关闭整个应用")
            //@ts-ignore
            if (window?.electronAPI) {
              //@ts-ignore
              window.electronAPI.closeApp();
            } else message.error("Something is wrong at Close App.")
          }}
        >
          Exit App
        </button>
      </div>

    </Modal>
  )
}

export default LoginModal
