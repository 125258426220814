import React, { useState, useEffect, useRef, useCallback } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { Table, Typography, Button } from 'antd';
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { selectCustomer, selectInvoice, loadInvoiceByCustomer, showKeyboard, switchKeyboardLanguage, searchCustomer, searchInvoice, serveCustomer } from "../../redux/Customer/slice"
import { getCustomerByCustomerCode } from "../../api/customer";
type TableProps = Parameters<typeof Table>[0] & { onViewDetail: (item: any) => void, onSelectCustomer: (item: any) => void, newPage: number }

export default function CustomerVirtualTable (props: TableProps) {
  const { columns, scroll, onRow, onViewDetail, onSelectCustomer, newPage } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const [oldPage, setOldPage] = useState(1);
  const dispatch = useDispatch();
  const widthColumnCount = columns!.filter(({ width }) => !width).length;
  const mergedColumns = columns!.map(column => {
    if (column.width) {
      return column;
    }

    return {
      ...column,
      width: Math.floor(tableWidth / widthColumnCount),
    };
  });

  const gridRef = useRef<any>();
  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };
  const onPageUpDown = (newPage: number) => {
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (!v1) return
    if (oldPage < newPage) {//向下翻页
      v1.scrollTop += 624
    } else if (oldPage > newPage) {//向上翻页
      v1.scrollTop -= 624
    }
    setOldPage(newPage)
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);
  useEffect(() => onPageUpDown(newPage), [newPage]);
//begin of 处理扫描invoiceNo事件
let buffer = '' // buffer for constructing the barcode from key presses
const handleBarcodeScan = async (data: string) => {
  console.log('handleBarcodeScan:', data)
  const result = await getCustomerByCustomerCode(data)
  if (result) {
    onViewDetail(result)
  }
}
const scannerCallback = useCallback((event) => {
  let data = buffer || ''
  if (event.key !== 'Enter') { // barcode ends with enter -key
    data += event.key
    buffer = data
  } else {
    buffer = ''
    handleBarcodeScan(data)
  }
}, [])
useEffect(() => {
  document.addEventListener('keypress', scannerCallback, true)
  return () => {
    document.removeEventListener('keypress', scannerCallback, true)
  }
}, [])
//end of 处理扫描invoiceNo事件

  return (
      <Table
        {...props}
        // className="pageMainTable  pageMainTableHighLine"
        className="pageDetailTable pageMainTableHighLine pageDetailTableHeight900"
        rowClassName={(_, index) => {
          if (index%2 > 0) return "rowStyle1"; else return "rowStyle2";}}
        columns={mergedColumns}
        pagination={false}
        scroll={scroll}
      />
  );
}
