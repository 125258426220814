import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, Form, Input, Button, Checkbox, AutoComplete, Typography, notification, Divider } from "antd"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import SearchBar from "../../common/SearchBar"
import { Customer, StyleSheet, Order, InvoiceHead, InvoiceItem, CartItem } from "../../../models"
import useViewport from "../../../hooks/useViewport"
import PaymentTotalSection from "./PaymentTotalSection"
import { setPaymentTotal, setCurrentInvoice, setShowReceiptModal } from "../../../redux/Payment/slice"
import { calculatePaymentRounding, cartItemsToInvoiceItems, cartItemsToProductCodeOnlyItems, randomInteger, syncModalToCustomerScreen } from "../../../utils/Global"
import { postInvoice } from "../../../api/invoice"
import ProgressModal from "../../common/ProgressModal"
import { clearCart } from "../../../redux/Checkout/slice"
import { useNavigate } from "react-router-dom"
import { addInvoice } from "../../../redux/Customer/slice"
import { ExampleInvoiceInDB } from "../../../constants/data"
import { royalpayCreateOrder } from "../../../utils/royalpay-functions"
import ActionConfirmModal from "../../common/ActionConfirmModal"
import AlertModal from "../../common/AlertModal"
import loadSpinner from "../../../assets/gif/Spinner-3.gif"
// import loadSpinner from "../../../assets/gif/loadSpinner.gif"
import { BackendPaymentMethod } from "../../../constants/Enums"
import { doPurchase } from "../../../api/payment"

interface Props {
  isVisible: boolean
  isTransactionSuccess?: boolean | undefined
  setVisibility: (value: boolean) => void
  handleSplitPayment: () => void
  postInvoiceToBackend: (payBy: string, payAmount: number) => void
  onStopInterval?: () => void
  callTransactionApi?: () => void
  setSurChargeBack?: () => void
  setIsCheckoutPaymentModalVisible?: (value: boolean) => void
}

const CardPaymentModal = ({ isVisible, setVisibility, handleSplitPayment, postInvoiceToBackend, onStopInterval, callTransactionApi, isTransactionSuccess, setSurChargeBack, setIsCheckoutPaymentModalVisible }: Props) => {
  const { width, height } = useViewport()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  console.log("isTransaction Success?????", isTransactionSuccess)
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer, customers } = Customer
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total, itemsInCart } = Checkout
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { paymentTotal, paymentAmount1, paymentAmount2, paymentType, paymentStep } = Payment
  const surCharge = 0.1//todo config it from dashboard
  const [options, setOptions] = useState<any[]>([])
  const [selectedKey, setSelectedKey] = useState<string>()
  const [amountPaid, setAmountPaid] = useState<string>("0.00")
  const [change, setChange] = useState<string>("0.00")
  const [rounding, setRounding] = useState<number>(0)
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const [showReceipt, setShowReceipt] = useState<boolean>(false)
  const [authCode, setAuthCode] = useState<string>("")
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState("")

  const handleProceedPayment = async () => {
    if (paymentType === "oneOff") {
      setVisibility(false)
      postInvoiceToBackend(BackendPaymentMethod.EFTPOS, total.totalAmount)
    } else if (paymentStep === "split1") {
      setVisibility(false)
      handleSplitPayment()
    } else if (paymentStep === "split2") {
      setVisibility(false)
      postInvoiceToBackend(BackendPaymentMethod.EFTPOS, total.totalAmount)
    }
  }

  useEffect(() => {//sync customerscreen to close modal when close cardpaymodal in main screen
    if (!isVisible) {
      syncModalToCustomerScreen("closecardpaymentModal")
    }
  }, [isVisible])

  return (
    <Modal
      maskClosable={false}
      transitionName="none"
      maskTransitionName="none"
      className="modalPage"
      title={<div className="modalTitle">Card Payment<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
      visible={isVisible}
      footer={null}
      onCancel={(e) => {
        setIsCheckoutPaymentModalVisible && setIsCheckoutPaymentModalVisible(true)
        setVisibility(false)
        setSurChargeBack && setSurChargeBack()
        syncModalToCustomerScreen("closecardpaymentModal")
      }}
      width={600}
      closable={false}
    >
      {
        paymentType === "oneOff" ?
          <div style={{ marginTop: "-40px" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: 10, backgroundColor: "#F2F2F2", borderRadius: "10px", padding: "10px" }}>
              <div className="normalLabel">{`Invoice Amount${total.totalAmount && total.totalAmount < 5 && total.totalAmount > 0 ? "(Include Surcharge)" : ""}: `}</div>
              <div className="normalValue" style={{ fontSize: 40, color: "#FC2C2C" }}>
                ${total.totalAmount ? total.totalAmount.toFixed(2) : "0.00"}
                {/* ${total.totalAmount? total.totalAmount<5? (total.totalAmount+surCharge).toFixed(2) : total.totalAmount.toFixed(2) : "0.00"} */}
              </div>
            </div>
            <div style={{ margin: 10, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
              <div className="normalLabel">Please Finish Payment on Pinpad...</div>
              {isTransactionSuccess == false ?
                <Typography style={{ fontSize: 25, alignSelf: "center", color: "red" }}>Card Payment Failed, Please Retry.</Typography>
                : isTransactionSuccess == true ? 
                <Typography style={{ fontSize: 25, alignSelf: "center", color: "green" }}>Card Payment Successed.</Typography>
                : <img src={loadSpinner} alt="loading spinner" style={{ margin: "10px" }} />
              }
            </div>
            <div style={{ justifyContent: "space-around", alignItems: "center", display: "flex", flexDirection: "row" }}>
              <button
                style={{ ...styles.button, border: "none", backgroundColor: "#E63737", color: "#fff", fontWeight: "normal" }}
                onClick={() => {
                  setVisibility(false)
                  syncModalToCustomerScreen("closecardpaymentModal")
                  setIsCheckoutPaymentModalVisible && setIsCheckoutPaymentModalVisible(true)
                  setSurChargeBack && setSurChargeBack()
                }}
              >
                Cancel Payment
              </button>
              {isTransactionSuccess === false ?
                <button
                  style={{ ...styles.button, border: "none", backgroundColor: "#32B373", color: "#fff", fontWeight: "normal" }}
                  onClick={() => {
                    callTransactionApi && callTransactionApi()
                  }}
                >
                  Retry Payment
                </button>
                : isTransactionSuccess === true ?
                <button
                // disabled  //
                  style={{ ...styles.button, border: "none", backgroundColor: "#32B373", color: "#fff", fontWeight: "normal" }}
                  onClick={() => {
                    handleProceedPayment()
                  }}
                >
                  Proceed
                </button>
                : 
                null
              }


            </div>
          </div>
          :
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
              <Typography style={{ fontWeight: "bold", marginRight: 10, fontSize: 22, alignSelf: "center" }}>Invoice Amount: </Typography>
              <Typography style={{ fontWeight: "bold", marginRight: 10, fontSize: 25 }}>${total?.totalAmount?.toFixed(2)}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
              <Typography style={{ fontWeight: "bold", marginRight: 10, fontSize: 22, alignSelf: "center" }}>Split Amount: </Typography>
              <Typography style={{ fontWeight: "bold", marginRight: 10, fontSize: 25, color: "red" }}>${paymentStep === "split1" ? paymentAmount1?.toFixed(2) : paymentStep === "split2" ? paymentAmount2?.toFixed(2) : 0}</Typography>
            </div>
            <div style={{ margin: 10, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
              <Typography style={{ fontWeight: "bold", marginRight: 10, fontSize: 22, alignSelf: "center" }}>Please Finish Payment on Pinpad...</Typography>
              {/* <img src={loadSpinner} alt="loading spinner" /> */}
              {isTransactionSuccess == false ?
                <Typography style={{ fontSize: 25, alignSelf: "center", color: "red" }}>Card Payment Failed, Please Retry.</Typography>
                :
                <img src={loadSpinner} alt="loading spinner" style={{ margin: "10px" }} />
              }
            </div>
            <div style={{ justifyContent: "space-around", alignItems: "center", display: "flex", flexDirection: "row" }}>
              <button
                style={{ ...styles.button, border: "none", backgroundColor: "#E63737", color: "#fff", fontWeight: "normal" }}
                onClick={() => {
                  setVisibility(false)
                  syncModalToCustomerScreen("closecardpaymentModal")
                  setIsCheckoutPaymentModalVisible && setIsCheckoutPaymentModalVisible(true)
                  setSurChargeBack && setSurChargeBack()
                }}
              >
                Cancel Payment
              </button>
              {isTransactionSuccess === false ?
                <button
                  style={{ ...styles.button, border: "none", backgroundColor: "#32B373", color: "#fff", fontWeight: "normal" }}
                  onClick={() => {
                    callTransactionApi && callTransactionApi()
                  }}
                >
                  Retry Payment
                </button>
                :
                null
                // <button
                // disabled  //
                //   style={{ ...styles.button, border: "none", backgroundColor: "#32B373", color: "#fff", fontWeight: "normal" }}
                //   onClick={() => {
                //     handleProceedPayment()
                //   }}
                // >
                //   Proceed
                // </button>
              }
            </div>
          </div>
      }
      <ProgressModal isVisible={isInProgress} description={"Processing Payment"} />
      <AlertModal
        isVisible={isAlertModalVisible}
        description={"Please Scan Customer QRCode Before Pay!"}
        buttonText={"OK"}
        onCancel={() => setIsAlertModalVisible(false)}
        onConfirm={() => setIsAlertModalVisible(false)}
      />
    </Modal>
  )
}

export default CardPaymentModal

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  button: {
    fontSize: 20,
    width: 270,
    height: 50,
    borderRadius: 5
  }
}

// MSG response: GetMessageResponse(type=Transaction, content={
//   "cardName": 0,
//   "responseText": "OPERATOR CANCELLED  ",
//   "dateExpiry": "    ",
//   "txnRef": "270220201003333 ",
//   "txnType": "PurchaseCash",
//   "txnFlags": {
//    "offline": false,
//    "receiptPrinted": true,
//    "cardEntry": "Swiped",
//    "payPass": "PayPassNotUsed",
//    "currency": "AUD",
//    "commsMethod": "Unknown",
//    "undefinedFlag7": "0",
//    "undefinedFlag6": "0"
//   },
//   "responseCode": "TM",
//   "availableBalance": 0,
//   "RRN": "000000000000",
//   "amtTip": 0,
//   "catid": "25646200",
//   "clearedFundsBalance": 0,
//   "pairedRequestType": "class pceft.sdk.eftclient.java.EFTTransactionRequest",
//   "caid": "008282592      ",
//   "amtPurchase": 16.72,
//   "purchaseAnalysisData": "000",
//   "pan": "                    ",
//   "track2": "                                        ",
//   "bankDate": "Thu Nov 03 19:58:01 AEDT 2022",
//   "authCode": 0,
//   "amtCash": 0,
//   "cardType": "                    ",
//   "merchant": "00",
//   "settlementDate": "Sun Nov 30 00:00:00 AEST 1969",
//   "balanceReceived": false,
//   "success": false,
//   "stan": 0,
//   "cardAccountType": "Credit"
//  })

// MSG response: GetMessageResponse(type=Transaction, content={
//   "cardName": 4,
//   "responseText": "CONTACT CARD ISSUER ",
//   "dateExpiry": "0522",
//   "txnRef": "270220201003333 ",
//   "txnType": "PurchaseCash",
//   "txnFlags": {
//    "offline": false,
//    "receiptPrinted": true,
//    "cardEntry": "Contactless",
//    "payPass": "PayPassNotUsed",
//    "currency": "AUD",
//    "commsMethod": "Unknown",
//    "undefinedFlag7": "0",
//    "undefinedFlag6": "0"
//   },
//   "responseCode": "14",
//   "availableBalance": 0,
//   "RRN": "200123002858",
//   "amtTip": 0,
//   "catid": "25646200",
//   "clearedFundsBalance": 0,
//   "pairedRequestType": "class pceft.sdk.eftclient.java.EFTTransactionRequest",
//   "caid": "008282592      ",
//   "amtPurchase": 16.72,
//   "purchaseAnalysisData": "000",
//   "pan": "456438.......592    ",
//   "track2": "456438.......592=2205???????????????????",
//   "bankDate": "Thu Nov 03 20:01:23 AEDT 2022",
//   "authCode": 0,
//   "amtCash": 0,
//   "cardType": "VISA                ",
//   "merchant": "00",
//   "settlementDate": "Sun Nov 30 00:00:00 AEST 1969",
//   "balanceReceived": false,
//   "success": false,
//   "stan": 2858,
//   "cardAccountType": "Credit"
//  })
