import React, { useState, useEffect, useRef, useCallback } from 'react'
import { VariableSizeGrid as Grid } from 'react-window'
import ResizeObserver from 'rc-resize-observer'
// import classNames from 'classnames'
import { Table, Typography, Button, Tag } from 'antd'
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
// import { selectCustomer, selectInvoice, loadInvoiceByCustomer, showKeyboard, switchKeyboardLanguage, searchCustomer, searchInvoice, serveCustomer } from "../../redux/Customer/slice"
import { StyleSheet } from "../../models"
import moment from "moment"
import { dateFormat } from "../../constants/data"
// import { checkIfInvoiceContainsRefundItem } from '../../utils/Global'
import "../../css/Global.css"

type TableProps = Parameters<typeof Table>[0] & { onViewDetail: (item: any) => void, onSearchRefund: (item: any) => void }
interface RowProps {
  customerCode: string
}
let buffer = '' // buffer for constructing the barcode from key presses

export default function RefundVirtualTable(props: TableProps) {
  const { columns, scroll, onViewDetail, onSearchRefund } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const dispatch = useDispatch()
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { customers } = Customer

  const widthColumnCount = columns!.filter(({ width }) => !width).length;
  const mergedColumns = columns!.map(column => {
    if (column.width) {
      return column;
    }

    return {
      ...column,
      width: Math.floor(tableWidth / widthColumnCount),
    };
  });

  const gridRef = useRef<any>();
  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  //begin of 处理扫描invoiceNo事件
  const handleBarcodeScan = async (data: string) => {
    console.log('handle退货BarcodeScan:', data)
    onSearchRefund(data)
    // const result = await getInvoiceByInvoiceNo(data)
    // if (result) {
    //   onViewDetail(result)
    // }
  }
  const scannerCallback = useCallback((event) => {
    let data = buffer || ''
    if (event.key !== 'Enter') { // barcode ends with enter -key
      data += event.key
      buffer = data
    } else {
      buffer = ''
      handleBarcodeScan(data)
    }
  }, [])
  useEffect(() => {
    // 电子秤信息捕捉
    //@ts-ignore
    if (window?.electronAPI) {
      //@ts-ignore
      window.electronAPI.handleScanner((event, value) => {
        // console.log("the 退货 barcode is ", value)
        handleBarcodeScan(value)
      })
    }
    document.addEventListener('keypress', scannerCallback, true)
    return () => {
      //@ts-ignore
      if (window?.electronAPI) {
        //@ts-ignore
        window.electronAPI.handleScanner((event, value) => {
          console.log("refund不执行串口监听事件。。。")
        })
      }
      document.removeEventListener('keypress', scannerCallback, true)
    }
  }, [])
  //end of 处理扫描invoiceNo事件
  return (

    <Table
      {...props}
      // tableLayout="fixed"
      // className="pageMainTable"
      className="pageDetailTable pageDetailTableHeight900"
      // className="pageDetailTable pageMainTableHighLine"
      rowClassName={(_, index) => {
        if (index % 2 > 0) return "rowStyle1"; else return "rowStyle2";
      }}
      // className="virtual-table"
      columns={mergedColumns}
      pagination={false}
      scroll={scroll}
      // scroll={{
      //   y: 605,
      // }}
    />
  );
}

const styles: StyleSheet = {
  row: {
    //@ts-ignore
    "&:hover": {
      background: "#efefef"
    },
  }
}
