import React, { useState, useEffect } from "react"
import { Layout, Empty, Drawer, Table, Typography, Button, Tabs, DatePicker } from 'antd'
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons'
import { Product, Category, Order, InvoiceHead, Customer } from "../models"
import OrderDetail from "../components/Order/OrderDetail"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import SearchBar from "../components/common/SearchBar"
import { switchKeyboardLanguage, showKeyboard, searchOrder } from "../redux/Order/slice"
import { Link, useNavigate } from "react-router-dom"
import useViewport from "../hooks/useViewport"
import KeyboardModal from "../components/common/KeyboardModal"
import moment from "moment"
import { dateFormat } from "../constants/data"
import { searchInvoice } from "../redux/Customer/slice"
import RefundVirtualTable from "../components/Refund/RefundVirtualTable"
import RefundDetail from "../components/Refund/RefundDetail"
import { listRefundsByInvoiceNo } from "../api/refund"
import { searchRefund } from "../redux/Invoice/slice"
import "../css/Global.css"

const { Title } = Typography
const { RangePicker } = DatePicker

export default function RefundPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width, height } = useViewport()
  const Invoice = useSelector((state: RootStateOrAny) => state.Invoice)
  const { allInvoices, searchedInvoices, allRefunds, searchedRefunds } = Invoice

  const [currentInvoice, setCurrentInvoice] = useState<InvoiceHead | null>(null)
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [displayType, setDisplayType] = useState<"search" | "all">("all")

  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)

  const showDrawer = () => {
    setIsDrawerVisible(true)
  }

  const onClose = () => {
    setIsDrawerVisible(false)
  }


  const onSearchRefund = async (value: string) => {
    const result = await listRefundsByInvoiceNo(value)
    console.log("result: ", result)
    dispatch(searchRefund({ refunds: result.data }))
    setDisplayType("search")
  }

  const invoiceColumns = [
    {
      title: "Reference Invoice No",
      dataIndex: "ReferenceNo",
      key: "InvoiceNo",
      render: (text: any) => <Typography>{text}</Typography>
    },
    {
      title: "Customer Name",
      dataIndex: "CustomerCode",
      key: "InvoiceNo",
      render: (customerCode: string) => {
        let customerName = "Cash Customer"
        // const filteredCustomers = customers.filter((customer: Customer) => { return customer.CustomerCode === customerCode })
        // if (filteredCustomers.length > 0) {
        //   customerName = filteredCustomers[0].name
        // }
        return (
          <Typography>{customerName}</Typography>
        )
      }
    },

    {
      title: "Refund Date",
      dataIndex: "InvoiceDate",
      key: "InvoiceNo",
      render: (date: string) => <Typography>{moment(date).format(dateFormat)}</Typography>
    },
    {
      title: "Total",
      dataIndex: "PaidAmount",
      key: "Total",
      // width: "50px",
      render: (amount: number) => <Typography style={{ textAlign: "right" }}>{amount?.toFixed(2)}</Typography>
    },
    {
      title: "Operations",
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
      render: (text: any, item: InvoiceHead) =>
        <button
          className="viewDetailsButton"
          // type="primary"
          // size="large" 
          onClick={() => {
            setCurrentInvoice(item)
            // setIsDrawerVisible(true)
          }}
        // style={{ width: 100, fontSize: 14, textAlign: "center", alignItems: "center", justifyContent: "center", display: "flex" }}
        >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/details-icon.png"} className="pageIcon" />
          View Details
        </button>
    }
  ]

  return (
    <div className="doublePageContainerCustomerInvoice" style={{ width, height }}>
      <KeyboardModal
        initialValue={searchValue}
        isVisible={isKeyboardVisible}
        onHideKeyboard={() => {
          console.log('hide triggered')
          setIsKeyboardVisible(false)
        }}
        onConfirm={(value) => {
          setSearchValue(value)
          onSearchRefund(value)
          setIsKeyboardVisible(false)
        }}
        onSelect={(value) => {
          setSearchValue(value)
        }}
      />

      <div className="doublePage01">
        <div style={{ marginLeft: 10, marginRight: -40, marginBottom: 12, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/refund-icon.png"} className="pageIcon" /></div>
          <div className="pageTitle">REFUNDS</div>
          {/* <Title level={4} className="pageTitle">REFUNDS</Title> */}
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <button
              className="pageSearchButton"
              // type="primary" 
              onClick={() => { setDisplayType("all") }}
              style={{ marginRight: 10, alignSelf: "center" }}
            >
              Show All
            </button>
            <SearchBar
              onSearch={onSearchRefund}
              // containerStyle={{ justifyContent: "flex-end", display: "flex", width: width * 0.3 }}
              placeholderText="Search By Reference Invoice No"
              value={searchValue}
              onChange={setSearchValue}
              onClick={() => {
                // dispatch(showKeyboard({ isVisible: true }))
                setIsKeyboardVisible(true)
              }}
            />
          </div>
        </div>
      </div>
      <div className="doublePage03">
        <RefundVirtualTable
          //@ts-ignore
          columns={invoiceColumns}
          dataSource={displayType === "search" ? searchedRefunds : allRefunds}
          pagination={false}
          scroll={{
            y: height * 0.82,
            // scrollToFirstRowOnChange: true
          }}
          onViewDetail={(item) => {
            setCurrentInvoice(item)
            // setIsDrawerVisible(true)
          }}
          onSearchRefund={(item) => onSearchRefund(item)}
        />
      </div>
      <div className="doublePage04">
        {
          currentInvoice ?
            <RefundDetail invoice={currentInvoice} />
            :
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
              <Empty
                description="Please choose a refund invoice to view details."
              />
            </div>
        }
      </div>
      <div className="doublePage05">
        <Link to="/checkout">
          <button
            className="unCheckedMenu"
            style={{ backgroundColor: "#DCE3EC" }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/return-icon.png"} className="pageIcon" />
            Return
          </button>
        </Link>
      </div>
    </div>
  )
}
