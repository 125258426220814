import React, { useState, useEffect, useRef } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Table, Input, Button, Form, notification, Divider, message } from "antd"
import { CartItem, StyleSheet, Total, Order, InvoiceHead, CustomerOrderHead, CustomerOrderItem, RecvAcct } from "../../../models"
import { ExampleCustomer, ExampleInvoiceInDB } from "../../../constants/data"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { clearCart, removeDiscount, clearCurrentOrderNo, setRefundMode, clearRefundCart } from "../../../redux/Checkout/slice"
import { addOrder, addHoldingOrder } from "../../../redux/Order/slice"
import { Link, useNavigate } from "react-router-dom"
import DiscountModal from "../DiscountModal"
import ActionConfirmModal from "../../common/ActionConfirmModal"
import useViewport from "../../../hooks/useViewport"
import { reloadCustomerScreen } from "../../../utils/customerscreen-functions"
import AlertModal from "../../common/AlertModal"
import { cartItemsToInvoiceItems, randomInteger, cartItemsToCustomerOrderItems, calculatePaymentRounding, cartItemsToProductCodeOnlyItems, transferPaymentMethod } from "../../../utils/Global"
import { clearPaymentProcedure, setCurrentInvoice, setPaymentStep, setPaymentTotal, setPaymentTotal1, setPaymentTotal2, setShowReceiptModal, setShowRefundReceiptModal, setReceiptCopy } from "../../../redux/Payment/slice"
import { listOrders, postHoldingOrder } from "../../../api/order"
import CustomerInfoModal from "../Customer/CustomerInfoModal"
import RefundPaymentModal from "../../Refund/RefundPaymentModal"
import RefundInfoModal from "../../Refund/RefundInfoModal"
import PaymentModal from "../Payment/PaymentModal"
import ProgressModal from "../../common/ProgressModal"
import CashModal from "../Payment/CashModal"
import RoyalPayModal from "../Payment/RoyalPayModal"
import CardPaymentModal from "../Payment/CardPaymentModal"
import ReceiptModal from "../Payment/ReceiptModal"
import RefundReceiptModal from "../../Refund/RefundReceiptModal"
import { addInvoice, serveCustomer } from "../../../redux/Customer/slice"
import { postInvoice } from "../../../api/invoice"
import { doPurchase, getMessage, clearMessage, sendOK, sendYes, sendNo } from "../../../api/payment"
import { BackendPaymentMethod } from "../../../constants/Enums"
import ManagerApproveModal from "../../Refund/ManagerApproveModal"
import { getConfiguration } from "../../../utils/configurate"
import { UserState } from "../../../redux/User/slice"
import useSound from 'use-sound'
import { updateTotal } from "../../../redux/Checkout/slice"

const errorBeep = require('../../../assets/sounds/failure-wrong-action.mp3')
const successBeep = require('../../../assets/sounds/success-2.mp3')

type Props = {
  closeModal?: boolean
  isScanableForCustomer?: boolean
  setIsScanableForCustomer?: (value: boolean) => void
}
export default function StaffOperationSection({ closeModal, isScanableForCustomer, setIsScanableForCustomer }: Props) {
  const surCharge = 0.1//todo config it from dashboard
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { height, width } = useViewport()
  const cardPaymentAmount = useRef<number>(0)
  const cardPaymentType = useRef<string>("")
  const User: UserState = useSelector((state: RootStateOrAny) => state.User)
  const { user } = User
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer } = Customer
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { itemsInCart, total, isKeyboardVisible, currentOrderNo, isRefundMode, refundItems } = Checkout
  const Order = useSelector((state: RootStateOrAny) => state.Order)
  const { orders, holdingOrders } = Order
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { paymentTotal, paymentTotal1, paymentTotal2, currentInvoice, showReceiptModal, paymentType, paymentMethod1, paymentMethod2, paymentAmount1, paymentAmount2, showRefundReceiptModal } = Payment

  const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(false)
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState<boolean>(false)
  const [isDiscountModalVisible, setIsDiscountModalVisible] = useState<boolean>(false)
  const [isRemoveDiscountModalVisible, setIsRemoveDiscountModalVisible] = useState<boolean>(false)
  const [isRefundPaymentModalVisible, setIsRefundPaymentModalVisible] = useState<boolean>(false)
  const [isRefundInfoModalVisible, setIsRefundInfoModalVisible] = useState(false)
  const [refundSuccessInvoice, setRefundSuccessInvoice] = useState<InvoiceHead | null>(null)
  const [isCheckoutPaymentModalVisible, setIsCheckoutPaymentModalVisible] = useState(false)
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const [isCashModalVisible, setIsCashModalVisible] = useState<boolean>(false)
  const [isRoyalPayModalVisible, setIsRoyalPayModalVisible] = useState<boolean>(false)
  const [isCardPaymentModalVisible, setIsCardPaymentModalVisible] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false)
  const [receiptProcessed, setReceiptProcessed] = useState(false)
  const [confirmMsg, setConfirmMsg] = useState("")
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [confirmType, setConfirmType] = useState<"discount" | "holdorder">("holdorder")
  const [selectedMethod, setSelectedMethod] = useState<"cash" | "card" | "royalpay">("cash")
  const [messageInterval, setMessageInterval] = useState<any>()
  const [isTransactionSuccess, setIsTransactionSuccess] = useState<boolean | undefined>(undefined)
  const [isManagerApproveModalVisible, setIsManagerApproveModalVisible] = useState(false)
  const [selectedRefundPaymentType, setSeletedRefundPaymentType] = useState(BackendPaymentMethod.Cash)
  const [curDiscount, setCurDiscount] = useState(0)
  const PosConfig = getConfiguration("StaffOperationSection")
  const { machineID: MachineID, warehouseCode: WarehouseCode, sendOK: isSendOK } = PosConfig
  const [playError] = useSound(errorBeep)
  const [playSuccess] = useSound(successBeep)
  console.log("Posconfig is...", PosConfig, isSendOK)
  const [isSurcharge, setIsSurcharge] = useState(false)

  useEffect(() => {
    setIsCustomerModalVisible(false)
    setIsDiscountModalVisible(false)
    setIsRefundPaymentModalVisible(false)
    setIsCheckoutPaymentModalVisible(false)
    setIsCashModalVisible(false)
    setIsRoyalPayModalVisible(false)
    setIsCardPaymentModalVisible(false)
    setIsAlertModalVisible(false)
    setIsConfirmModalVisible(false)
    setIsManagerApproveModalVisible(false)
    dispatch(setShowReceiptModal({ isVisible: false }))
    dispatch(setShowRefundReceiptModal({ isVisible: false }))
  }, [closeModal])

  useEffect(() => {
    if (showReceiptModal || showRefundReceiptModal) {
      setIsScanableForCustomer && setIsScanableForCustomer(false)
    } else if ([isCustomerModalVisible, isDiscountModalVisible, isRefundPaymentModalVisible, isCheckoutPaymentModalVisible, isCashModalVisible, isRoyalPayModalVisible, isCardPaymentModalVisible, isAlertModalVisible, isConfirmModalVisible, isManagerApproveModalVisible,].some(val => Boolean(val))) {
      setIsScanableForCustomer && setIsScanableForCustomer(true)
    } else {
      setIsScanableForCustomer && setIsScanableForCustomer(false)
    }
  }, [
    isCustomerModalVisible,
    isDiscountModalVisible,
    isRefundPaymentModalVisible,
    isCheckoutPaymentModalVisible,
    isCashModalVisible,
    isRoyalPayModalVisible,
    isCardPaymentModalVisible,
    isAlertModalVisible,
    isConfirmModalVisible,
    isManagerApproveModalVisible,
    showReceiptModal,
    showRefundReceiptModal
  ])

  useEffect(() => {
    if (showReceiptModal) {

    }
  }, [showReceiptModal])

  const setPaymentMethod = (value: "cash" | "card" | "royalpay") => {
    setSelectedMethod(value)
  }

  const removeDiscountTrigger = () => {
    const setDiscountPromise = () => new Promise((resolve, reject) => {
      dispatch(removeDiscount({}))
      resolve("finish")
    })
    setDiscountPromise()
      .then(() => {
        // reloadCustomerScreen()
      })
      .catch((e) => console.log(e))
  }

  const generateInvoiceData = () => {
    const invoiceItems = cartItemsToInvoiceItems(itemsInCart)
    const newInvoice: InvoiceHead = {
      ...ExampleInvoiceInDB,
      InvoiceNo: "TESTINV-" + randomInteger(1, 10000).toString(),
      Amount: Number(total.totalAmount.toFixed(2)),
      PaidAmount: 0,
      CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
      InvoiceDate: new Date().toISOString(),
      InvoiceItems: invoiceItems,
      GST: total.gstAmount
    }
    const roundingResult = calculatePaymentRounding(total.totalAmount)
    const newPaymentTotal = {
      ...total,
      depositAmount: 0,
      receivableAmount: Number(roundingResult.payable),
      roundingAmount: Number(roundingResult.rounding),
      surchargeAmount: 0,
      changeAmount: 0
    }
    dispatch(setPaymentTotal({ paymentTotal: newPaymentTotal }))
    dispatch(setCurrentInvoice({ invoice: newInvoice }))
  }

  const holdOrder = async () => {
    if (itemsInCart.length > 0) {
      // refresh the users token
      const count = holdingOrders.length + 1
      let orderNo = currentOrderNo
      if (!orderNo) {
        orderNo = "TESTORD-" + randomInteger(1, 100000).toString()
      }
      const newOrder: Order = {
        id: count.toString() + Date(),
        customerOrderNo: orderNo,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        // createdBy: isSignIn ? user.name : "Supervisor",
        // createdFor: servingCustomer ? servingCustomer.name : "Cash Customer",
        staff: user || undefined,
        staffCode: user?.FirebaseUid,
        staffName: user?.Name,
        customer: servingCustomer ? servingCustomer : undefined,
        customerCode: servingCustomer ? servingCustomer.CustomerCode : undefined,
        customerName: servingCustomer ? servingCustomer.name : undefined,
        // customer: servingCustomer ? servingCustomer : ExampleCustomer,
        // customerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
        // customerName: servingCustomer ? servingCustomer.name : "Cash Customer",
        status: "submitted",
        hasRefund: false,
        surchargeAmount: 0,
        invoicePrinted: false,
        storeId: "STORE01",
        amount: 0,
        discoutAmount: total.discountAmount,
        taxAmount: 0,
        refundAmount: 0,
        totalAmount: total.totalAmount,
        cartItems: itemsInCart,
        machineId: MachineID
        // machineId: "POS01"
      }
      const customerOrderItems = cartItemsToCustomerOrderItems(itemsInCart, orderNo)
      console.log('hold items加工后', itemsInCart, customerOrderItems)
      const newOrderInDB: CustomerOrderHead = {
        Customer: servingCustomer ? servingCustomer : undefined,//保留客户信息以供revoke
        CustomerOrderNo: orderNo,
        // InvoiceHead: newInvoice,
        // InvoiceNo: new Date().toISOString(),
        CustomerOrderItems: customerOrderItems,
        CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
        OrderDate: new Date().toISOString(),
        Cancel: false,
        Holding: true,
        ModifyDate: new Date().toISOString(),
        WarehouseCode,
        Amount: Number(total.totalAmount.toFixed(2)),
      }
      console.log("confirm order", newOrderInDB, newOrder)
      await postHoldingOrder(newOrderInDB, "append")
      dispatch(addHoldingOrder({ order: newOrder }))
      dispatch(clearCart({}))
      dispatch(serveCustomer({ customer: null }))//清空customer信息
      dispatch(clearPaymentProcedure({}))//清空结算缓存
      dispatch(clearCurrentOrderNo({}))
    }
  }

  const postInvoiceToBackend = async (payBy: string, payAmount: number, customerCopy?: string[], royalPay?: string, newTotal?: Total) => {
    console.log('显示paymentTotal', Payment)
    const finalTotal = newTotal ? newTotal : total
    //if paymethod=EFTPOS, transfer paymethod from receipt customer copy
    console.log('curCustomerCopy', customerCopy)
    console.log('显示RoyalPay消息。。。。。', royalPay)
    let CardType: string = payBy
    if (payBy === BackendPaymentMethod.EFTPOS && customerCopy && customerCopy.length > 0) {
      CardType = customerCopy.length > 8 ? customerCopy[8].replaceAll(" ", "") : "unknown"//get card type string and remove space char
      CardType === "unknown" && console.log("cannot get customer copy be covered by transaction message")
      if (CardType !== "MASTERCARD" && CardType !== "VISA") {
        CardType = "DEBIT CARD"
      }
      console.log('CardType changed....', CardType)
    }
    setIsInProgress(true)
    const invoiceItems = cartItemsToInvoiceItems(itemsInCart)
    const productCodeOnlyItems = cartItemsToProductCodeOnlyItems(itemsInCart)
    const invoiceNo = "TESTINV-" + randomInteger(1, 10000).toString()
    const RecvAccts: Array<RecvAcct> = []
    if (paymentType === "oneOff") {
      console.log("oneOff royalpay付款内容是: ", royalPay ? royalPay : paymentTotal.RoyalPay)
      RecvAccts.push({
        PayBy: CardType,
        // PayBy: payBy,
        PaidAmount: CardType === BackendPaymentMethod.Cash ? paymentTotal.depositAmount : payAmount,
        Rounding: CardType === BackendPaymentMethod.Cash ? Number((0 - paymentTotal.roundingAmount).toFixed(2)) : undefined,
        Change: CardType === BackendPaymentMethod.Cash ? Number((0 - paymentTotal.changeAmount).toFixed(2)) : undefined,
        // Rounding: 0 - paymentTotal.roundingAmount,
        // Change: 0 - paymentTotal.changeAmount,
        MachineID,
        Transfer: false,
        PaymentID: randomInteger(1, 10000),
        RoyalPay: CardType === BackendPaymentMethod.RoyalPay ? royalPay ? royalPay : paymentTotal.RoyalPay : undefined
      })
    } else {
      console.log("保存发票时的付款情况", Payment)
      if (transferPaymentMethod(paymentMethod1) === BackendPaymentMethod.EFTPOS && customerCopy && customerCopy.length > 0) {
        CardType = customerCopy[8].replaceAll(" ", "")//get card type string and remove space char
        if (CardType !== "MASTERCARD" && CardType !== "VISA") {
          CardType = "DEBIT CARD"
        }
      } else CardType = transferPaymentMethod(paymentMethod1)
      RecvAccts.push({
        PayBy: CardType,
        PaidAmount: CardType === BackendPaymentMethod.Cash ? paymentTotal1.depositAmount : paymentAmount1,
        Rounding: CardType === BackendPaymentMethod.Cash ? Number((0 - paymentTotal1.roundingAmount).toFixed(2)) : undefined,
        Change: CardType === BackendPaymentMethod.Cash ? Number((0 - paymentTotal1.changeAmount).toFixed(2)) : undefined,
        MachineID,
        Transfer: false,
        PaymentID: randomInteger(1, 10000),
        RoyalPay: CardType === BackendPaymentMethod.RoyalPay ? royalPay ? royalPay : paymentTotal1.RoyalPay : undefined
      })
      if (transferPaymentMethod(paymentMethod2) === BackendPaymentMethod.EFTPOS && customerCopy && customerCopy.length > 0) {
        CardType = customerCopy[8].replaceAll(" ", "")//get card type string and remove space char
        if (CardType !== "MASTERCARD" && CardType !== "VISA") {
          CardType = "DEBIT CARD"
        }
      } else CardType = transferPaymentMethod(paymentMethod2)
      RecvAccts.push({
        PayBy: CardType,
        PaidAmount: CardType === BackendPaymentMethod.Cash ? paymentTotal2.depositAmount : paymentAmount2,
        Rounding: CardType === BackendPaymentMethod.Cash ? Number((0 - paymentTotal2.roundingAmount).toFixed(2)) : undefined,
        Change: CardType === BackendPaymentMethod.Cash ? Number((0 - paymentTotal2.changeAmount).toFixed(2)) : undefined,
        MachineID,
        Transfer: false,
        PaymentID: randomInteger(1, 10000),
        RoyalPay: CardType === BackendPaymentMethod.RoyalPay ? royalPay ? royalPay : paymentTotal2.RoyalPay : undefined
      })
    }
    console.log('现金明细。。。', RecvAccts)
    const newInvoiceForFrontEnd: InvoiceHead = {
      ...ExampleInvoiceInDB,
      InvoiceNo: invoiceNo,
      Amount: Number(finalTotal.totalAmount?.toFixed(2)),
      PaidAmount: Number(finalTotal.totalAmount?.toFixed(2)),
      CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
      CustomerName: servingCustomer ? servingCustomer.name : "Cash Customer",
      CurrentPoints: servingCustomer ? servingCustomer.Points : 0,//add customer points before this transaction
      InvoiceDate: new Date().toString(),
      InvoiceItems: invoiceItems,
      RecvAcct: RecvAccts,
      RewardPoints: Math.floor(finalTotal.totalAmount * 1),
      // RewardPoints: Math.floor(total.totalAmount * 3),
      RedeemPoints: finalTotal.redeemPoints ? finalTotal.redeemPoints : 0,
      GST: finalTotal.gstAmount,
      OtherCharge: finalTotal.surcharge,
      OtherChargeDescription: finalTotal.surcharge ? "Surcharge $0.1 for amount less than $5" : undefined

    }
    const newInvoiceForBackEnd = {
      InvoiceHead: {
        // ...ExampleInvoiceInDB,
        // InvoiceNo: invoiceNo,
        Amount: Number(finalTotal.netAmount.toFixed(2)),
        // Amount: Number(finalTotal.totalAmount.toFixed(2)),
        PaidAmount: Number(finalTotal.totalAmount.toFixed(2)),
        CustomerCode: servingCustomer ? servingCustomer.CustomerCode : "000001",
        InvoiceDate: new Date().toString(),
        InvoiceItems: productCodeOnlyItems,
        GST: finalTotal.gstAmount,
        MachineID,
        WarehouseCode,
        ReceiptCustomerCopy: customerCopy && customerCopy.length > 0 ? customerCopy.toString() : null,
        OtherCharge: finalTotal.surcharge,
        OtherChargeDescription: finalTotal.surcharge ? "Surcharge $0.1" : undefined,
        CurrentPoints: servingCustomer ? servingCustomer.Points : 0,//add customer points before this transaction

      },
      RecvAcct: RecvAccts,
      RewardPoints: Math.floor(finalTotal.totalAmount * 1),
      // RewardPoints: Math.floor(finalTotal.totalAmount * 3),
      RedeemPoints: finalTotal.redeemPoints ? finalTotal.redeemPoints : 0
    }
    console.log("提交前发票数据: ", newInvoiceForBackEnd)
    //@ts-ignore
    const result = await postInvoice(newInvoiceForBackEnd)
    console.log("提交后发票数据: ", result, newInvoiceForFrontEnd)
    setIsInProgress(false)
    dispatch(addInvoice({ invoice: { ...newInvoiceForFrontEnd, ...result } }))
    dispatch(setCurrentInvoice({ invoice: { ...newInvoiceForFrontEnd, ...result } }))
    dispatch(setShowReceiptModal({ isVisible: true }))
    //Modal同步到客户屏
    //@ts-ignore
    if (window?.electronAPI) {
      //@ts-ignore
      window.electronAPI.modalVisibleSync("showReceiptModal", (response) => {
        console.log('发票modal同步显示在客户屏===成功: ', response);
      })
      //@ts-ignore
      window.electronAPI.invoiceSync({ ...newInvoiceForFrontEnd, ...result }, (response) => {
        console.log('发票信息同步显示在客户屏===成功: ', response);
      })
    }
    //clear customerCopy value
    dispatch(setReceiptCopy({ customerCopy: [] }))
    if (payBy === "EFTPOS" || transferPaymentMethod(paymentMethod1) === "EFTPOS" || transferPaymentMethod(paymentMethod2) === "EFTPOS") {
      // clearMessage("发票生成后")
    }

  }

  const handleCashPayment = (amount: number, type: string) => {
    setIsCheckoutPaymentModalVisible(false)
    const roundingResult = calculatePaymentRounding(amount)
    const newPaymentTotal = {
      ...total,
      depositAmount: 0,
      receivableAmount: Number(roundingResult.payable),
      roundingAmount: Number(roundingResult.rounding),
      surchargeAmount: 0,
      changeAmount: 0
    }
    console.log("是否执行现金处理", amount, newPaymentTotal)
    if (type === "oneOff") {
      dispatch(setPaymentStep({ paymentStep: "oneOff" }))
      dispatch(setPaymentTotal({ paymentTotal: newPaymentTotal }))
    } else if (type === "split1") {
      dispatch(setPaymentStep({ paymentStep: "split1" }))
      dispatch(setPaymentTotal1({ paymentTotal1: newPaymentTotal }))
    } else if (type === "split2") {
      dispatch(setPaymentStep({ paymentStep: "split2" }))
      dispatch(setPaymentTotal2({ paymentTotal2: newPaymentTotal }))
    }
    setIsCashModalVisible(true)
    //Modal同步到客户屏
    //@ts-ignore
    if (window?.electronAPI) {
      //@ts-ignore
      window.electronAPI.modalVisibleSync("isCashModalVisible", (response) => {
        console.log('现金modal同步显示在客户屏===成功: ', response);
      })
    }
  }

  const callTransactionApi = async (amountOrigin: number, type: string) => {
    setIsTransactionSuccess(undefined)
    let transFlag: boolean | undefined = undefined//函数内部标识，比useState有效
    let isSentOkKey: boolean = false
    let isSentNoKey: boolean = false
    let customerCopySaved: boolean = false
    let amount: number = amountOrigin
    let newTotal: Total
    if (amountOrigin < 5 && amountOrigin > 0) {//刷卡金额不到$5则收取$0.1的手续费 && amount>0避免refund业务也执行surcharge
      amount += surCharge
      setIsSurcharge(true)
      //修改客户屏的金额和手续费显示
      newTotal = {
        ...total,
        totalAmount: !total.surcharge ? total.totalAmount + surCharge : total.totalAmount,//try agin payment and keep add surcharge only once
        surcharge: surCharge
        // surcharge: total.surcharge ? total.surcharge + surCharge : surCharge
      }
      console.log('newTotal', newTotal)
      dispatch(updateTotal({ input: newTotal }))

    } else setIsSurcharge(false)

    const purchaseRequest = {
      txtPurchaseAmount: Math.abs(amount).toFixed(2),
      txtCashOutAmount: 0,
      applicationCode: 0,
      requestOptionCode: amount > 0 ? 0 : 1
    }
    console.log("金额小数问题：", amountOrigin, amount, Math.abs(amount).toFixed(2))
    await clearMessage("采购开始前")//just only keep this clearmessage.........
    await doPurchase(purchaseRequest)
    let customerCopy: string[] = []//initial for concat
    let intervalCount: number = 0
    const messageInterval = setInterval(async () => {
      intervalCount += 1
      if (intervalCount > 900 && transFlag !== false) {//90秒后仍未获得付款错误则显示付款成功下一步按钮
        // if (intervalCount > 90 && isTransactionSuccess !== false) {//90秒后仍未获得付款错误则显示付款成功下一步按钮
        setIsTransactionSuccess(true)
        transFlag = true
      }
      if (intervalCount > 3000) {
        console.log("付款状态读取循环超时结束", intervalCount)
        clearInterval(messageInterval)
        // clearMessage("付款状态读取循环超时结束")
      }
      console.log('getMesseage start', intervalCount)
      const message = await getMessage()
      //每次收到最新的receipt消息都处理并取代旧的customerCopy
      if (message.type === "Receipt" && message.content.indexOf("APPROVED") > -1) {//customer copy = merchant copy
        setIsTransactionSuccess(true)//这里修改标记触发cardpaymentmodal显示proceed按钮，当ok键不显示时可以手动下一步
        transFlag = true
        if (isSendOK !== false && isSentNoKey === false) {
          sendNo()
          // sendOK()
          isSentNoKey = true
          console.log("尝试发送NO建...")
        }
        // if (message.type === "Receipt" && !receiptProcessed && message.content.indexOf("DECLINED") === -1) {//customer copy = merchant copy
        if (!customerCopySaved) {
          const content = JSON.parse(message.content)
          console.log('客户拷贝是。。。:', content)
          const receiptText: string[] = content.receiptText
          if (receiptText.length > 0) {
            customerCopy = [
              "========================",
              "     CUSTOMER COPY      ",
            ]
            customerCopy = customerCopy.concat(receiptText.slice(9, 25))
            customerCopy.push("========================")
            setReceiptProcessed(true)
            dispatch(setReceiptCopy({ customerCopy: customerCopy }))
          }
          customerCopySaved = true
        }

      }
      if (message.type === "Transaction" || message.type === 'Display') {
        console.log("观察过程消息", message)
      }
      if (message.type === "Transaction" && message.content.indexOf("APPROVED") > -1) {
        playSuccess()//刷卡付款成功提示音
        if (isSendOK !== false && isSentOkKey === false) {
          sendOK()
          isSentOkKey = true
          console.log("尝试发送OK建...")
        }
        const content = JSON.parse(message.content)
        console.log('get Transaction Messeage success', content)
        if (content.success) {
          const customerCopyAppend: string[] = [
            `MID: ${content.caid}`,
            `TID: ${content.catid}`,
            "========================",
          ]
          customerCopy = customerCopy.concat(customerCopyAppend)
          dispatch(setReceiptCopy({ customerCopy: customerCopy }))
          // setIsTransactionSuccess(true)
          clearInterval(messageInterval)
          // clearMessage("开始后续流程前，")//开始后续流程前，先清空java消息池，避免split2的时候如果还是card，会导致提前结束
          console.log('before call postInvoiceToBackend', customerCopy)

          //如果是oneOff或split2，直接生成发票，否则进入split2
          if (type === "split1") {
            //关闭当前cardpaymentmodal
            setIsCardPaymentModalVisible(false)
            handleSplitPaymentStep2()
          } else {
            postInvoiceToBackend(BackendPaymentMethod.EFTPOS, Number(amount.toFixed(2)), customerCopy, "", newTotal)
            setIsCardPaymentModalVisible(false)
          }
        } else {
          setIsTransactionSuccess(false)
          transFlag = false
          clearInterval(messageInterval)
          setIsCardPaymentModalVisible(false)
          setIsCheckoutPaymentModalVisible(true)
          //Modal同步到客户屏
          //@ts-ignore
          if (window?.electronAPI) {
            //@ts-ignore
            window.electronAPI.modalVisibleSync("isCheckoutPyamentModalVisible", (response) => {
              console.log('付款modal同步显示在客户屏===成功: ', response);
            })
          }
        }
      } else if (message.type === "Transaction" && JSON.parse(message.content).success === false) {
        //刷卡失败
        // sendOK() need manually process when payment failed//发送ok按钮事件关闭eftpos界面
        playError()
        console.log("shuaka shibai????")
        setIsTransactionSuccess(false)
        clearInterval(messageInterval)
      }
    }, 100)
    // setMessageInterval(messageInterval)
  }

  const stopInterval = () => {
    clearInterval(messageInterval)
  }

  const handleCardPayment = async (amount: number, type: string) => {
    callTransactionApi(amount, type)
    cardPaymentAmount.current = amount
    cardPaymentType.current = type
    setIsCheckoutPaymentModalVisible(false)
    setIsCardPaymentModalVisible(true)
    //Modal同步到客户屏
    //@ts-ignore
    if (window?.electronAPI) {
      const values = {
        mode: "isCardPaymentModalVisible",
        amount,
        surCharge: amount < 5 && amount > 0 ? surCharge : 0//amount>0避免refund业务也执行surcharge
      }
      //@ts-ignore
      window.electronAPI.modalVisibleSync(JSON.stringify(values), (response) => {
        console.log('刷卡modal同步显示在客户屏===成功: ', response);
      })
    }
  }

  const handleRoyalPay = (amount: number, type: string) => {
    setIsCheckoutPaymentModalVisible(false)
    setIsRoyalPayModalVisible(true)
  }

  const handleSplitPayment = () => {
    if (paymentMethod1 && paymentMethod2 && (paymentAmount1 + paymentAmount2)?.toFixed(2) === total.totalAmount.toFixed(2) && paymentAmount1 > 0 && paymentAmount2 > 0) {
      dispatch(setPaymentStep({ paymentStep: "split1" }))
      switch (paymentMethod1) {
        case "cash":
          handleCashPayment(paymentAmount1, "split1")
          break
        case "card":
          handleCardPayment(paymentAmount1, "split1")
          break
        case "royalpay":
          handleRoyalPay(paymentAmount1, "split1")
          break
      }
    } else if ((paymentAmount1 + paymentAmount2)?.toFixed(2) === total.totalAmount.toFixed(2) || paymentAmount1 <= 0 || paymentAmount2 <= 0) {
      setErrorMsg("Payment Amount is Incorrect, Please Check Input Number!")
      setIsAlertModalVisible(true)
    } else {
      setErrorMsg("Please Choose Both Payment Methods Before Proceed!")
      setIsAlertModalVisible(true)
    }
  }

  const handleSplitPaymentStep2 = () => {
    console.log("now process split 2...")
    dispatch(setPaymentStep({ paymentStep: "split2" }))
    switch (paymentMethod2) {
      case "cash":
        handleCashPayment(paymentAmount2, "split2")
        break
      case "card":
        handleCardPayment(paymentAmount2, "split2")
        break
      case "royalpay":
        handleRoyalPay(paymentAmount2, "split2")
        break
    }
  }

  const onConfirmHold = () => {
    holdOrder()
    setIsConfirmModalVisible(false)
  }

  const onConfirmRemoveDiscount = () => {
    removeDiscountTrigger()
    setIsConfirmModalVisible(false)
  }

  const onRefundSuccess = (invoice: any) => {
    setRefundSuccessInvoice(invoice)
    setIsRefundPaymentModalVisible(false)
    dispatch(clearRefundCart({}))
    dispatch(setRefundMode({ isRefundMode: false }))
    dispatch(setShowRefundReceiptModal({ isVisible: true }))
  }

  const onCheckout = () => {
    generateInvoiceData()
    if (total.totalAmount === 0) {
      postInvoiceToBackend(BackendPaymentMethod.Cash, total.totalAmount)
    } else {

      // setIsCheckoutPaymentModalVisible(true)
      setIsCustomerModalVisible(true)
      //Modal同步到客户屏
      //@ts-ignore
      if (window?.electronAPI) {
        //@ts-ignore
        window.electronAPI.modalVisibleSync("isCustomerModalVisible", (response) => {
          console.log('客户modal同步显示在客户屏===成功: ', response);
        })
      }
    }
  }

  const onManagerApprove = (paymentType: BackendPaymentMethod) => {
    setSeletedRefundPaymentType(paymentType)
    setIsManagerApproveModalVisible(true)
  }

  const handleClick = (event: any) => {//判断recall order是否能跳转
    if (itemsInCart.length > 0) {
      event.preventDefault()
      setErrorMsg("Please hold the current cart before revoking.")
      setIsAlertModalVisible(true)
    }

  }
  const setSurChargeBack = () => {//放弃cardpay时需要回退surcharge
    if (isSurcharge) {
      setIsSurcharge(false)
      //修改客户屏的金额和手续费显示
      const newTotal: Total = {
        ...total,
        totalAmount: total.totalAmount - surCharge,
        surcharge: total.surcharge ? total.surcharge - surCharge : 0
      }
      dispatch(updateTotal({ input: newTotal }))
    }

  }



  return (
    <div style={{}}>
      {isAlertModalVisible ?
        <AlertModal
          isVisible={isAlertModalVisible}
          description={errorMsg}
          buttonText={"OK"}
          onCancel={() => setIsAlertModalVisible(false)}
          onConfirm={() => setIsAlertModalVisible(false)}
        />
        : null
      }

      {isConfirmModalVisible ?
        <ActionConfirmModal
          isVisible={isConfirmModalVisible}
          description={confirmMsg}
          actionText={"Yes"}
          cancelText={"No"}
          onConfirm={confirmType === "holdorder" ? onConfirmHold : onConfirmRemoveDiscount}
          onCancel={() => {
            setIsConfirmModalVisible(false)
          }}
        />
        : null
      }

      {isDiscountModalVisible ?
        <DiscountModal
          isVisible={isDiscountModalVisible}
          setVisibility={(value: boolean) => {
            setIsDiscountModalVisible(value)
          }}
          curDiscount={curDiscount}
          setCurDiscount={(value: number) => {
            setCurDiscount(value)
          }}
        />
        : null
      }


      {isRefundPaymentModalVisible ?
        <RefundPaymentModal
          isVisible={isRefundPaymentModalVisible}
          setVisibility={(value: boolean) => {
            setIsRefundPaymentModalVisible(value)
          }}
          onRefundSuccess={onRefundSuccess}
          onManagerApprove={onManagerApprove}
        />
        : null
      }

      {isManagerApproveModalVisible ?
        <ManagerApproveModal
          isVisible={isManagerApproveModalVisible}
          setVisibility={(value: boolean) => {
            setIsManagerApproveModalVisible(value)
          }}
          onRefundSuccess={onRefundSuccess}
          selectedPaymentType={selectedRefundPaymentType}
        />
        : null
      }

      {isCheckoutPaymentModalVisible ?
        <PaymentModal
          isVisible={isCheckoutPaymentModalVisible}
          setVisibility={(value: boolean) => {
            setIsCheckoutPaymentModalVisible(value)
          }}
          handleCashPayment={handleCashPayment}
          handleCardPayment={handleCardPayment}
          handleRoyalPay={handleRoyalPay}
          handleSplitPayment={handleSplitPayment}
          setPaymentMethod={setPaymentMethod}
        />
        : null
      }
      {isCustomerModalVisible ?
        <CustomerInfoModal
          originTotal={Number(total.amount?.toFixed(2))}
          // originTotal={Number(total.totalAmount?.toFixed(2))}
          isVisible={isCustomerModalVisible}
          setVisibility={(value: boolean) => {
            setIsCustomerModalVisible(value)
            setIsScanableForCustomer && setIsScanableForCustomer(value)
            // setIsCheckoutPaymentModalVisible(!value)
          }}
          onGoing={() => {
            setIsCheckoutPaymentModalVisible(true)
            //Modal同步到客户屏
            //@ts-ignore
            if (window?.electronAPI) {
              //@ts-ignore
              window.electronAPI.modalVisibleSync("isCheckoutPaymentModalVisible", (response) => {
                console.log('付款modal同步显示在客户屏===成功: ', response);
              })
            }
          }}
        />
        : null
      }

      {isCashModalVisible ?
        <CashModal
          isVisible={isCashModalVisible}
          setVisibility={(value: boolean) => setIsCashModalVisible(value)}
          setIsCheckoutPaymentModalVisible={setIsCheckoutPaymentModalVisible}
          handleSplitPayment={handleSplitPaymentStep2}
          postInvoiceToBackend={postInvoiceToBackend}
        />
        : null
      }
      {isRoyalPayModalVisible ?
        <RoyalPayModal
          isVisible={isRoyalPayModalVisible}
          setVisibility={(value: boolean) => setIsRoyalPayModalVisible(value)}
          handleSplitPayment={handleSplitPaymentStep2}
          postInvoiceToBackend={postInvoiceToBackend}
          setIsCheckoutPaymentModalVisible={setIsCheckoutPaymentModalVisible}
        />
        : null
      }
      {isCardPaymentModalVisible ?
        <CardPaymentModal
          isVisible={isCardPaymentModalVisible}
          setVisibility={(value: boolean) => {
            setIsCardPaymentModalVisible(value)
          }}
          setIsCheckoutPaymentModalVisible={setIsCheckoutPaymentModalVisible}
          handleSplitPayment={handleSplitPaymentStep2}
          postInvoiceToBackend={postInvoiceToBackend}
          onStopInterval={stopInterval}
          isTransactionSuccess={isTransactionSuccess}
          callTransactionApi={() => callTransactionApi(cardPaymentAmount.current, cardPaymentType.current)}
          setSurChargeBack={setSurChargeBack}
        />
        : clearInterval(messageInterval)
      }
      {showReceiptModal ?
        <ReceiptModal
          isVisible={showReceiptModal}
          invoice={currentInvoice}
          onCancel={() => {
            dispatch(setShowReceiptModal({ isVisible: false }))
            //Modal同步到客户屏
            //@ts-ignore
            if (window?.electronAPI) {
              //@ts-ignore
              window.electronAPI.modalVisibleSync("closeReceiptModal", (response) => {
                console.log('关闭发票modal同步显示在客户屏===成功: ', response);
              })
            }
          }}
        // paymentMethod={selectedMethod}
        />
        : null
      }
      {showRefundReceiptModal ?
        <RefundReceiptModal
          isVisible={showRefundReceiptModal}
          //@ts-ignore
          invoice={refundSuccessInvoice}
          onCancel={() => dispatch(setShowRefundReceiptModal({ isVisible: false }))}
        />
        : null
      }

      <AlertModal
        isVisible={isAlertModalVisible}
        description={errorMsg}
        buttonText={"OK"}
        onCancel={() => setIsAlertModalVisible(false)}
        onConfirm={() => setIsAlertModalVisible(false)}
      />
      <ProgressModal isVisible={isInProgress} description={"Transaction In Progress"} />
      {
        isRefundMode ?
          <div style={{
            display: "flex", alignItems: "center", justifyContent: "space-around", flexDirection: "row", marginTop: "185px"
          }}>
            <button
              className="staffOperateButton"
              style={{ backgroundColor: "#E63737", width: "150px", marginRight: "5px" }}
              onClick={() => {
                dispatch(setRefundMode({ isRefundMode: false }))
              }}
            >
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/cancel-icon.png"} className="pageIcon" />
              Exit<br />Refund Mode
            </button>
            <button
              className="staffOperateButton"
              style={{ backgroundColor: "#39bc7c", width: "150px" }}
              onClick={async () => {
                if (refundItems.length > 0) {
                  setIsRefundPaymentModalVisible(true)
                } else {
                  console.log("itemInCart是否刷新？？？", refundItems)
                  notification.warning({ message: "Please Add Item to Cart Before Refund!" })
                }
                
              }}
            >
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/discount-icon.png"} className="pageIcon" />
              Proceed<br />Refund
            </button>
          </div>
          :
          <div style={{ display: "block" }}>
            <Divider style={{ width: "301px", marginBottom: "0px" }} />
            <div className="staffOperateContainer">
              <div className="staffOperate11">
                <button
                  className="staffOperateButton"
                  style={{ backgroundColor: "#39bc7c" }}
                  onClick={() => {
                    setIsDiscountModalVisible(true)
                  }}
                >
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/discount-icon.png"} className="pageIcon" />
                  Apply Discount
                </button>
              </div>
              <div className="staffOperate12">
                <button
                  className="staffOperateButton"
                  style={{ backgroundColor: "#39bc7c" }}
                  onClick={async () => {
                    setConfirmMsg("Are you sure to hold this order?")
                    setConfirmType("holdorder")
                    setIsConfirmModalVisible(true)
                  }}
                >
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/holdOrder-icon.png"} className="pageIcon" />
                  Hold <br /> Order
                </button>
              </div>
              <div className="staffOperate21">
                <Link to="/invoices"
                  className="staffOperateButton"
                  style={{ backgroundColor: "#39bc7c" }}
                // onClick={() => window.open("/#/invoices", "_self")}
                >
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/invoice2-icon.png"} className="pageIcon" />
                  Invoice
                </Link>
              </div>
              <div className="staffOperate13">
                <Link to="/holdingorders"
                  className="staffOperateButton"
                  style={{ backgroundColor: "#39bc7c" }}
                  onClick={handleClick}
                >
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/recallhold-icon.png"} className="pageIcon" />
                  Recall <br /> Order
                </Link>
              </div>
              <div className="staffOperate22">
                <button
                  className="staffOperateButton"
                  style={{ backgroundColor: "#2C6EF1", flexDirection: "row" }}
                  onClick={async () => {
                    if (itemsInCart.length > 0) {
                      onCheckout()
                    } else {
                      notification.warning({ message: "Please Add Item to Cart Before Checkout!" })
                    }
                  }}
                >
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/processPay-icon.png"} style={{ width: 32, height: 32, marginRight: 10 }} />
                  Proceed <br /> Payment
                </button>
              </div>
            </div>
            <Divider style={{ width: "301px", marginTop: "0px" }} />
          </div>
      }

    </div>
  )
}

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 500
    // position: "absolute"
    // display: "flex",
    // justifyContent: "flex-end",
    // alignItems: "flex-end"
  },
  boldFont: {
    fontWeight: "bold"
  },
  totalContainer: {
    right: 10,
    position: "absolute"
  },
  total: {
    display: "flex",
    flexDirection: "row"
  },
  totalDescription: {
    fontWeight: "bold",
    marginRight: 20
  },
  button: {
    marginBottom: 20,
    width: 150,
    height: 160,
    fontSize: 16,
    fontWeight: "bold",
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10
  }

}
// {
        //   "receiptText": [
        //    "------------------------",
        //    "    ABN 85613150691     ",
        //    "KT MART CITY            ",
        //    "MELBOURNE VIC           ",
        //    "MID: 008282592          ",
        //    "TID: 25646200           ",
        //    "Version: 0.9.48         ",
        //    "========================",
        //    "     MERCHANT COPY      ",
        //    "AUG 15, 22         17:22",
        //    "BATCH:            000124",
        //    "INV:                1936",
        //    "STAN:             002723",
        //    "ACCOUNT TYPE      CREDIT",
        //    "RRN:        172242002723",
        //    "VISA                    ",
        //    "A0000000031010          ",
        //    "Visa Debit              ",
        //    "............9057(t)     ",
        //    "SALE    AUD      $0.10  ",
        //    "------------------------",
        //    "APPROVED            00  ",
        //    "APPROVAL CODE     333637",
        //    "ARQC:   665E7393FE7C6FD2",
        //    "APSN:00         ATC:0267",
        //    "------------------------",
        //    "Please use Contactless! ",
        //    "                        ",
        //    " PLEASE RETAIN RECEIPT  ",
        //    "    FOR YOUR RECORDS    ",
        //    "                        ",
        //    "------------------------"
        //   ],
        //   "pairedRequestType": "class pceft.sdk.eftclient.java.EFTReceiptRequest",
        //   "prePrint": false,
        //   "receipt": "ReceiptText"
        //  }
        // {
        //   "receiptText": [
        //    "------------------------",
        //    "    ABN 85613150691     ",
        //    "KT MART CITY            ",
        //    "MELBOURNE VIC           ",
        //    "MID: 008282592          ",
        //    "TID: 25646200           ",
        //    "Version: 0.9.48         ",
        //    "========================",
        //    "     CUSTOMER COPY      ",
        //    "AUG 18, 22         10:38",
        //    "BATCH:            000125",
        //    "INV:                1941",
        //    "STAN:             002732",
        //    "ACCOUNT TYPE      CREDIT",
        //    "RRN:        103858002732",
        //    "MASTERCARD              ",
        //    "A0000000041010          ",
        //    "Debit Mastercard        ",
        //    "............8636(t)     ",
        //    "SALE    AUD      $0.10  ",
        //    "------------------------",
        //    "DECLINED            19  ",
        //    "ARQC:   1A41DA88B5E99AA4",
        //    "APSN:01         ATC:00AD",
        //    "*****DECLINED*****      ",
        //    "CONTACT CARD ISSUER     ",
        //    "                        ",
        //    "                        ",
        //    "Please use Contactless! ",
        //    "                        ",
        //    " PLEASE RETAIN RECEIPT  ",
        //    "    FOR YOUR RECORDS    ",
        //    "                        ",
        //    "------------------------"
        //   ],
        //   "pairedRequestType": "class pceft.sdk.eftclient.java.EFTReceiptRequest",
        //   "prePrint": false,
        //   "receipt": "ReceiptText"
        //  }