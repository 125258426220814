import React, { useState, useEffect, useRef } from "react"
import { Layout, Menu, Breadcrumb, List, Card, Drawer, Table, Typography, Button, Tabs, DatePicker, Empty, Select, Form } from 'antd'
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons'
import { Product, Category, Order, InvoiceHead, Customer } from "../models"
import OrderDetail from "../components/Order/OrderDetail"
import { RootStateOrAny, useSelector, useDispatch } from "react-redux"
import SearchBar from "../components/common/SearchBar"
import { revokeOrder } from "../redux/Checkout/slice"
import { switchKeyboardLanguage, showKeyboard, searchOrder } from "../redux/Order/slice"
import { Link, useNavigate } from "react-router-dom"
import useViewport from "../hooks/useViewport"
import KeyboardModal from "../components/common/KeyboardModal"
import InvoiceDetail from "../components/Invoice/InvoiceDetail"
import moment from "moment"
import { dateFormat } from "../constants/data"
import { searchInvoice, loadAllInvoices } from "../redux/Customer/slice"
import InvoiceVirtualTable from "../components/Invoice/InvoiceVirtualTable"
import "../css/Global.css"
import ScrollButtons from "../components/common/ScrollButtons"
import { Warehouse } from "../models"
import { getConfiguration, saveConfiguration } from "../utils/configurate"
import { listNoneRefundInvoices } from "../api/invoice"
import { invoiceInDBsToLocalInvoices } from "../utils/Global"
// import {  } from "../redux/Invoice/slice"

const { Title } = Typography
const { RangePicker } = DatePicker

export default function InvoiceHistoryPage() {
  const PosConfig = getConfiguration("invoiceHistoryPage")
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width, height } = useViewport()
  const Order = useSelector((state: RootStateOrAny) => state.Order)
  const { orders, selectedOrder, holdingOrders, isKeyboardVisible, searchedOrders, keyboardLanguage } = Order
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { allInvoices, customers, searchedInvoices, invoicePagination } = Customer
  // console.log("allInvoice...", allInvoices)
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceHead | null>(null)
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [filterValue, setfilterValue] = useState<any>(
    {
      WarehouseCode: PosConfig?.warehouseCode,
      MachineID: PosConfig?.machineID,
      InvoiceDate: moment().format('YYYY-MM-DD'),
      page: 1
    }
  )
  const [invoiceDisplayType, setInvoiceDisplayType] = useState<"search" | "all">("all")
  const [pageUpDown, setPageUpDown] = useState<number>(1)
  const [curMachine, setCurMachine] = useState<string>(PosConfig.machineID)
  const [curWarehouse, setCurWarehouse] = useState<string>(PosConfig.warehouseCode)
  const Product = useSelector((state: RootStateOrAny) => state.Product)
  const { warehouses } = Product
  // console.log("仓库列表", warehouses)

  const [oldPage, setOldPage] = useState(1);
  const tableRef = useRef<any>()

  const getMachineEnum = () => {    //得到POS的枚举json作为查询条件select的options
    const Enum: any[] = [{ label: "All", value: "All" }]
    const curStore = warehouses.find((store: Warehouse) => store.WarehouseCode === PosConfig.warehouseCode)
    if (curStore) {
      curStore.Machines?.forEach((Machine: any) => {
        Enum.push({ label: Machine.MachineID, value: Machine.MachineID })
      })

    } else {
      console.log("没有找到匹配的仓库讯息：", PosConfig.warehouseCode)
    }
    return Enum
  }
  const getWarehouseEnum = () => {    //得到shop的枚举json作为查询条件select的options
    const Enum: any[] = [{ label: "All", value: "All" }]
      warehouses?.forEach((shop: any) => {
        Enum.push({ label: shop.WarehouseName, value: shop.WarehouseCode })
      })
    return Enum
  }
  // let pageUpDown = {old: 1, new: 1}

  const showDrawer = () => {
    setIsDrawerVisible(true)
  }

  const onClose = () => {
    setIsDrawerVisible(false)
  }

  const onPageUp = () => {
    onPageUpDown(pageUpDown - 1)
    setPageUpDown(pageUpDown - 1)
  }

  const onPageDown = () => {
    onPageUpDown(pageUpDown + 1)
    setPageUpDown(pageUpDown + 1)
  }

  const onFirstPage = async () => {
    const Data = await listNoneRefundInvoices({...filterValue, page: 1})
    const invoices = invoiceInDBsToLocalInvoices(Data.data)
    const pagination = Data.pagination
    dispatch(loadAllInvoices({ invoices, pagination }))
    setPageUpDown(1)
    //改页面内部移动方式为加载新页面
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    // if (v1) {
    //   // console.log("v1 scroll height: ", v1.scrollHeight)
    //   // console.log("v1 scroll top: ", v1.scrollTop)
    //   v1.scrollTop = 0
    // }
  }
  const onLastPage = async () => {
    const Data = await listNoneRefundInvoices({...filterValue, page: invoicePagination.pageTotal})
    const invoices = invoiceInDBsToLocalInvoices(Data.data)
    const pagination = Data.pagination
    dispatch(loadAllInvoices({ invoices, pagination }))
    setPageUpDown(invoicePagination.pageTotal)
    //改页面内部移动方式为加载新页面
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    // if (v1) {
    //   // console.log("v1 scroll height: ", v1.scrollHeight)
    //   // console.log("v1 scroll top: ", v1.scrollTop)
    //   v1.scrollTop = v1.scrollHeight
    // }
  }

  const onPageUpDown = async (newPage: number) => {
    console.log("look at filtervalue of opPageDown", filterValue)
    const Data = await listNoneRefundInvoices({...filterValue, page: newPage})
    const invoices = invoiceInDBsToLocalInvoices(Data.data)
    const pagination = Data.pagination
    dispatch(loadAllInvoices({ invoices, pagination }))
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    // // let v1 = tableRef.current // document.getElementsByClassName("ant-table-body")[0]
    // console.log(tableRef.current)
    // if (!v1) return
    // if (oldPage < newPage) {//向下翻页
    //   v1.scrollTop += height * 0.38
    // } else if (oldPage > newPage) {//向上翻页
    //   v1.scrollTop -= height * 0.38
    // }
    // setOldPage(newPage)
  };

  // useEffect(() => onPageUpDown(pageUpDown), [pageUpDown]);

  const onSearchInvoice = async (value: string) => {
    setfilterValue({InvoiceNo: value})
    const Data = await listNoneRefundInvoices({InvoiceNo: value, page: 1})
    const invoices = invoiceInDBsToLocalInvoices(Data.data)
    const pagination = Data.pagination
    dispatch(loadAllInvoices({ invoices, pagination }))
    setPageUpDown(1)
    // const result: Array<Product> = allInvoices.filter((invoice: InvoiceHead) => {
    //   return (invoice.InvoiceNo.toLowerCase().includes(value.toLowerCase()) || invoice.CustomerName?.toLowerCase().includes(value.toLowerCase())) ? true : false
    // })
    // setInvoiceDisplayType("search")
    // dispatch(searchInvoice({ invoices: result }))
  }

  const invoiceColumns = [
    {
      title: "Invoice No",
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
      width: "15%",
      render: (text: any) => <Typography>{text}</Typography>
    },
    {
      title: "Customer Name",
      dataIndex: ["Customer","CustomerName"],
      key: "CustomerName",
      width: "10%",
      // render: (customerCode: string) => {
      //   let customerName = "Cash Customer"
      //   const filteredCustomers = customers.filter((customer: Customer) => { return customer.CustomerCode === customerCode })
      //   if (filteredCustomers.length > 0) {
      //     customerName = filteredCustomers[0].name
      //   }
      //   return (
      //     <Typography>{customerName}</Typography>
      //   )
      // }
      render: (text:string) => <Typography>{text}</Typography>
    },

    {
      title: "Invoice Date",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
      width: "10%",
      render: (date: string) => <Typography>{moment(date).format(dateFormat)}</Typography>
    },
    {
      title: "Total",
      dataIndex: "PaidAmount",
      // dataIndex: "Amount",
      key: "Amount",
      width: "10%",
      render: (amount: number) => <Typography style={{ textAlign: "right" }}>${amount?.toFixed(2)}</Typography>
    },
    {
      title: "Points Earned",
      dataIndex: "RewardPoints",
      key: "RewardPoints",
      width: "5%",
      render: (points: number) => <Typography style={{ textAlign: "right" }}>{points ? points : 0}</Typography>
    },
    {
      title: "Machine ID",
      dataIndex: "MachineID",
      key: "MachineID",
      width: "5%",
      render: (text: string) => <Typography style={{ textAlign: "center" }}>{text}</Typography>
    },
    {
      title: "Operations",
      dataIndex: "InvoiceNo",
      key: "Operations",
      width: "12%",
      render: (text: any, item: InvoiceHead) =>
      <div
      //  style={{width: "100%"}}
       >
        <button
          className="viewDetailsButton"

          onClick={() => {
            setCurrentInvoice(item)

          }}

        >
          <img src={process.env.PUBLIC_URL + "/images/assets/icons/details-icon.png"} className="pageIcon" />
          View Details
        </button>
        </div>
    }
    // 
  ]

  // const onMachineSelect = async (value: string) => {
  //   setCurMachine(value)
  //   let invoicesData
  //   console.log("当前选中的是：", value)
  //   if (value === "All") {
  //     invoicesData = await listNoneRefundInvoices()
  //   } else {
  //     invoicesData = await listNoneRefundInvoices({ MachineID: value })
  //   }
  //   const invoices = invoiceInDBsToLocalInvoices(invoicesData.data)
  //   console.log("invoice: ", invoices)
  //   setInvoiceDisplayType("search")
  //   dispatch(searchInvoice({ invoices: invoices }))
  // }
  const queryReset = async () => {
    setfilterValue({})
    const Data = await listNoneRefundInvoices({page: 1})
    const invoices = invoiceInDBsToLocalInvoices(Data.data)
    const pagination = Data.pagination
    dispatch(loadAllInvoices({ invoices, pagination }))
    setPageUpDown(1)
  }

  const onFinish = async (values: any) => {
    console.log("form values===", values)
    const searchValues = {
      WarehouseCode: values.warehouseCode === "All"? undefined : values.warehouseCode,
      MachineID: values.machineID === "All"? undefined : values.machineID,
      InvoiceDate: values.invoiceDate ? moment(values.invoiceDate).format('YYYY-MM-DD') : undefined,
      page: 1
    }
    setfilterValue(searchValues)
    const Data = await listNoneRefundInvoices({ ...searchValues })
    const invoices = invoiceInDBsToLocalInvoices(Data.data)
    const pagination = Data.pagination
    dispatch(loadAllInvoices({ invoices, pagination }))
    setPageUpDown(1)
    // if (invoicesData.total > 0) {
    //   const invoices = invoiceInDBsToLocalInvoices(invoicesData.data)
    //   // setInvoiceDisplayType("search")
    //   // dispatch(searchInvoice({ invoices: invoices }))
    // } else {
    //   // setInvoiceDisplayType("search")
    //   // dispatch(searchInvoice({ invoices: [] }))
    // }
  }

  return (
    <div className="doublePageContainerCustomerInvoice" style={{ width, height }}>
      <KeyboardModal
        isVisible={isKeyboardVisible}
        language={keyboardLanguage}
        onHideKeyboard={() => {
          dispatch(showKeyboard({ isVisible: false }))
        }}
        onSwitchLanguage={() => {
          dispatch(switchKeyboardLanguage({}))
        }}
        onConfirm={(value: string) => {
          setSearchValue(value)
          dispatch(showKeyboard({ isVisible: false }))
          onSearchInvoice(value)
        }}
        onSelect={(value) => {
          setSearchValue(value)
        }}
      />
      <div className="doublePage01">
        <div style={{ width: "100%", marginLeft: 10, marginRight: "-40px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "inline-flex" }}>
            <div><img src={process.env.PUBLIC_URL + "/images/assets/icons/invoice-icon.png"} className="pageIcon" /></div>
            <div className="pageTitle">INVOICES</div>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>
            <Form
              name="invoiceSearchForm"
              form={form}
              style={{ display: "flex", alignItems: "center" }}
              onFinish={onFinish}
            >
              <div style={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                <Form.Item
                  label="Invoice Date"
                  name="invoiceDate"
                  style={{ marginTop: "25px" }}
                >
                  <DatePicker
                    // <RangePicker
                    style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-flex", alignItems: "center", marginRight: "15px" }}>
                <Form.Item
                  name="warehouseCode"
                  label="Shop"
                  style={{ marginTop: "25px" }}
                >
                  <Select
                    style={{ backgroundColor: "#F3F3F3", width: "200px", border: "none", borderRadius: "4px" }}
                    options={getWarehouseEnum()}
                    defaultValue={curWarehouse}
                  // onSelect={onMachineSelect}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-flex", alignItems: "center", marginRight: "15px" }}>
                <Form.Item
                  name="machineID"
                  label="Machine ID"
                  style={{ marginTop: "25px" }}
                >
                  <Select
                    style={{ backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px" }}
                    options={getMachineEnum()}
                    defaultValue={curMachine}
                  // onSelect={onMachineSelect}
                  />
                </Form.Item>
              </div>
              <Button
                className="pageSearchButton"
                type="primary"
                htmlType="submit"
                style={{ marginRight: 20, alignSelf: "center" }}
              >
                Query
              </Button>
              {/* <button
                className="pageSearchButton"
                type="primary"
                htmlType="submit"
                style={{ marginRight: 20, alignSelf: "center" }}
              >
                Query
              </button> */}
            </Form>
            <button
              className="pageSearchButton"
              // type="primary" 
              onClick={queryReset}
              style={{ marginRight: 10, alignSelf: "center" }}
            >
              Reset
            </button>

            <SearchBar
              onSearch={onSearchInvoice}
              placeholderText="Search By Reference Invoice No"
              value={searchValue}
              onChange={setSearchValue}
              onClick={() => {
                dispatch(showKeyboard({ isVisible: true }))
              }}
            />
          </div>

        </div>
      </div>
      <div className="doublePage03">
        <InvoiceVirtualTable
          ref={tableRef}
          //@ts-ignore
          columns={invoiceColumns}
          dataSource={allInvoices}
          // dataSource={invoiceDisplayType === "search" ? searchedInvoices : allInvoices}
          pagination={false}
          scroll={{
            y: height * 0.82,
            // scrollToFirstRowOnChange: true
          }}
          onViewDetail={(item) => {
            setCurrentInvoice(item)
            // setIsDrawerVisible(true)
          }}
          onSearchInvoice={(item) => onSearchInvoice(item)}
          setSearchValue={(value: string) => setSearchValue(value)}
          newPage={pageUpDown}
        />
      </div>
      <div className="doublePage05">
        <Link to="/checkout">
          <button
            className="unCheckedMenu"
            style={{ backgroundColor: "#DCE3EC" }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/return-icon.png"} className="pageIcon" />
            Return
          </button>
        </Link>
        <Link to="/refunds">
          <button
            style={{ backgroundColor: "#DCE3EC" }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/return-icon.png"} className="pageIcon rotateRight" />
            Refund List
          </button>
        </Link>
        <div style={{ width: "305px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <>{`${invoicePagination?.current ?? 1}/${invoicePagination?.pageTotal ?? 1}`}</>
          <ScrollButtons
            onPageDown={onLastPage}
            onPageUp={onFirstPage}
            onRowDown={onPageDown}
            onRowUp={onPageUp}
          />
        </div>
      </div>
      <div className="doublePage04">
        {
          currentInvoice ?
            <InvoiceDetail invoice={currentInvoice} />
            :
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
              <Empty
                description="Please choose a Invoice to view details."
              />
            </div>
        }
      </div>
    </div>
  )
}
