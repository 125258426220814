import "../../css/Global.css"
import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Table, Input, Button, Form, Popconfirm, ConfigProvider, Image } from "antd"
import { DeleteFilled } from "@ant-design/icons"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { CartItem, StyleSheet, Total, Order, CartItemType, InvoiceItem } from "../../models"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { addItemToCart, updateCartItem, updateTotal, removeItemFromCart, clearCart, showKeyboard, updateAllCartItems, clearRefundCart, removeItemFromRefundCart, updateItemInRefundCart } from "../../redux/Checkout/slice"
import { addOrder, addHoldingOrder } from "../../redux/Order/slice"
import { calculateCartTotal, calculateGSTAmount, generateImagePath, handleChangeCartItemQuantity } from "../../utils/Global"
import LoginModal from "../Login/LoginModal"
import NumPad from "react-numpad"
import ActionConfirmModal from "../common/ActionConfirmModal"
import { reloadCustomerScreen } from "../../utils/customerscreen-functions"
import AlertModal from "../common/AlertModal"
import { SmileOutlined } from "@ant-design/icons"
import NumpadModal from "../common/NumpadModal"
import { UserState } from "../../redux/User/slice"
import ScrollButtons from "../common/ScrollButtons"

const { Title } = Typography

interface Props {
  tableHeight?: number
  tableRows?: number
  scrollableHeight?: number
}

export default function RefundCart({ tableHeight, tableRows, scrollableHeight }: Props) {
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { itemsInCart, total, isKeyboardVisible, refundItems } = Checkout
  const Order = useSelector((state: RootStateOrAny) => state.Order)
  const { orders, holdingOrders } = Order
  const User: UserState = useSelector((state: RootStateOrAny) => state.User)
  const { isSignIn } = User
  const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [isNumpadVisible, setIsNumpadVisible] = useState(false)
  const [editItem, setEditItem] = useState<any | null>(null)
  const [numpadValue, setNumpadValue] = useState(0)
  const [numpadDecimal, setNumpadDecimal] = useState(0)
  const [pageUpDown, setPageUpDown] = useState<number>(1)
  const [oldPage, setOldPage] = useState(1)

  useEffect(() => {
    // let box = document.getElementById("box")
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    let v2 = document.getElementsByClassName("ant-table-body")[1]
    // console.log("v: ", document.getElementsByClassName("ant-table-body"))
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
    if (v2) {
      // console.log("v2 scroll height: ", v2.scrollHeight)
      // console.log("v2 scroll top: ", v2.scrollTop)
      v2.scrollTop = v2.scrollHeight
    }
  }, [refundItems.length])

  const handleValueChange = (value: number, editItem: any) => {
    if (value > 9999) {
      setErrorMsg("Quantity must be less than 9999!")
      setIsAlertModalVisible(true)
    } else if (value > getAvailableRefundQty(editItem.ID)) {
      setErrorMsg("Quantity must be less than available refund qty!")
      setIsAlertModalVisible(true)
    } else {
      const newPrice = value * editItem.Price * (1 - editItem.Discount / 100)
      // const newPrice = value * editItem.UnitCost * (1 - editItem.Discount / 100)
      const newItem = {
        ...editItem,
        planRefundQty: value,
        refundTotal: newPrice
      }
      dispatch(updateItemInRefundCart({ item: newItem }))
    }
  }

  const onConfirmValue = (value: number) => {
    handleValueChange(value, editItem!)
    setIsNumpadVisible(false)
  }

  const getAvailableRefundQty = (invoiceItemId: number) => {
    let result = 0
    refundItems.forEach((item: any) => {
      if (item.ID === invoiceItemId) {
        result = item.Qty - item.RefundQty
      }
    })
    return result
  }

  const itemColumns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      width: "50px",
      render: (text: string, record: any, index: number) => String(index + 1).padStart(2, '0')
    },
    {
      title: "Image",
      dataIndex: ["Product", "ImageLoadPath"],
      key: "ID",
      render: (image: string, record: InvoiceItem) => (
        <Image src={generateImagePath(image)} width={40} />
      )
    },
    {
      title: "Description",
      dataIndex: ["Product", "Description"],
      key: "ID",
      width: "30%",
      render: (description: string, record: InvoiceItem) => (
        <Typography>{description}</Typography>
      )
    },
    {
      title: "Refund Qty",
      dataIndex: "planRefundQty",
      key: "ID",
      render: (quantity: number, record: any) => (
        <div
          onClick={() => {
            setEditItem(record)
            setNumpadValue(quantity)
            setNumpadDecimal(0)
            setIsNumpadVisible(true)
          }}
        >
          <Typography
            style={{ fontSize: 20, alignSelf: "center", color: "blue" }}
          >
            {quantity}
          </Typography>
        </div>
      )
    },
    {
      title: "Price",
      dataIndex: "Price",
      // dataIndex: "UnitCost",
      key: "ID",
      render: (text: any) => <Typography>${text.toFixed(2)}</Typography>
    },
    {
      title: "Discount",
      dataIndex: "Discount",
      key: "ID",
      render: (text: any) => <Typography>{text?.toFixed(0)}%</Typography>
    },
    {
      title: "GST",
      dataIndex: "GSTRate",
      key: "ID",
      render: (text: any) => <Typography>{text?.toFixed(0)}%</Typography>
    },
    {
      title: "Refund Total",
      dataIndex: "refundTotal",
      key: "ID",
      render: (text: any, record: any) =>
        <Typography>
          ${text.toFixed(2)}
        </Typography>
    },
  ]

  const columns = [
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>No</Typography.Text>,
      dataIndex: "itemId",
      key: "id",
      // width: "10%",
      width: "30px",
      ellipsis: false,
      render: (text: string, record: any, index: number) => String(index + 1).padStart(2, '0')
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Description</Typography.Text>,
      dataIndex: ["productInfo", "name"],
      key: "productId",
      width: "30%",
      render: (text: any, item: CartItem) => (
        <div>
          {
            item.promotionText ?
              <Typography style={{ fontSize: width > 1500 ? 20 : 15, fontWeight: "bold", color: "red" }}>{item.promotionText}</Typography>
              :
              null
          }
          <Typography style={{ fontSize: width > 1500 ? 20 : 15 }}>{text}</Typography>
          <Typography style={{ fontSize: width > 1500 ? 20 : 15 }}>{item.productInfo.nameKorean}</Typography>
        </div>
      )
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Qty</Typography.Text>,
      dataIndex: "quantity",
      key: "quantity",
      width: "10%",
      render: (quantity: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography style={{ fontSize: 18, alignSelf: "center" }}>{quantity}</Typography>
        </div>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Price</Typography.Text>,
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: "10%",
      render: (price: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography style={{ fontSize: 18, alignSelf: "center" }}>${price.toFixed(2)}</Typography>
        </div>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Discount</Typography.Text>,
      dataIndex: "discountRatio",
      key: "discountRatio",
      width: "12%",
      render: (discount: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
          {
            item.allowDiscount ?
              <Typography
                style={{ fontSize: 20, alignSelf: "center", color: "blue" }}
              >
                {discount.toFixed(0)}%
              </Typography>
              :
              <Typography style={{ fontSize: 18, alignSelf: "center" }}>N/A</Typography>
          }
        </div>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>GST</Typography.Text>,
      dataIndex: "gstRatio",
      width: "10%",
      render: (gst: number, item: CartItem) =>
        <div>
          {
            item.applyGst ?
              <Typography style={{ fontSize: 18 }}>{gst.toFixed(0)}%</Typography>
              :
              <Typography style={{ fontSize: 18, alignSelf: "center" }}>N/A</Typography>
          }
        </div>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Amount</Typography.Text>,
      dataIndex: "total",
      key: "total",
      width: "10%",
      render: (total: number) =>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography style={{ fontSize: 18, alignSelf: "center" }}>${total.toFixed(2)}</Typography>
        </div>
    },

  ]

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center', height: height * 0.25, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
      <SmileOutlined style={{ fontSize: 20 }} />
      <p>No Items In Cart</p>
    </div>
  );

  const onPageUp = () => {
    setPageUpDown(pageUpDown - 1)
  }

  const onPageDown = () => {
    setPageUpDown(pageUpDown + 1)
  }

  const onFirstPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = 0
    }
  }
  const onLastPage = () => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
  }
  const onPageUpDown = (newPage: number) => {
    let v1 = document.getElementsByClassName("ant-table-body")[1]
    if (!v1) return
    if (oldPage < newPage) {//向下翻页
      v1.scrollTop += height * 0.38
    } else if (oldPage > newPage) {//向上翻页
      v1.scrollTop -= height * 0.38
    }
    setOldPage(newPage)
  };

  useEffect(() => onPageUpDown(pageUpDown), [pageUpDown]);

  return (
    <div>
      {isLoginModalVisible ?
        <LoginModal
          isVisible={isLoginModalVisible}
          setVisibility={(value: boolean) => {
            setIsLoginModalVisible(value)
          }}
        />
        : null
      }
      <AlertModal
        isVisible={isAlertModalVisible}
        description={errorMsg}
        buttonText={"OK"}
        onCancel={() => setIsAlertModalVisible(false)}
        onConfirm={() => setIsAlertModalVisible(false)}
      />
      {isNumpadVisible ?
        <NumpadModal
          value={numpadValue.toFixed(0)}
          isVisible={isNumpadVisible}
          decimal={numpadDecimal}
          negative={false}
          onConfirm={onConfirmValue}
          onDismiss={() => {
            setIsNumpadVisible(false)
          }}
        />
        : null
      }

      <ActionConfirmModal
        isVisible={isConfirmModalVisible}
        description={"Are you sure to remove this item from cart?"}
        actionText={"Remove"}
        cancelText={"Cancel"}
        onConfirm={() => {
          if (editItem) {
            const removeItemPromise = () => new Promise((resolve, reject) => {
              const newCartItems = itemsInCart.filter((itemInCart: CartItem) => { return editItem.itemId !== itemInCart.itemId })
              dispatch(removeItemFromCart({ item: editItem }))
              const newTotal = calculateCartTotal(newCartItems)
              dispatch(updateTotal({ input: newTotal }))
              resolve("finish")
            })
            removeItemPromise()
              .then(() => {
                // reloadCustomerScreen()
              })
              .catch((e) => console.log(e))
          }
          setIsConfirmModalVisible(false)
        }}
        onCancel={() => {
          setIsConfirmModalVisible(false)
          setEditItem(null)
        }}
        rightButtonDanger={true}
      />
      <div className="pageTitle">Refund Items</div>
      {/* <ConfigProvider renderEmpty={customizeRenderEmpty}> */}
      <Table
        className="pageDetailTable"
        rowClassName={(record, index) => { return index % 2 > 0 ? "rowStyle1" : "rowStyle2" }}
        //@ts-ignore
        columns={itemColumns.concat(isSignIn ? [
          {
            title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Action</Typography.Text>,
            dataIndex: "code",
            key: "code",
            width: "7%",
            render: (text: any, item: any) =>
              <button
                style={{ border: "none", backgroundColor: "rgba(0, 0, 0, 0.0)" }}
                onClick={() => {
                  dispatch(removeItemFromRefundCart({ refundItemId: item.refundItemId }))
                }}
              >
                <img src={process.env.PUBLIC_URL + "/images/assets/icons/deleteAction-icon.png"} style={{ width: 28, height: 28 }} />
              </button>
            // <Button
            //   danger
            //   type="primary"
            //   size="small"
            //   onClick={() => {
            //     dispatch(removeItemFromRefundCart({ refundItemId: item.refundItemId }))
            //   }}
            //   style={{ marginRight: 10 }}
            // >
            //   <DeleteFilled />
            // </Button>
          }
        ] : [])}
        dataSource={refundItems}
        pagination={false}
        scroll={{
          y: height * 0.66,
          // scrollToFirstRowOnChange: true
        }}
        id="box"
      />
      <ScrollButtons
        onPageDown={onLastPage}
        onPageUp={onFirstPage}
        onRowDown={onPageDown}
        onRowUp={onPageUp}
      />
      {/* </ConfigProvider> */}

    </div>
  )
}

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 500
    // position: "absolute"
    // display: "flex",
    // justifyContent: "flex-end",
    // alignItems: "flex-end"
  },
  boldFont: {
    fontWeight: "bold"
  },
  tableItem: {
    width: 100,
    fontSize: 20
  },
  totalContainer: {
    right: 10,
    position: "absolute"
  },
  total: {
    display: "flex",
    flexDirection: "row"
  },
  totalDescription: {
    fontWeight: "bold",
    marginRight: 20
  },
  button: {
    marginBottom: 20,
    width: 140,
    height: 140
  }

}
