import "../../css/Global.css"
import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Typography, Table, Input, Button, Form, Popconfirm, ConfigProvider } from "antd"
import { DeleteFilled } from "@ant-design/icons"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { CartItem, StyleSheet, Total, Order, CartItemType } from "../../models"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { addItemToCart, updateCartItem, updateTotal, removeItemFromCart, clearCart, showKeyboard, updateAllCartItems } from "../../redux/Checkout/slice"
import { addOrder, addHoldingOrder } from "../../redux/Order/slice"
import { calculateCartTotal, calculateGSTAmount, handleChangeCartItemQuantity } from "../../utils/Global"
import LoginModal from "../Login/LoginModal"
import NumPad from "react-numpad"
import ActionConfirmModal from "../common/ActionConfirmModal"
import { reloadCustomerScreen } from "../../utils/customerscreen-functions"
import AlertModal from "../common/AlertModal"
import { SmileOutlined } from "@ant-design/icons"
import NumpadModal from "../common/NumpadModal"
import { UserState } from "../../redux/User/slice"
import { getProductDisplayName } from "../../utils/getProductDisplayName"
import ScrollButtons from "../common/ScrollButtons"
import VirtualTable from "./VirtualTableForCart"

const { Title } = Typography

interface CheckoutCartProps {
  tableHeight?: number
  tableRows?: number
  scrollableHeight?: number
}

export default function CheckoutCart({ tableHeight, tableRows, scrollableHeight }: CheckoutCartProps) {
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { itemsInCart, total, isKeyboardVisible } = Checkout
  const Order = useSelector((state: RootStateOrAny) => state.Order)
  const { orders, holdingOrders } = Order
  const User: UserState = useSelector((state: RootStateOrAny) => state.User)
  const { isSignIn } = User

  const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState("")

  // Thins Need to set for edit item
  const [isNumpadVisible, setIsNumpadVisible] = useState(false)
  const [editItem, setEditItem] = useState<CartItem | null>(null)
  const [editType, setEditType] = useState<"quantity" | "unitPrice" | "discount" | string>("quantity")
  const [numpadValue, setNumpadValue] = useState("0")
  const [numpadDecimal, setNumpadDecimal] = useState(2)
  const [numpadNegative, setNumpadNegative] = useState(false)

  useEffect(() => {
    // let box = document.getElementById("box")
    let v1 = document.getElementsByClassName("ant-table-body")[0]
    let v2 = document.getElementsByClassName("virtual-grid")[0]
    // console.log("v: ", document.getElementsByClassName("ant-table-body"))
    if (v1) {
      // console.log("v1 scroll height: ", v1.scrollHeight)
      // console.log("v1 scroll top: ", v1.scrollTop)
      v1.scrollTop = v1.scrollHeight
    }
    if (v2) {
      // console.log("v2 scroll height: ", v2.scrollHeight)
      // console.log("v2 scroll top: ", v2.scrollTop)
      v2.scrollTop = v2.scrollHeight
    }
  }, [itemsInCart.length])

  const handleValueChange = (input: string, editItem: CartItem, type: string) => {
    let newCartItems: Array<CartItem> = []
    const numberInput = Number(input)
    switch (type) {
      case "quantity" : case "weight":
        if (numberInput > 9999) {
          setErrorMsg("Quantity must be less than 9999!")
          setIsAlertModalVisible(true)
        } else if (numberInput < 0) {
          const newPrice = numberInput * editItem.unitPrice * (1 - editItem.discountRatio / 100)
          const newFields = {
            quantity: numberInput,
            total: newPrice,
            type: "refund",
            gstAmount: editItem.applyGst ? calculateGSTAmount(newPrice, editItem.gstRatio) : 0
          }
          newCartItems = itemsInCart.map((item: CartItem) =>
            item.itemId === editItem.itemId
              ? { ...item, ...newFields }
              : item
          )
          dispatch(updateCartItem({ id: editItem.itemId, input: newFields }))
          let newTotal = calculateCartTotal(newCartItems)
          dispatch(updateTotal({ input: newTotal }))
        } else {
          const newPrice = numberInput * editItem.unitPrice * (1 - editItem.discountRatio / 100)
          const newCartItem = {
            ...editItem,
            quantity: numberInput,
            total: newPrice,
            gstAmount: editItem.applyGst ? calculateGSTAmount(newPrice, editItem.gstRatio) : 0
          }
          const newCartItems = handleChangeCartItemQuantity(newCartItem, itemsInCart)
          dispatch(updateAllCartItems({ cartItems: newCartItems }))
          let newTotal = calculateCartTotal(newCartItems)
          dispatch(updateTotal({ input: newTotal }))
        }
        break
      case "unitPrice":
        if (numberInput < 0) {
          setErrorMsg("Price must be larger than 0!")
          setIsAlertModalVisible(true)
        } else if (numberInput > 9999) {
          setErrorMsg("Price must be less than $9999!")
          setIsAlertModalVisible(true)
        } else {
          const newPrice = numberInput * editItem.quantity * (1 - editItem.discountRatio / 100)
          const newFields1 = {
            unitPrice: numberInput,
            total: newPrice,
            gstAmount: editItem.applyGst ? calculateGSTAmount(newPrice, editItem.gstRatio) : 0
          }
          newCartItems = itemsInCart.map((item: CartItem) =>
            item.itemId === editItem.itemId
              ? { ...item, ...newFields1 }
              : item
          )
          dispatch(updateCartItem({ id: editItem.itemId, input: newFields1 }))
          let newTotal = calculateCartTotal(newCartItems)
          dispatch(updateTotal({ input: newTotal }))
        }
        break
      case "discount":
        if (numberInput < 0 || numberInput > 100) {
          setErrorMsg("Discount ratio must between 0 and 100!")
          setIsAlertModalVisible(true)
        } else {
          const newPrice = editItem.quantity * editItem.unitPrice * (1 - numberInput / 100)
          const newFields2 = {
            discountRatio: numberInput,
            total: newPrice,
            gstAmount: editItem.applyGst ? calculateGSTAmount(newPrice, editItem.gstRatio) : 0
          }
          newCartItems = itemsInCart.map((item: CartItem) =>
            item.itemId === editItem.itemId
              ? { ...item, ...newFields2 }
              : item
          )
          dispatch(updateCartItem({ id: editItem.itemId, input: newFields2 }))
          let newTotal = calculateCartTotal(newCartItems)
          dispatch(updateTotal({ input: newTotal }))
        }
        break
    }
  }

  const onConfirmValue = (value: number) => {
    handleValueChange(value.toString(), editItem!, editType)
    setIsNumpadVisible(false)
  }

  const columns = [
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>No</Typography.Text>,
      dataIndex: "itemId",
      key: "id",
      width: "5%",
      // width: 50,
      ellipsis: false,
      render: (text: string, record: any, index: number) => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>{String(index + 1).padStart(2, '0')}</Typography.Text>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Description</Typography.Text>,
      // dataIndex: "itemId",
      dataIndex: ["productInfo", "name"],
      key: "productId",
      width: "40%",
      render: (text: any, item: CartItem) => (
        <div>
          {
            item && item.promotionText ?
              <Typography style={{ fontSize: width > 1500 ? 20 : 15, fontWeight: "bold", }}>
                {item.promotionText.split("/").map(str => <div style={{ color: str.indexOf("WAS") > -1 ? "black" : "red" }}>{str}<br /></div>)}
              </Typography>
              :
              null
          }
          {
            item && item.productInfo ?
            <Typography style={{ fontSize: width > 1500 ? 20 : 15 }}>{getProductDisplayName(item.productInfo)}</Typography>
              :
              null
          }
          </div>
      )
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Qty</Typography.Text>,
      dataIndex: "quantity",
      key: "quantity",
      width: "10%",
      render: (quantity: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {
            item.type === "promotion" || item.promotionText && item.promotionText.indexOf("Buy") > -1 ?
              <Typography style={{ fontSize: 20, alignSelf: "center" }}>{quantity}{item.productInfo.isScalable ? <div style={{ color: "#333", fontSize: "14px", textAlign: "right" }}>kg</div> : ""}</Typography>
              :
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}
                onClick={() => {
                  if (item.productInfo.isScalable) {
                    setEditType("weight")
                  } else {
                    setEditType("quantity")
                  }
                  setEditItem(item)
                  setNumpadValue(quantity.toString())
                  setNumpadDecimal(4)
                  setNumpadNegative(true)
                  setIsNumpadVisible(true)
                }}
              >
                <Typography
                  style={{ fontSize: 20, alignSelf: "center", color: "blue", textDecoration: "underline" }}
                >
                  {quantity}{item.productInfo.isScalable ? <div style={{ color: "#333", fontSize: "14px", textAlign: "right" }}>kg</div> : ""}
                </Typography>
              </div>
          }
        </div>

    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Price</Typography.Text>,
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: "15%",
      render: (price: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {
            (item.type === CartItemType.Promotion || item.promotionText) && price > 0 ?
              <Typography style={{ fontSize: 20, alignSelf: "center", color: item.promotionText ? "red" : "black" }}><>${price.toFixed(2)}{item.productInfo.isScalable ? <div style={{ color: "#333", fontSize: "14px", textAlign: "right" }}>/kg</div> : ""}</></Typography>
              :
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}
                onClick={() => {
                  setEditType("unitPrice")
                  setEditItem(item)
                  setNumpadValue(price.toFixed(2))
                  setNumpadDecimal(3)
                  setNumpadNegative(false)
                  setIsNumpadVisible(true)
                }}
              >
                <Typography
                  style={{ fontSize: 20, alignSelf: "center", color: "blue", textDecoration: "underline" }}
                >
                  ${price.toFixed(2)}{item.productInfo.isScalable ? <div style={{ color: "#333", fontSize: "14px", textAlign: "right" }}>/kg</div> : ""}
                </Typography>
              </div>
          }
        </div>
    },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Discount</Typography.Text>,
      dataIndex: "discountRatio",
      key: "discountRatio",
      width: "10%",
      render: (discount: number, item: CartItem) =>
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
          {
            item.allowDiscount ?
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}
                onClick={() => {
                  setEditType("discount")
                  setEditItem(item)
                  setNumpadValue(discount.toFixed(0))
                  setNumpadDecimal(0)
                  setNumpadNegative(false)
                  setIsNumpadVisible(true)
                }}
              >
                <Typography
                  style={{ fontSize: 20, alignSelf: "center", color: "blue", textDecoration: "underline" }}
                >
                  {discount.toFixed(0)}%
                </Typography>
              </div>
              :
              <Typography style={{ fontSize: 18, alignSelf: "center" }}>N/A</Typography>
          }
        </div>
    },
    // {
    //   title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>GST</Typography.Text>,
    //   dataIndex: "gstRatio",
    //   key: "gstRatio",
    //   width: "5%",
    //   render: (gst: number, item: CartItem) =>
    //     <div>
    //       {
    //         item.applyGst ?
    //           <Typography style={{ fontSize: 18 }}>{gst.toFixed(0)}%</Typography>
    //           :
    //           <Typography style={{ fontSize: 18, alignSelf: "center" }}>N/A</Typography>
    //       }
    //     </div>
    // },
    {
      title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Amount</Typography.Text>,
      dataIndex: "total",
      key: "total",
      width: "10%",
      render: (amount: number, item: CartItem) => {
        const isSpecial = (item.originalPrice && ((item.quantity * item.originalPrice) - amount) >= 0.01)
        return (
          <>
            <Typography style={{ fontSize: "18px", textAlign: "right", fontWeight: 400 }}>${amount.toFixed(2)}</Typography>
            <Typography style={{ fontSize: "14px", textAlign: "right", color: "red", fontWeight: 700, lineHeight: "14px" }}>{isSpecial ? `Save $${((item.quantity * (item.originalPrice ? item.originalPrice : 0)) - amount).toFixed(2)}` : null}</Typography>
          </>
        )
      }
    },

  ]

  const onPageUp = () => {
    // setPageUpDown(pageUpDown - 1)
    let v1 = document.getElementsByClassName("virtual-grid")[0]
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (!v1) return
    v1.scrollTop -= height * 0.545
  }

  const onPageDown = () => {
    // setPageUpDown(pageUpDown + 1)
    let v1 = document.getElementsByClassName("virtual-grid")[0]
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (!v1) return
    v1.scrollTop += height * 0.545
  }
  const onRowUp = () => {
    // setPageUpDown(pageUpDown - 1)
    let v1 = document.getElementsByClassName("virtual-grid")[0]
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (!v1) return
    v1.scrollTop -= 70
  }

  const onRowDown = () => {
    // setPageUpDown(pageUpDown + 1)
    let v1 = document.getElementsByClassName("virtual-grid")[0]
    // let v1 = document.getElementsByClassName("ant-table-body")[0]
    if (!v1) return
    v1.scrollTop += 70
  }

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center', height: height * 0.25, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
      <SmileOutlined style={{ fontSize: 20 }} />
      <p>No Items In Cart</p>
    </div>
  );

  return (
    <div>
      {isLoginModalVisible ?
        <LoginModal
          isVisible={isLoginModalVisible}
          setVisibility={(value: boolean) => {
            setIsLoginModalVisible(value)
          }}
        />
        : null
      }

      <AlertModal
        isVisible={isAlertModalVisible}
        description={errorMsg}
        buttonText={"OK"}
        onCancel={() => setIsAlertModalVisible(false)}
        onConfirm={() => setIsAlertModalVisible(false)}
      />
      {isNumpadVisible ?
        <NumpadModal
          title={editType}
          value={numpadValue}
          isVisible={isNumpadVisible}
          decimal={numpadDecimal}
          negative={numpadNegative}
          onConfirm={onConfirmValue}
          onDismiss={() => {
            setIsNumpadVisible(false)
          }}
        />
        : null
      }

      <ActionConfirmModal
        isVisible={isConfirmModalVisible}
        description={"Are you sure to remove this item from cart?"}
        actionText={"Remove"}
        cancelText={"Cancel"}
        onConfirm={() => {
          if (editItem) {
            const removeItemPromise = () => new Promise((resolve, reject) => {
              const newCartItems = itemsInCart.filter((itemInCart: CartItem) => { return editItem.itemId !== itemInCart.itemId })
              dispatch(removeItemFromCart({ item: editItem }))
              // const newTotal = calculateCartTotal(newCartItems)
              // dispatch(updateTotal({ input: newTotal }))
              resolve("finish")
            })
            removeItemPromise()
              .then(() => {
                // reloadCustomerScreen()
              })
              .catch((e) => console.log(e))
          }
          setIsConfirmModalVisible(false)
        }}
        onCancel={() => {
          setIsConfirmModalVisible(false)
          setEditItem(null)
        }}
        rightButtonDanger={true}
      />

      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <VirtualTable
        // <Table
          className="pageDetailTable"
          rowClassName={(record, index) => { return record.itemId === "sp" || record.type === CartItemType.Promotion ? "yellow" : index % 2 > 0 ? "rowStyle1" : "rowStyle2" }}
          size="small"
          columns={columns.concat(isSignIn ? [
            {
              title: () => <Typography.Text ellipsis style={{ fontSize: width > 1500 ? 20 : 12 }}>Action</Typography.Text>,
              dataIndex: "code",
              key: "action",
              width: "10%",
              render: (text: any, item: CartItem) =>
                // item.type === "promotion" && item.specialInfo?.Type === 'BuyNGetOneFree' ?
                //   <></>
                //   :
                <button
                  style={{ 
                    border: "none", 
                    // backgroundColor: "red",
                    width: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.0)"
                }}
                  onClick={() => {
                    setEditItem(item)
                    setIsConfirmModalVisible(true)
                  }}
                >
                  <img src={process.env.PUBLIC_URL + "/images/assets/icons/deleteAction-icon.png"} style={{ width: 28, height: 28 }} />
                </button>
            }
          ] : [])}
          dataSource={itemsInCart}
          pagination={false}
          scroll={{
            // y: scrollableHeight ? scrollableHeight : height * 0.4,
            y: height * 0.545,
            x: "max-content"
          }}
          id="box"
        />
        <ScrollButtons
          onPageDown={onPageDown}
          onPageUp={onPageUp}
          onRowDown={onRowDown}
          onRowUp={onRowUp}
        />
      </ConfigProvider>

    </div>
  )
}

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginLeft: 500
    // position: "absolute"
    // display: "flex",
    // justifyContent: "flex-end",
    // alignItems: "flex-end"
  },
  boldFont: {
    fontWeight: "bold"
  },
  tableItem: {
    width: 100,
    fontSize: 20
  },
  totalContainer: {
    right: 10,
    position: "absolute"
  },
  total: {
    display: "flex",
    flexDirection: "row"
  },
  totalDescription: {
    fontWeight: "bold",
    marginRight: 20
  },
  button: {
    marginBottom: 20,
    width: 140,
    height: 140
  }

}
