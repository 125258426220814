import react, { useEffect, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { CashDeclaration } from "../../models"
import { Button, Form, InputNumber, PageHeader, Row, Col, Typography, DatePicker, Input, notification } from "antd"
import moment from "moment";
import NumpadModal from "../common/NumpadModal"
import { PostCashDeclaration } from "../../api/cashDeclaration"
import { getConfiguration } from "../../utils/configurate"
const PosConfig = getConfiguration("CashDeclaration")
const { machineID: MachineID } = PosConfig
type Props = {
    declaration: CashDeclaration | null
    onCancel: () => void
    reset: () => void
}

export default function CashDeclarationDetail({ declaration, onCancel, reset }: Props) {
    const User = useSelector((state: RootStateOrAny) => state.User)
    const { uid } = User

    const dispatch = useDispatch()
    const [curTotal, setCurTotal] = useState<number>(0)
    const [form] = Form.useForm()
    // Thins Need to set for edit item
    const [isNumpadVisible, setIsNumpadVisible] = useState(false)
    const [editType, setEditType] = useState<string>("")
    const [numpadValue, setNumpadValue] = useState("0")
    const [numpadDecimal, setNumpadDecimal] = useState(1)
    const [numpadNegative, setNumpadNegative] = useState(false)
    const initValues: any =
    {
        StartDateTime: moment(),
        EndDateTime: moment(),
        HundredDollar: 0,
        FiftyDollar: 0,
        TwentyDollar: 0,
        TenDollar: 0,
        FiveDollar: 0,
        TwoDollar: 0,
        OneDollar: 0,
        FiftyCent: 0,
        TwentyCent: 0,
        TenCent: 0,
        FiveCent: 0,
        OpFirebaseUid: uid,
        MachineID
    }

    const onConfirmValue = (value: number) => {
        form.setFieldsValue({ [editType]: value })
        setIsNumpadVisible(false)
        onValuesChange()

    }
    const onFinish = async (values: any) => {
        const mergeValues = {
            ...values,
            StartDateTime: moment(values.StartDateTime).format("YYYY-MM-DD hh:mm:ss"),
            EndDateTime: moment(values.EndDateTime).format("YYYY-MM-DD hh:mm:ss"),
            CreateDate: moment().format("YYYY-MM-DD hh:mm:ss"),
        }
        // console.log('准备提交的cashdeclaration数据', mergeValues)
        if (!values.ID) {
            const res: any = await PostCashDeclaration( mergeValues)
            // console.log('查看cashdeclaraton返回值。。。', res)
            reset && reset()
        } else
            notification.error({
                message: "Cash Declaration History Can Not Be Updated"
            })
    }
    const onValuesChange = async () => {
        const values = form.getFieldsValue()
        console.log('onValuesChange', values)
        setCurTotal(
            (values.HundredDollar * 10000 +
                values.FiftyDollar * 5000 +
                values.TwentyDollar * 2000 +
                values.TenDollar * 1000 +
                values.FiveDollar * 500 +
                values.TwoDollar * 200 +
                values.OneDollar * 100 +
                values.FiftyCent * 50 +
                values.TwentyCent * 20 +
                values.TenCent * 10 +
                values.FiveCent * 5) / 100
        )
    }

    useEffect(() => {
        if (declaration) {
            form.setFieldsValue({
                ...declaration,
                "StartDateTime": moment(declaration.StartDateTime),
                "EndDateTime": moment(declaration.EndDateTime),

            })
        }
    }, [declaration])

    return (
        <PageHeader
            className="detailsPageHeader"
            ghost={false}
            backIcon={false}
            title={
                <div style={{ display: "flex", flexDirection: "row", marginTop: "-30px" }}>
                    <div className="detailNo">
                        {
                            declaration ?
                                <>Edit Cash Declaration: <br /> {declaration.ID}</>
                                :
                                `New Cash Declaration`
                        }
                    </div>
                </div>
            }
            extra={[
                <div style={{ display: "flex" }}>
                    <button key="cancel" className="scanToRefundButton" style={{width: "60px"}}
                    onClick={() => {
                        //取消修改，恢复原始数据
                        onCancel && onCancel()
                    }}
                >
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/cancel-icon.png"} className="pageIcon" />
                    Cancel
                </button>
                <button key="edit" className="printDetailsButton"
                    onClick={() => {
                        //save cashdeclaration
                        onFinish(form.getFieldsValue())

                    }}
                >
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/cardPayMethodChecked-icon.png"} className="pageIcon" />
                    Save
                </button>
                
                </div>
            ]}
        >
            {isNumpadVisible ?
                <NumpadModal
                    title={editType}
                    value={numpadValue}
                    isVisible={isNumpadVisible}
                    decimal={numpadDecimal}
                    negative={numpadNegative}
                    onConfirm={onConfirmValue}
                    onDismiss={() => {
                        setIsNumpadVisible(false)
                    }}
                />
                : null
            }
            <Form
                name="CashDeclarationDetailForm"
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                initialValues={declaration ? declaration : initValues}
            >
                <Row gutter={24}>
                    <Col span={12} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                        <Form.Item
                            name="StartDateTime"
                            label="Start DateTime"
                        >
                            <DatePicker showTime />
                        </Form.Item>
                        <div
                            onClick={() => {
                                setEditType("HundredDollar")
                                setNumpadValue(form.getFieldValue("HundredDollar").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="HundredDollar"
                                label="100 Dollar"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <div
                            onClick={() => {
                                setEditType("TwentyDollar")
                                setNumpadValue(form.getFieldValue("TwentyDollar").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="TwentyDollar"
                                label="20 Dollar"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <div
                            onClick={() => {
                                setEditType("FiveDollar")
                                setNumpadValue(form.getFieldValue("FiveDollar").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="FiveDollar"
                                label="5 Dollar"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <div
                            onClick={() => {
                                setEditType("OneDollar")
                                setNumpadValue(form.getFieldValue("OneDollar").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="OneDollar"
                                label="1 Dollar"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <div
                            onClick={() => {
                                setEditType("TwentyCent")
                                setNumpadValue(form.getFieldValue("TwentyCent").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="TwentyCent"
                                label="20 Cent"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <div
                            onClick={() => {
                                setEditType("FiveCent")
                                setNumpadValue(form.getFieldValue("FiveCent").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="FiveCent"
                                label="5 Cent"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>

                    </Col>
                    <Col span={12} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                        <Form.Item
                            name="EndDateTime"
                            label="End DateTime"
                        >
                            <DatePicker showTime />
                        </Form.Item>
                        <div
                            onClick={() => {
                                setEditType("FiftyDollar")
                                setNumpadValue(form.getFieldValue("FiftyDollar").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="FiftyDollar"
                                label="50 Dollar"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <div
                            onClick={() => {
                                setEditType("TenDollar")
                                setNumpadValue(form.getFieldValue("TenDollar").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="TenDollar"
                                label="10 Dollar"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <div
                            onClick={() => {
                                setEditType("TwoDollar")
                                setNumpadValue(form.getFieldValue("TwoDollar").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="TwoDollar"
                                label="2 Dollar"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <div
                            onClick={() => {
                                setEditType("FiftyCent")
                                setNumpadValue(form.getFieldValue("FiftyCent").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="FiftyCent"
                                label="50 Cent"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <div
                            onClick={() => {
                                setEditType("TenCent")
                                setNumpadValue(form.getFieldValue("TenCent").toFixed(0))
                                setNumpadDecimal(1)
                                setNumpadNegative(true)
                                setIsNumpadVisible(true)
                            }}
                        >
                            <Form.Item
                                name="TenCent"
                                label="10 Cent"
                            >
                                <InputNumber min={0} style={{backgroundColor: "#F3F3F3", width: "100px", border: "none", borderRadius: "4px"}} />
                            </Form.Item>
                        </div>
                        <Typography style={{ fontWeight: 700 }}>
                            Total: {curTotal}
                        </Typography>
                    </Col>

                </Row>
                <Form.Item
                    name="OpFirebaseUid"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="ID"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="MachineID"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
            </Form>

        </PageHeader>
    )

}