import React, { useRef, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, List, Radio, Input, Space, Typography, Button, Divider, notification, Image, message } from "antd"
import { CreditCardFilled, DollarCircleFilled } from '@ant-design/icons'
import { useNavigate } from "react-router-dom"
import useViewport from "../../../hooks/useViewport"
import { primary } from "../../../constants/Colors"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import {
  setCurrentInvoice,
  setPaymentTotal,
  setShowReceiptModal,
  setPaymentType,
  setSplitPaymentMethod1,
  setSplitPaymentMethod2,
  setSplitPaymentAmount1,
  setSplitPaymentAmount2,
  setPaymentTotal1,
  setPaymentTotal2,
  setPaymentStep
} from "../../../redux/Payment/slice"
import NumPad from "react-numpad"
import AlertModal from "../../common/AlertModal"
import { syncModalToCustomerScreen } from "../../../utils/Global"

const logo_royalpay = require("../../../assets/png/logo_royalpay.png")

interface Props {
  sectionHeightFactor?: number
  sectionWidthFactor?: number
  isVisible: boolean
  setVisibility: (value: boolean) => void
  handleCashPayment: (amount: number, type: string) => void
  handleCardPayment: (amount: number, type: string) => void
  handleRoyalPay: (amount: number, type: string) => void
  handleSplitPayment: () => void
  setPaymentMethod: (value: "cash" | "card" | "royalpay") => void
}

interface PaymentMethodProps {
  paymentNo: number
}

export default function CheckoutPaymentModal({ sectionHeightFactor, sectionWidthFactor, isVisible, setVisibility, handleCardPayment, handleCashPayment, handleRoyalPay, handleSplitPayment, setPaymentMethod }: Props) {
  const { height, width } = useViewport()
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { paymentTotal, currentInvoice, showReceiptModal, paymentType, paymentMethod1, paymentMethod2, paymentAmount1, paymentAmount2 } = Payment
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total, itemsInCart } = Checkout
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer } = Customer
  const curSplit = useRef("1")

  console.log("先看这里的payment。。", Payment)

  // const [selectedMethod, setSelectedMethod] = useState<"cash" | "card" | "royalpay">("cash")
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const PaymentTypeSelect = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        <div className="normalLabel">Payment Type<br /> {paymentType === "oneOff" ? "One-Off" : "Split"}</div>
        {
          paymentType === "oneOff" ?
            <button
              className="staffOperateButton"
              style={{ backgroundColor: "#2C6EF1", flexDirection: "row", width: "220px", height: "55px", fontSize: "18px" }}
              onClick={
                () => {
                  dispatch(setPaymentType({ paymentType: "split" }))
                  dispatch(setPaymentStep({ paymentStep: "split1" }))//这里原先没有配置，可能导致分批刷卡时只刷了一次
                }}>
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/switchToOtherPayment-icon.png"} className="pageIcon" />
              Switch to <br />Split Payment
            </button>
            :
            <button
              className="staffOperateButton"
              style={{ backgroundColor: "#2C6EF1", flexDirection: "row", width: "220px", height: "55px", fontSize: "18px" }}
              onClick={() => {
                dispatch(setPaymentType({ paymentType: "oneOff" }))
              }}>
              <img src={process.env.PUBLIC_URL + "/images/assets/icons/switchToOtherPayment-icon.png"} className="pageIcon" />
              Switch to <br />One-Off Payment
            </button>
        }
      </div>
    )
  }

  const PaymentMethodSelect = ({ paymentNo }: PaymentMethodProps) => {
    return (
      <div style={{ marginTop: 18, display: "flex", flexDirection: "column" }}>
        <div className="normalLabel">Method: </div>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: 10, justifyContent: "space-around" }}>
          <div
            onClick={() => {
              if (paymentNo === 1) {
                dispatch(setSplitPaymentMethod1({ paymentMethod1: "cash" }))
              } else {
                dispatch(setSplitPaymentMethod2({ paymentMethod2: "cash" }))
              }
            }}
            style={{
              height: "80px", display: "flex", flexDirection: "column", width: "30%",
              backgroundColor: (paymentNo === 1 && paymentMethod1 === "cash" || paymentNo === 2 && paymentMethod2 === "cash") ? "#2C6EF1" : "#fff",
              borderRadius: "6px", justifyContent: "center", alignItems: "center"
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/cashPayMethod-icon.png"} style={{ width: 40, height: 40 }} />
            <div className="normalLabel"
              style={{ fontSize: 20, color: (paymentNo === 1 && paymentMethod1 === "cash" || paymentNo === 2 && paymentMethod2 === "cash") ? "#fff" : "#333333" }}
            >Cash</div>
          </div>

          <div
            onClick={() => {
              if (paymentNo === 1) {
                dispatch(setSplitPaymentMethod1({ paymentMethod1: "card" }))
              } else {
                dispatch(setSplitPaymentMethod2({ paymentMethod2: "card" }))
              }
            }}
            style={{
              height: "80px", display: "flex", flexDirection: "column", width: "30%",
              backgroundColor: (paymentNo === 1 && paymentMethod1 === "card" || paymentNo === 2 && paymentMethod2 === "card") ? "#2C6EF1" : "#fff",
              borderRadius: "6px", justifyContent: "center", alignItems: "center"
            }}
          >
            <img src={(paymentNo === 1 && paymentMethod1 === "card" || paymentNo === 2 && paymentMethod2 === "card") ? process.env.PUBLIC_URL + "/images/assets/icons/cardPayMethodChecked-icon.png" : process.env.PUBLIC_URL + "/images/assets/icons/cardPayMethod-icon.png"} style={{ width: 40, height: 40 }} />
            <div className="normalLabel"
              style={{ fontSize: 20, color: (paymentNo === 1 && paymentMethod1 === "card" || paymentNo === 2 && paymentMethod2 === "card") ? "#fff" : "#333333" }}
            >Card</div>
          </div>
          {/* <Divider type="vertical" style={{ backgroundColor: primary, fontSize: 80, alignSelf: "center", marginLeft: 60, marginRight: 60 }} /> */}
          <div
            onClick={() => {
              if (paymentNo === 1) {
                dispatch(setSplitPaymentMethod1({ paymentMethod1: "royalpay" }))
              } else {
                dispatch(setSplitPaymentMethod2({ paymentMethod2: "royalpay" }))
              }
            }}
            style={{
              height: "80px", display: "flex", flexDirection: "column", width: "30%",
              backgroundColor: (paymentNo === 1 && paymentMethod1 === "royalpay" || paymentNo === 2 && paymentMethod2 === "royalpay") ? "#2C6EF1" : "#fff",
              borderRadius: "6px", justifyContent: "center", alignItems: "center"
            }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/royalPayMethod-icon.png"} style={{ width: 40, height: 40 }} />
            <div className="normalLabel"
              style={{ fontSize: 20, color: (paymentNo === 1 && paymentMethod1 === "royalpay" || paymentNo === 2 && paymentMethod2 === "royalpay") ? "#fff" : "#333333" }}
            >RoyalPay</div>
          </div>
        </div>
        {/* <Radio.Group
          onChange={(e: any) => {
            if (paymentNo === 1) {
              dispatch(setSplitPaymentMethod1({ paymentMethod1: e.target.value }))
            } else {
              dispatch(setSplitPaymentMethod2({ paymentMethod2: e.target.value }))
            }
          }}
          value={paymentNo === 1 ? paymentMethod1 : paymentMethod2}
        >
          <Radio value={"cash"} style={{ fontSize: 18, marginRight: 80 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography >Cash</Typography>
              <DollarCircleFilled style={{ fontSize: 20, color: primary, marginLeft: 10, alignSelf: "center" }} />
            </div>
          </Radio>
          <Radio value={"card"} style={{ fontSize: 18, marginRight: 80 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography >Card</Typography>
              <CreditCardFilled style={{ fontSize: 20, color: primary, marginLeft: 10, alignSelf: "center" }} />
            </div>
          </Radio>
          <Radio value={"royalpay"} style={{ fontSize: 18 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ marginRight: 10 }}>RoyalPay</Typography>
              <Image
                src={logo_royalpay}
                width={40}
              />
            </div>
          </Radio>
        </Radio.Group> */}
      </div>
    )
  }

  const handleAmountPaidChange = (mode: "input" | "reset", value: number) => {
    // let currentPaymentTotal = EmptyPaymentTotal
    // if (paymentType === "oneOff") {
    //   currentPaymentTotal = paymentTotal
    // } else if (paymentStep === "split1") {
    //   currentPaymentTotal = paymentTotal1
    // } else if (paymentStep === "split2") {
    //   currentPaymentTotal = paymentTotal2
    // }
    if (mode === "reset") {
      const restAmount = Number((total.totalAmount).toFixed(2))
      if (curSplit.current === "1") {
        // message.info("now curSplit is 1")
        dispatch(setSplitPaymentAmount1({ paymentAmount1: "0" }))
        dispatch(setSplitPaymentAmount2({ paymentAmount2: restAmount }))
      } else {
        // message.info("now curSplit is 2")
        dispatch(setSplitPaymentAmount2({ paymentAmount2: "0" }))
        dispatch(setSplitPaymentAmount1({ paymentAmount1: restAmount }))
      }
    }
  }

  return (
    <Modal
      transitionName="none"
      maskTransitionName="none"
      className="modalPage"
      title={<div className="modalTitle">Checkout Payment<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
      visible={isVisible}
      footer={null}
      onCancel={(e) => {
        setVisibility(false)
        dispatch(setPaymentType({ paymentType: "oneOff" }))
        syncModalToCustomerScreen("closePaymentModal")
        // //Modal同步到客户屏
        // //@ts-ignore
        // if (window?.electronAPI) {
        //   //@ts-ignore
        //   window.electronAPI.modalVisibleSync("closeCustomerModal", (response) => {
        //     console.log('关闭modal同步显示在客户屏===成功: ', response);
        //   })
        // }
      }}
      width={paymentType === "oneOff" ? 600 : 924}
      style={{
        zIndex: 999
      }}
    >
      <AlertModal
        isVisible={isAlertModalVisible}
        description={errorMsg}
        buttonText={"OK"}
        onCancel={() => setIsAlertModalVisible(false)}
        onConfirm={() => setIsAlertModalVisible(false)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundColor: "white",
          flexDirection: "column",
          zIndex: 100,
          padding: 18,
          marginTop: "-40px"
          // height: sectionHeightFactor ? height * sectionHeightFactor : height * 0.45
        }}
      >

        {
          paymentType === "oneOff"
            ?
            <>
              <PaymentTypeSelect />
              <div style={{ width: "100%", marginTop: "10px", padding: "10px", borderRadius: "8px", backgroundColor: "#EEEEEE", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start", alignSelf: "center", zIndex: 100 }}>
                <div className="normalLabel" style={{ color: "#2C6EF1", display: "flex", alignItems: "center" }}>
                  <div style={{ width: "4px", height: "15px", marginRight: "5px", backgroundColor: "#2C6EF1" }}></div>Please Select Payment Method
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: 20, justifyContent: "space-around" }}>
                  <div
                    onClick={() => {
                      setPaymentMethod("cash")
                      // console.log("cash")
                      handleCashPayment(total.totalAmount, "oneOff")
                    }}
                    style={{ height: "100px", display: "flex", flexDirection: "column", width: "30%", backgroundColor: "#fff", borderRadius: "6px", justifyContent: "center", alignItems: "center" }}
                  >
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/cashPayMethod-icon.png"} style={{ width: 50, height: 50 }} />
                    <div className="normalLabel" style={{ fontSize: 20 }}>Cash</div>
                  </div>

                  <div
                    onClick={async () => {
                      setPaymentMethod("card")
                      handleCardPayment(total.totalAmount, "oneOff")
                    }}
                    style={{ height: "100px", display: "flex", flexDirection: "column", width: "30%", backgroundColor: "#fff", borderRadius: "6px", justifyContent: "center", alignItems: "center" }}
                  >
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/cardPayMethod-icon.png"} style={{ width: 50, height: 50 }} />
                    <div className="normalLabel" style={{ fontSize: 20 }}>Card</div>
                  </div>
                  {/* <Divider type="vertical" style={{ backgroundColor: primary, fontSize: 80, alignSelf: "center", marginLeft: 60, marginRight: 60 }} /> */}
                  <div
                    onClick={() => {
                      console.log("设置当前付款方式为royalpay")
                      setPaymentMethod("royalpay")
                      handleRoyalPay(total.totalAmount, "oneOff")
                    }}
                    style={{ height: "100px", display: "flex", flexDirection: "column", width: "30%", backgroundColor: "#fff", borderRadius: "6px", justifyContent: "center", alignItems: "center" }}
                  >
                    <img src={process.env.PUBLIC_URL + "/images/assets/icons/royalPayMethod-icon.png"} style={{ width: 50, height: 50 }} />
                    <div className="normalLabel" style={{ fontSize: 20 }}>RoyalPay</div>
                  </div>
                </div>
              </div>
            </>
            :
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", width: "100%", marginLeft: "-25px" }}>
              <div style={{ alignSelf: "center", display: "flex", justifyContent: "center", flexDirection: "column", width: "480px" }}>
                <PaymentTypeSelect />
                <div style={{ backgroundColor: "#EEEEEE", marginTop: 10, padding: 10, borderRadius: "8px" }}>
                  <div className="normalLabel" style={{ color: "#2C6EF1", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "4px", height: "15px", marginRight: "5px", backgroundColor: "#2C6EF1" }}></div>Split Payment 1:
                  </div>
                  {/* <Typography style={{ fontWeight: "bold", fontSize: 18, marginBottom: 18 }}>Split Payment 1: </Typography> */}
                  <PaymentMethodSelect paymentNo={1} />
                  <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                    <div className="normalLabel">Amount: </div>
                    {/* <NumPad.Number
                      onChange={(value: string) => {
                        const amount = Number(value)
                        if (amount > total.totalAmount) {
                          setErrorMsg("The Number Entered Must Not Exceed Total Amount!")
                          setIsAlertModalVisible(true)
                        } else {
                          const restAmount = Number((total.totalAmount - amount).toFixed(2))
                          dispatch(setSplitPaymentAmount1({ paymentAmount1: amount }))
                          dispatch(setSplitPaymentAmount2({ paymentAmount2: restAmount }))
                        }
                      }}
                      value={paymentAmount1}
                      decimal={2}
                      negative={false}
                    > */}
                    <Input
                      style={{ width: 180, fontSize: 20, fontWeight: "bold", color: "#000" }}
                      value={paymentAmount1}
                      onFocus={() => {
                        curSplit.current = "1"
                      }}
                    />
                    {/* </NumPad.Number> */}
                  </div>
                </div>

                <div style={{ backgroundColor: "#EEEEEE", marginTop: 10, padding: 10, borderRadius: "8px" }}>
                  <div className="normalLabel" style={{ color: "#2C6EF1", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "4px", height: "15px", marginRight: "5px", backgroundColor: "#2C6EF1" }}></div>Split Payment 2:
                  </div>
                  <PaymentMethodSelect paymentNo={2} />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="normalLabel">Amount: </div>
                    {/* <NumPad.Number
                      onChange={(value: string) => {
                        const amount = Number(value)
                        if (amount > total.totalAmount) {
                          setErrorMsg("The Number Entered Must Not Exceed Total Amount!")
                          setIsAlertModalVisible(true)
                        } else {
                          const restAmount = Number((total.totalAmount - amount).toFixed(2))
                          dispatch(setSplitPaymentAmount1({ paymentAmount1: restAmount }))
                          dispatch(setSplitPaymentAmount2({ paymentAmount2: amount }))
                        }
                      }}
                      value={paymentAmount2}
                      decimal={2}
                      negative={false}
                    > */}
                    <Input
                      style={{ width: 180, fontSize: 20, fontWeight: "bold", color: "#000" }}
                      value={paymentAmount2}
                      // onFocus={() => {
                      //   curSplit.current = "2"
                      // }}
                      readOnly={true}
                      disabled={true}
                    />
                    {/* </NumPad.Number> */}
                  </div>
                </div>
                <Button
                  type="primary"
                  onClick={() => {
                    handleSplitPayment()
                  }}
                  style={{ backgroundColor: "#2C6EF1", borderRadius: "6px", fontSize: 20, marginTop: 20, width: 400, height: 50, alignSelf: "center" }}
                >
                  Proceed
                </Button>
              </div>
              <div style={{ alignSelf: "center", display: "flex", justifyContent: "center", flexDirection: "column", width: "350px" }}>
                <div style={{ display: "flex", flexDirection: "column", width: 400, justifyContent: "center" }}>
                  <NumPad.Number
                    onChange={(value: string) => {
                      // message.info(`${value} and ${JSON.stringify(curSplit.current)}`)
                      const amount = Number(value)
                      if (amount > total.totalAmount) {
                        setErrorMsg("The Number Entered Must Not Exceed Total Amount!")
                        setIsAlertModalVisible(true)
                      } else {
                        const restAmount = Number((total.totalAmount - amount).toFixed(2))
                        dispatch(setSplitPaymentAmount1({ paymentAmount1: amount }))
                        dispatch(setSplitPaymentAmount2({ paymentAmount2: restAmount }))
                      }
                    }}
                    value={paymentAmount1}
                    decimal={2}
                    negative={false}
                    inline={true}
                    sync={true}
                  />
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", marginTop: 20 }}>
                    <button
                      onClick={() => {
                        handleAmountPaidChange("reset", 0)
                      }}
                      style={{
                        fontSize: 25,
                        borderRadius: 5,
                        backgroundColor: "#F8E0E0",
                        border: "none", color: "#E63737", fontWeight: "normal", width: "100%", height: "50px"
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
        }

      </div>
    </Modal>
  )
}