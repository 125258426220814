import React, { useState } from "react"
import { Button, Tabs, Typography, Layout, Menu, Form, Input, Checkbox, Divider } from "antd"
import useViewport from "../hooks/useViewport"
import { Link, useNavigate } from "react-router-dom"
import PairPOSTab from "../components/Setting/PairPOSTab"
import TransactionTab from "../components/Setting/TransactionTab"
import PrinterTab from "../components/Setting/PrinterTab"
import MachineIDTab from "../components/Setting/MachineIDTab"
import ScannerTab from "../components/Setting/ScannerTab"

const { TabPane } = Tabs
const { Title } = Typography
const { Header, Content, Sider } = Layout

export default function SettingPage() {
  const { width, height } = useViewport()
  const navigate = useNavigate()
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>(["4"])

  const onFinish = (values: any) => {
    console.log('Success:', values)
    // dispatch(staffSignIn({ staffCode: values.username, password: values.password }))
    // setVisibility(false)
    // navigate("/checkout")
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className="doubleColumnContainer" style={{width, height}}>
      <div className="doubleColumnTitle">
        <Link to="/checkout">
          <button
            className="unCheckedMenu"
            style={{ backgroundColor: "#DCE3EC" }}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/return-icon.png"} className="pageIcon" />
            Return
          </button>
        </Link>
      </div>
      <div className="double01">
        <div
          style={{ height: "100%", backgroundColor: "#fff", borderRight: 0, borderRadius: "0 10px 10px 0", display: "flex", flexDirection: "column" }}
        >
          <button
            className={selectedKeys.includes("0") ? "checkedMenu" : "unCheckedMenu"}
            key="0"
            onClick={() => setSelectedKeys(["0"])}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/setting-icon.png"} className="pageIcon" />
            Scanner Setting
          </button>
          <button
            className={selectedKeys.includes("1") ? "checkedMenu" : "unCheckedMenu"}
            key="1"
          // onClick={() => setSelectedKeys(["1"])}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/pairPos-icon.png"} className="pageIcon" />
            Pair POS Terminal
          </button>
          <button
            className={selectedKeys.includes("2") ? "checkedMenu" : "unCheckedMenu"}
            key="2"
          // onClick={() => setSelectedKeys(["2"])}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/transaction-icon.png"} className="pageIcon" />
            Transaction
          </button>
          <button
            className={selectedKeys.includes("3") ? "checkedMenu" : "unCheckedMenu"}
            key="3"
          onClick={() => setSelectedKeys(["3"])}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/testPrinter-icon.png"} className="pageIcon" />
            Printer Setting
          </button>
          <button
            className={selectedKeys.includes("4") ? "checkedMenu" : "unCheckedMenu"}
            key="4"
            onClick={() => setSelectedKeys(["4"])}
          >
            <img src={process.env.PUBLIC_URL + "/images/assets/icons/machineID-icon.png"} className="pageIcon" />
            Machine ID
          </button>

        </div>
      </div>
      <div className="double02">
        <div style={{ backgroundColor: "#fff", borderRadius: "10px", display: "flex", flexDirection: "column",height: "100%", justifyContent:"center" }}>
          <div className="modalTitle">
            {
              selectedKeys.includes("1") && "Pair POS Terminal"
              ||
              selectedKeys.includes("2") && "Transaction"
              ||
              selectedKeys.includes("3") && "Printer Setting"
              ||
              selectedKeys.includes("4") && "Machine ID"
              ||
              selectedKeys.includes("0") && "Scanner Setting"
              ||
              "Settings"
            }
            <div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div>
          </div>
          {
            selectedKeys.includes("1") && <PairPOSTab />
            ||
            selectedKeys.includes("2") && <TransactionTab />
            ||
            selectedKeys.includes("3") && <PrinterTab />
            ||
            selectedKeys.includes("4") && <MachineIDTab />
            ||
            selectedKeys.includes("0") && <ScannerTab />
            ||
            null
          }
        </div>

        {/* <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            display: "flex",
            justifyContent: "flex-start"
          }}
        >
          
        </Content> */}
      </div>
      <div className="doubleColumnBottom">
        AiBuild © 2022
      </div>
    </div>

  )
}
