import { InvoiceHead, InvoiceItem, Order, CartItem } from "../models"
import { ExampleInvoiceInDB } from "../constants/data"
import API from "../constants/API"
// import API, { pageSize, largePageSize } from "../constants/API"
import { generateRequestUrl } from "../utils/Global" 
import { notification } from "antd"
import axios from "axios"

// const invoiceUrl = "http://3.25.211.253:4000/api/v1/invoices"
const pageSize = 11//显示invoicesVirtualTable的一页
// Add a function to retry request
function retry(maxRetries: number, fn: () => Promise<any>): Promise<any> {
  return fn().catch(function(err: Error) {
    console.log('error and retry', err)
    if (maxRetries <= 0) {
      throw err;
    }
    return retry(maxRetries - 1, fn); 
  });
}

export const postInvoice = async (newInvoice: InvoiceHead) => {
  
  try {
    const url = generateRequestUrl(API.postInvoice)
    // const config = {}
    // console.log("send request")

    try {
      const response = await retry(3, () => axios.post(url, newInvoice))
      return response.data
    } catch (error) {
      console.error("creating invoice error: ", error)
      notification.error({
        message: "Something went wrong when creating order!"
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export const listInvoices = async () => {
  try {
    const url = generateRequestUrl(API.getInvoices)
    const config = {
      params: {
        "pageSize": pageSize,
        "page": 1
      }
    }
    const response = await axios.get(url, config)
    return {
      ...response.data, 
    }
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const getInvoiceByInvoiceNo = async (invoiceNo: string) => {
  try {
    const url = generateRequestUrl(`${API.getInvoices}/${invoiceNo}`)
    //const config = {}
    const response = await axios.get(url)
    return response.data
  } catch (e) {
    console.log('getInvoiceByInvoiceNo ERROR::', e)
    notification.error({
      message: `Invoice ${invoiceNo} is not exist.`
    })
    return null
  }
}


export const listNoneRefundInvoices = async (searchFilters?:any) => {
  try {
    console.log("list none refund invoice............>", searchFilters)
    const url = generateRequestUrl(API.queryInvoiceFilter)
    const config = {
      params: {
        "pageSize": pageSize,
        "page": 1,
        "isRefund": false,
        ...searchFilters
      }
    }
    const response = await axios.get(url, config)
    console.log("request.....?", config, "list none refund invoice............result>", response)
    return {
      ...response.data,
      pagination: {
        current: config.params.page ? config.params.page : 1 ,
        pageSize: pageSize,
        pageTotal: Math.ceil(response.data.total / pageSize),
      },
    }
    // return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const searchInvoicesByCustomerAndItem = async (customerCode: string, itemDescription: string) => {
  try {
    const url = generateRequestUrl(API.queryInvoiceFilter)
    const config = {
      params: {
        "pageSize": pageSize,
        "page": 1,
        "isRefund": false,
        "CustomerCode": customerCode,
        "ProductDescription": itemDescription
      }
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}
