import API, { pageSize, largePageSize, smallPageSize } from "../constants/API"
import { generateRequestUrl } from "../utils/Global" 
import { notification } from "antd"
import axios from "axios"

export const listProducts = async () => {
  try {
    const url = generateRequestUrl(API.queryProductFilter)
    // const url = generateRequestUrl(API.queryProductList)
    const config = {
      params: {
        "pageSize": pageSize,
        "page": 1,
        "SortBy": 'KoreanName Description'
      }
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const listCheckoutProductsByPage = async (page: number) => {
  try {
    const url = generateRequestUrl(API.queryProductFilter)
    const config = {
      params: {
        "pageSize": smallPageSize,
        "page": page,
        "SortBy": 'KoreanName Description',
      }
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const getProductByProductCode = async (productCode: string) => {
  try {
    const url = generateRequestUrl(API.getProduct)
    const config = {
      params: {
        // "pageSize": pageSize,
        "ProductCode": productCode
      }
    }
    const response = await axios.get(url + productCode, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const getProductByBarCode = async (barCode: string) => {
  try {
    const url = generateRequestUrl(API.queryProductFilter)
    const config = {
      params: {
        "BarCode": barCode,
        "joinData": ['SpecialPrice'],
        "SortBy": 'KoreanName Description',
        // "pageSize": pageSize,
        // "ProductCode": productCode
      }
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const searchProductsByName = async (name: string) => {
  try {
    const url = generateRequestUrl(API.searchProduct)
    const config = {
      params: {
        "pageSize": pageSize,
        "Description": name,
        "joinData": ['SpecialPrice'],
        "SortBy": 'KoreanName Description',
      }
    }
    const response = await axios.get(url, config)
    // console.log("product: ", response)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const searchProductsByDescriptionByPage = async (name: string, page: number) => {
  try {
    const url = generateRequestUrl(API.searchProduct)
    const config = {
      params: {
        "pageSize": smallPageSize,
        "page": page,
        "Any": name,
        "joinData": ['SpecialPrice'],
        "SortBy": 'KoreanName Description',
        "ignoreShowOnTouchPanel": true,
      }
    }
    const response = await axios.get(url, config)
    // console.log("product: ", response)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const listProductsByCategory = async (cateid: string) => {
  try {
    const url = generateRequestUrl(API.searchProduct)
    const config = {
      params: {
        "pageSize": pageSize,
        "CateID": Number(cateid),
        "joinData": ['SpecialPrice'],
        "SortBy": 'KoreanName Description',
      }
    }
    const response = await axios.get(url, config)
    // console.log("product: ", response.data)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const listProductsByCategoryByPage = async (cateid: string, page: number) => {
  try {
    console.log("当前category item是", cateid, "page是", page)
    const url = generateRequestUrl(API.searchProduct)
    const config = {
      params: {
        "pageSize": smallPageSize,
        "page": page,
        "ignoreShowOnTouchPanel": "false",
        "ShowOnTouchPanel": "true",
        "CateID": Number(cateid),
        "joinData": ['SpecialPrice'],
        "SortBy": 'KoreanName Description',
      }
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}

export const listProductsByMostUsed = async (page: number, pageSize?: number) => {
  try {
    const url = generateRequestUrl(API.searchProduct)
    const config = {
      params: {
        "pageSize": pageSize ? pageSize : smallPageSize,
        "page": page,
        "hasClicked": "true",//just result which has press the productcard
        "SortBy": "-ClickRate KoreanName Description",
        "joinData": ['SpecialPrice'],
      }
    }
    const response = await axios.get(url, config)
    console.log("listProductsByMostUsed result...>>>>>>", response)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}
export const listProductsByMannuallyAdd = async (page: number, pageSize?: number) => {
  try {
    const url = generateRequestUrl(API.searchProduct)
    const config = {
      params: {
        "pageSize": pageSize ? pageSize : smallPageSize,
        "page": page,
        // "SortBy": "-ClickRate",  改为按首字母排序
        "joinData": ['SpecialPrice'],
        "isPinOnPOS": "true",
        "SortBy": 'KoreanName Description',
      }
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong at get mannually add category products!"
    })
    return e
  }
}

export const recordProductClick = async (productCode: string) => {
  try {
    // let finalProductCode = ''
    // if (productCode) {
    //   // Always use the productCode if given
    //   finalProductCode = productCode
    // } else if (product) {
    //   finalProductCode = product.productCode
    // }
    // finalProductCode = productCode

    const url = generateRequestUrl(API.recordProductClick)
    //const config = {}
    const data = { ProductCode: productCode }
    const response = await axios.post(url, data)
    return response.data
  } catch (e) {
    console.log('Error during recording product click', e)
    notification.error({
      message: 'Something went wrong (during recording product click)',
    })
  }
}

export const getSpecialPriceByProductCode = async (productCode: string) => {
  try {
    const url = generateRequestUrl(API.getSpecialPrices)
    const config = {
      params: {
        "pageSize": pageSize,
        "ProductCode": productCode
      }
    }
    const response = await axios.get(url, config)
    return response.data
  } catch (e) {
    console.log(e)
    notification.error({
      message: "Something went wrong!"
    })
    return e
  }
}
