import React, {useEffect} from "react"
import { Form, Button, Input, Typography, Select, InputNumber, Switch } from "antd"
import useViewport from "../../hooks/useViewport"
import { getConfiguration, saveConfiguration } from "../../utils/configurate"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"

const PrinterTab = () => {
  const [form] = Form.useForm()
  const { width } = useViewport()
  const PosConfig = getConfiguration("PrinterTab")
  const { printerInterface, mainWindow, secondWindow, sendOK } = PosConfig

  useEffect(() => {
    form.setFieldsValue({
      printerInterface,
      mainWindow, 
      secondWindow,
      sendOK
    })
  }, [])
  const onFinish = async (values: any) => {
    saveConfiguration(values)
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
        <Typography style={{ marginBottom: 20, color: "grey" }}>Enter printer details for this POS</Typography>
        <Form
          // labelCol={{ span: 100 }}
          wrapperCol={{ span: 100 }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          style={{width: "50%"}}
        >
          <Form.Item
            label="Printer Interface"
            name="printerInterface"
            rules={[{ required: true, message: "Example：USB/COM3..." }]}
          >
            <Input style={{backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px"}} />
          </Form.Item>
          <Form.Item
            label="Main Window Width"
            name={["mainWindow", "width"]}
            rules={[{ required: true, message: "Example：1280..." }]}
          >
            <InputNumber style={{backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px"}} />
          </Form.Item>
          <Form.Item
            label="Main Window Height"
            name={["mainWindow", "height"]}
            rules={[{ required: true, message: "Example：1024..." }]}
          >
            <InputNumber style={{backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px"}} />
          </Form.Item>
          <Form.Item
            label="Second Window Width"
            name={["secondWindow", "width"]}
            rules={[{ required: true, message: "Example：1024..." }]}
          >
            <InputNumber style={{backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px"}} />
          </Form.Item>
          <Form.Item
            label="Second Window Height"
            name={["secondWindow", "height"]}
            rules={[{ required: true, message: "Example：768..." }]}
          >
            <InputNumber style={{backgroundColor: "#F3F3F3", width: "500px", border: "none", borderRadius: "4px"}} />
          </Form.Item>
          <Form.Item
            label="Send OK Key To EFTPOS？"
            name="sendOK"
          >
            <Switch
              style={{ height: "25px", width: "100px" }}
              checkedChildren="YES"
              unCheckedChildren="NO"
              defaultChecked={sendOK} 
            />
          </Form.Item>
          <Form.Item >
            <Button type="primary" htmlType="submit" style={{ width: "500px", height: "50px", borderRadius: "4px", border: "none" }}>
              SAVE
            </Button>
          </Form.Item>
        </Form>
      </div>
  )
}

export default PrinterTab
